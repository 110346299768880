import {parseToMediaObject, TMediaObject} from "./TMediaObject";

export interface TCertificate {
  id: number;
  key: string;
  title: string;
  remark: string;
  transactionDate: string;
  medias: TMediaObject[];
  visible: boolean;
  deleted?: boolean;
}

export function parseToCertificate(c: any): TCertificate {
  return {
    id: c.id,
    key: c.key,
    title: c.title,
    remark: c.remark,
    medias: c.fileList.map(parseToMediaObject),
    transactionDate: c.transactionDate,
    visible: c.visible??false
  }
}
