import styles from './Professional.module.scss'
import cstyles from '../../components/Profile/Profile.module.scss'
import {mergeCss} from "../../utils/cssMerge";
import {useContext, useEffect, useState} from "react";
import {parseTranslation, Translation} from "../../types/Translation";
import {MainContext} from "../../context/MainContext";

import CProfileJobCard from "../../components/Profile/CProfileJobCard";
import {ProfileSwiperCard} from "../../components/Profile/ProfileSwiperCard";
import {dateToMonthYearString, yyyymmToString} from "../../utils/date";
import {getV2, postV2} from "../../utils/request";
import {useNavigate} from "react-router-dom";
import {CAboutEditModal} from "../../components/Profile/CAboutEditModal";
import {CExperienceAddModal} from "../../components/Profile/CExperienceAddModal";
import {CProfileSkillsModal} from "../../components/Profile/CProfileSkillsModal";
import {CProfilePaymentMethodModal} from "../../components/Profile/CProfilePaymentMethodModal";
import CLoadingAnimation from "../../components/CLoadingAnimation";
import {getProfessionalData, ProfessionalProfileData} from "../../service/profileRequest";
import {TError} from "../../types/TError";
import {CPortfolioAddModal} from "../../components/Profile/CPortfolioAddModal";
import {
  getActiveProjectsById,
  getCompletedProjectsById,
  getCountries,
  getHighschools,
  getLanguages,
  getWorkingPriceTypes,
  getProfessions,
  getSkills,
  getUniversities,
  getWorkingTypes, getTranslations
} from "../../service/commonRequest";
import {FilterConfig} from "../../types/TFilter";
import {PageState} from "../../types/Context";
import {Checkbox} from "@mui/material";
import {CProfileWorkingTypeModal} from "../../components/Profile/CProfileWorkingTypeModal";
import {CProfileReachPermissionModal} from "../../components/Profile/CProfileReachPermissionModal";
import {CProfileProfessionsModal} from "../../components/Profile/CProfileProfessionsModal";
import {CProfileSearchingJobModal} from "../../components/Profile/CProfileSearchingJobModal";
import {CProfileLanguagesModal} from 'src/components/Profile/CProfileLanguagesModal';
import {CProfileSocialModal} from "../../components/Profile/CProfileSocialModal";
import {CUserEditModal} from 'src/components/Profile/CUserEditModal';
import {CCertificateAddModal} from "../../components/Profile/CCertificateAddModal";
import {CActiveJobsList} from "../../components/CActiveJobsList";
import {CCompletedJobsList} from "../../components/CCompletedJobsList";
import {CExperiencesList} from "../../components/CExperiencesList";
import {CCertificatesList} from "../../components/CCertificatesList";
import {CEducationAddModal} from "../../components/Profile/CEducationAddModal";
import {CEducationsList} from "../../components/CEducationsList";
import {CPortfolioList} from "../../components/CPortfolioList";
import {isActiveJobStatus, isCompletedJobStatus, JobStatus, parseJobsResponse, TJob} from "../../types/TJob";
import {ComponentShowModal} from "../../components/Profile/ComponentShowModal";
import {EnumProfileUpdateType, SocialMediaId} from "../../types/EnumProfileUpdateType";
import {UserQuery} from 'src/pages/Profile/Profile';
import {NotificationType} from "../Toast/Toast";

interface Certificate {
  title: string,
  company: string,
  date: string
}

interface Product {
  image: string,
  title: string
}

interface iProps {
  userQuery?: UserQuery
}

export function Professional({userQuery}: iProps) {
  const mainContext = useContext(MainContext);
  const navigate = useNavigate();
  const editable = userQuery == undefined;
  const [modalState, setModalState] = useState('')
  const [editModalState, setEditModalState] = useState('')
  const [paymentTypes, setPaymentTypes] = useState<FilterConfig[] | undefined>(undefined)
  const [professions, setProfessions] = useState<FilterConfig[] | undefined>(undefined)
  const [languages, setLanguages] = useState<FilterConfig[] | undefined>(undefined)
  const [workingTypes, setWorkingTypes] = useState<FilterConfig[] | undefined>(undefined)
  const [skills, setSkills] = useState<FilterConfig[] | undefined>(undefined)
  const [countries, setCountries] = useState<FilterConfig[] | undefined>(undefined)
  const [universities, setUniversities] = useState<FilterConfig[] | undefined>(undefined)
  const [highschools, setHighschools] = useState<FilterConfig[] | undefined>(undefined)

  const [profileData, setProfileData] = useState<ProfessionalProfileData | undefined>(undefined)

  const [activeJobs, setActiveJobs] = useState<TJob[]>([])
  const [completedJobs, setCompletedJobs] = useState<TJob[]>([])

  const [editData, setEditData] = useState<any | undefined>(undefined)
  const [showModal, setShowModal] = useState<undefined | JSX.Element>(undefined)

  const isAllLoaded =
    profileData != undefined &&
    paymentTypes != undefined &&
    professions != undefined &&
    languages != undefined &&
    skills != undefined &&
    workingTypes != undefined && universities && highschools;

  const [translations, setTranslations] = useState<
    Map<string, Translation> | undefined
  >(undefined);

  function getTranslation(child: string): string {
    return translations?.get('sayfa')?.sentences.get(child) ?? "";
  }

  useEffect(() => {
    if (localStorage.getItem('userData') == null) {
      navigate('/')
      return
    }
    // if (userQuery?.keyId != undefined) {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
    // }
  }, []);

  useEffect(() => {

    if (profileData != undefined && mainContext?.userData) {
      if (userQuery && userQuery.keyId != mainContext?.userData?.id) {
        getV2('/ProjectOffer/listbyprofessionalID?professionalKey=' + userQuery.keyId).then((resp) => {
          if (resp.data.result == false) {
            mainContext?.showToast(NotificationType.Fail, 'projectOffer')
            return
          }
          const activeJobs = parseJobsResponse(resp.data.filter((d: any) => isActiveJobStatus(d.status)), mainContext?.getTranslation('common', 'halen'))
          const completedJobs = parseJobsResponse(resp.data.filter((d: any) => isCompletedJobStatus(d.status)), mainContext?.getTranslation('common', 'halen'))
          setActiveJobs(activeJobs)
          setCompletedJobs(completedJobs)
        })
      } else {
        getActiveProjectsById(userQuery ? userQuery.keyId : mainContext?.userData.nameId).then(resp => {
          if (resp instanceof TError) {
            mainContext?.showToast(NotificationType.Fail, 'getProjects')
            return
          } else {
            //@ts-ignore
            setActiveJobs(resp)
          }
        })
        getCompletedProjectsById(userQuery ? userQuery.keyId : mainContext?.userData.nameId).then(resp => {
          if (resp instanceof TError) {
            mainContext?.showToast(NotificationType.Fail, 'getProjects')
            return
          } else {
            //@ts-ignore
            setCompletedJobs(resp)
          }
        })
      }
    }
  }, [profileData, mainContext?.userData]);


  async function fetchCommonData() {
    await getCountries(mainContext).then((resp) => {
      if (resp instanceof TError) {
        return
      }
      setCountries(resp);
    });
    await getWorkingPriceTypes(mainContext).then((resp) => {
      if (resp instanceof TError) {
      } else {
        setPaymentTypes(resp);
      }
    });
    await getProfessions(mainContext).then((resp) => {
      if (resp instanceof TError) {
        return
      }
      setProfessions(resp);
    });
    await getLanguages(mainContext).then((resp) => {
      if (resp instanceof TError) {
        return
      }
      setLanguages(resp);
    });
    await getWorkingTypes(mainContext).then((resp) => {
      if (resp instanceof TError) {
        return
      }
      setWorkingTypes(resp);
    });
    await getSkills(mainContext).then((resp) => {
      if (resp instanceof TError) {
        return
      }
      setSkills(resp);
    });
    await getUniversities(mainContext).then((resp) => {
      if (resp instanceof TError) {
        return
      }
      setUniversities(resp);
    });
    await getHighschools(mainContext).then((resp) => {
      if (resp instanceof TError) {
        return
      }
      setHighschools(resp);
    });
    fetchProfileData()
  }

  function fetchProfileData(callback?: any) {
    setProfileData(undefined)
    getTranslations('profil-sayfa', (translations, data) => {
      setTranslations(translations);
      getProfessionalData(userQuery?.keyId).then(r => {
        if (r instanceof TError) {
          mainContext?.showToast(NotificationType.Fail, 'getData')
          return
        } else {
          setProfileData(r)
          if (window.location.href.endsWith('/settings')) {
            setModalState('user')
          }
          if (callback) {
            callback(r)
          }
        }
      })
    })
  }

  useEffect(() => {
    if (mainContext?.userData?.token) {
      const isExpired = mainContext.isExpired('professional')
      //berkExpire
      if (!isExpired) {
        if (mainContext?.publicToken) {
          fetchCommonData()
        }
      } else {
        setTimeout(() => {
          mainContext.setSigninupState(PageState.Signin)
        }, 1500)
      }

      // return () => {
      //   userInstance.interceptors.request.eject(configID)
      // }
    }
  }, [mainContext?.userData?.token, mainContext?.publicToken]);

  function getLeftCardRow(editKey: string, title?: string, subs?: string[], texts?: string[], customComponent?: JSX.Element, addKey?: string) {
    return <div className={'text-start flex-col items-start w-full'}>
      <div className={'pb-[12px] w-full gap-[16px] flex justify-between'}>
        <h1>{title}</h1>
        <div className={'flex gap-[16px]'}>
          {editable && addKey &&
              <div role={'button'} onClick={() => setModalState(addKey)}>
                  <img src={'/images/addButton.svg'}/>
              </div>
          }
          {editable &&
              <div role={'button'} onClick={() => setModalState(editKey)}
                   className={mergeCss([styles.editButton, '!relative'])}>
                  <img src={'/images/editButton.svg'}/>
              </div>
          }
        </div>
      </div>
      {subs &&
        subs.map(sub =>
          <h4>{sub}</h4>
        )
      }
      {texts &&
        texts.map(o => <h5>{o}</h5>)
      }
      {customComponent}
    </div>
  }

  function getAboutCard(profileData: ProfessionalProfileData | undefined) {
    return <div className={'whiteShadowCard py-[36px] px-[24px]'}>
      <div className={'flex justify-between'}>
        <div className={'flex gap-[14px]'}>
          <p className={styles.header}>{profileData?.title}</p>
          {editable &&
              <div onClick={() => setModalState('hakkimda')} role={'button'}
                   className={mergeCss([styles.editButton, '!relative'])}>
                  <img src={'/images/editButton.svg'}/>
              </div>
          }
        </div>
      </div>
      <div className={'h-[24px]'}></div>
      <div className={'flex-col gap-[16px]'}>
        <h2>{getTranslation(userQuery ? 'hakkinda' : 'hakkimda')}</h2>
        <p className={'text-15-24-500 text-black'}>{profileData?.about}</p>
      </div>
    </div>
  }

  return <div
    className="main-width1200 m-auto main-padding pt-[96px] pb-[48px] lg:pb-[72px] items-center h-full flex-col gap-[30px]">
    {!isAllLoaded ? <div className={'h-[100vh]'}><CLoadingAnimation/></div> : <>
      <div className={'flex w-full justify-between px-[24px] py-[36px] whiteShadowCard items-center'}>
        <div className={'flex gap-[11px]'}>
          <div className="relative w-[83px] h-[82px] p-[4px] flex justify-center items-center">
            <img
              className="w-full h-full rounded-full bg-gray-300"
              src={profileData.profilePhoto?.url}
            />
            <div className={styles.statusDot}>
              <div className={styles.available}></div>
            </div>
            {editable &&
                <div role={'button'} onClick={() => setModalState('user')} className={styles.editButton}>
                    <img src={'/images/editButton.svg'}/>
                </div>
            }
          </div>
          <div>
            <div className="text-black text-18-24-600 underline">
              {profileData.name}
            </div>
            {profileData?.country != undefined && profileData.city != undefined &&
                <div className="flex gap-[5px] text-grey text-14-24-500 pt-[4px]">
                    <img src={'/images/locationSmall.svg'}/>
                  {profileData.country + " " + profileData.city}
                </div>
            }
            <div className={mergeCss([styles.availability, styles.available])}>
              {profileData.active ? mainContext?.getTranslation('common', 'musait') : mainContext?.getTranslation('common', 'musait-degil')}
            </div>
          </div>
        </div>
        <div className={'flex gap-[10px]'}>
          {profileData?.socialMedias.instagram && profileData?.socialMedias.instagram.link &&
              <div style={{cursor: 'pointer'}} className={cstyles.socialmedia} onClick={() => {
                window.open(
                  profileData?.socialMedias.instagram!.link!,
                  "_blank"
                );
              }}><img src={'/images/social_Instagram.svg'}/></div>
          }
          {profileData?.socialMedias.twitter && profileData?.socialMedias.twitter.link &&
              <div style={{cursor: 'pointer'}} className={cstyles.socialmedia} onClick={() => {
                window.open(
                  profileData?.socialMedias.twitter!.link!,
                  "_blank"
                );
              }}><img src={'/images/social_X.svg'}/></div>
          }
          {profileData?.socialMedias.linkedin && profileData?.socialMedias.linkedin.link &&
              <div style={{cursor: 'pointer'}} className={cstyles.socialmedia} onClick={() => {
                window.open(
                  profileData?.socialMedias.linkedin!.link!,
                  "_blank"
                );
              }}><img src={'/images/social_Linkedin.svg'}/></div>
          }
          {profileData?.socialMedias.facebook && profileData?.socialMedias.facebook.link &&
              <div style={{cursor: 'pointer'}} className={cstyles.socialmedia} onClick={() => {
                window.open(
                  profileData?.socialMedias.facebook!.link!,
                  "_blank"
                );
              }}><img src={'/images/social_Facebook.svg'}/></div>
          }
          {profileData?.socialMedias.behance && profileData?.socialMedias.behance.link &&
              <div style={{cursor: 'pointer'}} className={cstyles.socialmedia} onClick={() => {
                window.open(
                  profileData?.socialMedias.behance!.link!,
                  "_blank"
                );
              }}><img src={'/images/social_Behance.svg'}/></div>
          }
          {profileData?.socialMedias.dribble && profileData?.socialMedias.dribble.link &&
              <div style={{cursor: 'pointer'}} className={cstyles.socialmedia} onClick={() => {
                window.open(
                  profileData?.socialMedias.dribble!.link!,
                  "_blank"
                );
              }}><img src={'/images/social_Dribble.svg'}/></div>
          }
        </div>
      </div>
      <div className={'flex w-full gap-[24px]'}>

        <div
          className={'max-w-[283px] flex-col gap-[40px] px-[24px] py-[36px] whiteShadowCard !text-start !items-start mb-auto'}>
          {getLeftCardRow('paymentType', mainContext?.getTranslation('filters', 'job-paymentType'), profileData.paymentType != 0 ?
            [paymentTypes?.find(p => p.id == profileData.paymentType)?.label ?? ''] : undefined)}
          {getLeftCardRow('workType', mainContext?.getTranslation('filters', 'job-workType'), profileData?.workingTypes.map(v => workingTypes?.find(p => ('' + p.id) == v)?.label ?? '')) ?? undefined}
          {getLeftCardRow('profession', mainContext?.getTranslation('filters', 'job-expertise'), profileData?.professions.map(v => professions?.find(p => ('' + p.id) == v)?.label ?? '')) ?? undefined}
          {getLeftCardRow('searchingJob', mainContext?.getTranslation('filters', 'is-arama-durumu'),
            profileData.searchingJob ?
              [mainContext?.getTranslation('common', 'evet') ?? ''] :
              [mainContext?.getTranslation('common', 'hayir') ?? '']
          )}
          {getLeftCardRow('educationEdit', mainContext?.getTranslation('filters', 'job-education'), undefined, undefined, profileData?.educations ?
            <>{
              profileData.educations.map(e => <div className={'pt-[12px]'}>
                  <h2>{e.schoolName}</h2>
                  <h3>{e.departmentName}</h3>
                  <h5>{yyyymmToString(e.beginYear, e.beginMonth) + ' - ' + yyyymmToString(e.endYear, e.endMonth)}</h5>
                </div>
              )}
            </>
            : <></>, 'educationAdd')}
          {getLeftCardRow('language', mainContext?.getTranslation('filters', 'job-language'), profileData?.languages.map(v => languages?.find(p => ('' + p.id) == v)?.label ?? ''))}
          {getLeftCardRow('reachPermission', mainContext?.getTranslation('filters', 'ticari-ileti-onayi'), undefined, undefined,
            <div className={'w-[85%]'}>
              <div className={'flex justify-between items-center'}>
                <h3>Sms</h3>
                <Checkbox
                  className={'checkBox basicCheckbox'}
                  checked={profileData?.smsPermission}
                />
              </div>
              <div className={'flex justify-between items-center'}>
                <h3>Arama</h3>
                <Checkbox
                  className={'checkBox basicCheckbox'}
                  checked={profileData?.callPermission}
                />
              </div>
              <div className={'flex justify-between items-center'}>
                <h3>E-posta</h3>
                <Checkbox
                  className={'checkBox basicCheckbox'}
                  checked={profileData?.emailPermission}
                />
              </div>
            </div>)}
          {editable && getLeftCardRow('social', mainContext?.getTranslation('profile', 'sosyal-medya'), undefined, undefined,
            <div className={'w-[85%]'}>
            </div>)}
        </div>

        <div className={'flex-col gap-[12px] flex-1 w-full min-w-0'}>
          {getAboutCard(profileData)}
          <ProfileSwiperCard isEditable={editable && activeJobs != undefined && activeJobs.length > 0}
                             slidesPerView={1.5} getTranslation={getTranslation}
                             title={userQuery ? 'aktif-isler' : 'my-active-jobs'}
                             openEditModal={() => setEditModalState('my-active-jobs')}
                             components={activeJobs.filter(j => j.visible).map(i =>
                               <CProfileJobCard job={i}/>
                             )}/>
          <ProfileSwiperCard isEditable={editable && completedJobs != undefined && completedJobs.length > 0}
                             slidesPerView={1.2} getTranslation={getTranslation}
                             title={userQuery ? 'tamamlanan-isler' : 'my-completed-jobs'}
                             openEditModal={() => setEditModalState('my-completed-jobs')}
                             components={completedJobs.filter(j => j.visible).map(i =>
                               <CProfileJobCard job={i}/>)
                             }/>
        </div>
      </div>

      <div className={'w-full fullSlider'}>
        <ProfileSwiperCard isAddable={editable} slidesPerView={3.2} gap={'28px'} getTranslation={getTranslation}
                           isEditable={editable && profileData?.portfolios != undefined && profileData?.portfolios.length > 0}
                           openEditModal={() => setModalState('portfolioEdit')}
                           openAddModal={() => setModalState('portfolyo')} title={'portfolyo'}
                           components={profileData?.portfolios.map(p =>
                             <div className={'flex-col gap-[12px] text-center h-[205px] justify-between'}>
                               <div className={'rounded-[16px] overflow-hidden'}>
                                 <img className={'h-full w-full object-cover'}
                                      src={(p.medias && p.medias.length > 0) ? p.medias[0].url : undefined}/>
                               </div>
                               <p className={'text-14-17-700'}>{p.name}</p>
                             </div>
                           )}/>
      </div>
      {/*<div className={'w-full fullSlider'}>*/}
      {/*  <ProfileSwiperCard isEditable={true} slidesPerView={3.2} gap={'28px'} getTranslation={getTranslation}*/}
      {/*                     openAddModal={() => setModalState('urunlerim')}*/}
      {/*                     title={'urunlerim'}*/}
      {/*                     components={[0, 1].map(i =>*/}
      {/*                       <div className={'flex-col gap-[12px] text-center'}>*/}
      {/*                         <div className={'rounded-[16px]'}>sdfdsf*/}
      {/*                           <img src={product.image}/>*/}
      {/*                         </div>*/}
      {/*                         <p className={'text-14-17-700'}>{product.title}</p>*/}
      {/*                       </div>*/}
      {/*                     )}/>*/}
      {/*</div>*/}
      <div className={'w-full whiteShadowCard py-[36px] px-[24px] flex flex-col gap-[24px]'}>
        <div className={'flex gap-[14px]'}>
          <div className={styles.skills}>{getTranslation('yetenekler')}</div>
          {editable &&
              <div onClick={() => {
                setModalState('yetenekler')
              }} role={'button'} className={mergeCss([styles.editButton, '!relative'])}>
                  <img src={'/images/editButton.svg'}/>
              </div>
          }
        </div>
        <div className={'flex gap-[16px] w-full flex-wrap'}>
          {profileData.skills.map(t =>
            <div role={'button'} className={'roundLabel w-fit'}>{skills?.find(s => ('' + s.id) == t)?.label ?? ''}</div>
          )}
        </div>
      </div>
      <div className={'w-full'}>
        <ProfileSwiperCard customClass={'experiencesSwiper'}
                           isEditable={editable && profileData?.experiences != undefined && profileData?.experiences.length > 0}
                           isAddable={editable} slidesPerView={2.2}
                           getTranslation={getTranslation} title={'tecrubeler'}
                           openAddModal={() => {
                             setEditData(undefined)
                             setModalState('tecrube')
                           }}
                           openEditModal={() => setEditModalState('tecrube')}
                           components={profileData?.experiences.filter(e => e.visible).map(e => {
                               return <CProfileJobCard job={{
                                 id: e.key,
                                 title: professions?.find(p => p.id == e.professionID)?.label ?? '',
                                 firm: e.companyName,
                                 workType: 'veri yok',
                                 startDate: yyyymmToString(e.year, e.month),
                                 endDate: yyyymmToString(e.endYear, e.endMonth),
                                 startDateObj: new Date(e.year, e.month),
                                 endDateObj: new Date(e.endYear, e.endMonth),
                                 description: e.remark,
                                 status: JobStatus.Confirmed,
                                 visible: e.visible,
                               }}/>
                             }
                           )}/>
      </div>
      <div className={'w-full'}>
        <ProfileSwiperCard
          isEditable={editable && profileData?.certificates != undefined && profileData?.certificates.length > 0}
          isAddable={editable} slidesPerView={'auto'} getTranslation={getTranslation}
          openAddModal={() => setModalState('sertifikalar')}
          openEditModal={() => setEditModalState('sertifikalar')}
          title={'sertifikalar'}
          customClass={'certificateSwiper'}
          components={profileData?.certificates.filter(c => c.visible).map(c =>
            <div className={mergeCss([cstyles.jobCard, 'flex-col !px-[15px] !py-[21px]'])}>
              <div
                className={'flex !gap-[14px] !text-start !justify-start !items-start'}>
                <img src={'/images/certificate.svg'}/>
                <div className={'flex-col text-start items-start'}>
                  <h1 className={'!items-start !text-start'}>{c.title}</h1>
                  <h2 className={'!items-start !text-start text-14-24-500'}>{c.remark}</h2>
                  <h3
                    className={'!items-start !text-start text-12-24-400'}>{dateToMonthYearString(c.transactionDate)}</h3>
                </div>
              </div>
              {(c.medias && c.medias.length > 0) &&
                  <div role={'button'} className={'bg-white border-purple rounded-[8px] py-[4px] flex justify-center'}
                       onClick={() => {
                         setShowModal(<div className={'max-h-[60vh]'}>
                           <img src={c.medias[0].url}/>
                         </div>)
                       }}>
                      <p className={'text-12-16-600 text-purple'}>{getTranslation('sertifikayi-gor')}</p>
                  </div>
              }
            </div>
          )}/>
      </div>
      {(mainContext && profileData) &&
          <CUserEditModal getTranslation={mainContext?.getTranslation} data={profileData} isOpen={modalState == 'user'}
                          close={() => setModalState('')}
                          updated={(callback?: any) => {
                            fetchProfileData(callback)
                          }}/>
      }
      {editable &&
          <CAboutEditModal getTranslation={getTranslation} isOpen={modalState == 'hakkimda'}
                           title={profileData.title}
                           about={profileData?.about ?? ''}
                           close={() => {
                             setModalState('')
                           }}
                           update={(newTitle, newAbout) => {
                             setProfileData({...profileData, title: newTitle, about: newAbout})
                             setModalState('')
                           }}/>
      }
      {editable &&
          <CExperienceAddModal getTranslation={getTranslation} isOpen={modalState == 'tecrube'}
                               editData={editData}
                               options={professions ?? []}
                               updated={() => fetchProfileData()}
                               close={() => {
                                 setModalState('')
                                 setEditData(undefined)
                               }}
          />
      }
      {editable &&
          <CEducationAddModal getTranslation={getTranslation}
                              editData={editData}
                              isOpen={modalState == 'educationAdd' || modalState == 'education'}
                              updated={() => fetchProfileData()}
                              close={() => {
                                setModalState('')
                                setEditData(undefined)
                              }}
          />
      }
      {editable &&
          <CProfileSkillsModal isOpen={modalState == 'yetenekler'}
                               close={() => setModalState('')}
                               options={skills ?? []}
                               onUpdated={(newSkills) => {
                                 setProfileData({...profileData, skills: newSkills})
                                 setModalState('')
                                 fetchProfileData()
                               }}
                               selecteds={profileData?.skills} filters={skills}/>
      }
      {editable &&
          <CProfilePaymentMethodModal options={paymentTypes} isOpen={modalState == 'paymentType'}
                                      selectedValue={profileData?.paymentType}
                                      close={() => {
                                        setModalState('')
                                      }}
                                      update={(tempVal) => {
                                        setProfileData({...profileData, paymentType: tempVal})
                                      }}/>
      }
      {editable &&
          <CProfileWorkingTypeModal options={workingTypes} isOpen={modalState == 'workType'}
                                    selectedValues={profileData?.workingTypes}
                                    close={() => setModalState('')}
                                    update={(newValues) => {
                                      setProfileData({...profileData, workingTypes: newValues?.map(v => "" + v) ?? []})
                                    }}/>
      }
      {editable &&
          <CProfileLanguagesModal options={languages} isOpen={modalState == 'language'}
                                  selectedValues={profileData?.languages}
                                  close={() => setModalState('')}
                                  update={(newValues) => {
                                    setProfileData({...profileData, languages: newValues?.map(v => "" + v) ?? []})
                                  }}/>
      }
      {editable &&
          <CPortfolioAddModal getTranslation={getTranslation} isOpen={modalState == 'portfolyo'} skills={skills}
                              editData={editData}
                              close={() => {
                                setEditData(undefined)
                                setModalState('')
                              }}
                              success={() => {
                                fetchProfileData()
                              }}
          />
      }
      {editable &&
          <CCertificateAddModal getTranslation={getTranslation} isOpen={modalState == 'sertifikalar'}
                                close={() => setModalState('')}
                                success={() => {
                                  fetchProfileData()
                                }}
                                editData={editData}
          />
      }
      {editable &&
          <CProfileReachPermissionModal isOpen={modalState == 'reachPermission'} sms={profileData?.smsPermission}
                                        phone={profileData?.callPermission} email={profileData?.emailPermission}
                                        onClose={() => setModalState('')}
                                        onUpdate={(s, p, e) => {
                                          setProfileData({
                                            ...profileData,
                                            smsPermission: s,
                                            callPermission: p,
                                            emailPermission: e
                                          })
                                        }}/>
      }
      <CProfileProfessionsModal options={professions} isOpen={modalState == 'profession'}
                                selectedValues={profileData?.professions}
                                close={() => {
                                  setModalState('')
                                }}
                                updated={(newValues) => {
                                  setProfileData({...profileData, professions: newValues?.map(v => "" + v) ?? []})
                                }}
      />

      <CProfileSearchingJobModal isOpen={modalState == 'searchingJob'}
                                 value={profileData?.searchingJob}
                                 close={() => setModalState('')}
                                 updated={(newValue: boolean) => {
                                   setProfileData({...profileData, searchingJob: newValue})
                                 }}/>
      {profileData != undefined &&
          <CProfileSocialModal getTranslation={mainContext?.getTranslation} isOpen={modalState == 'social'}
                               data={profileData.socialMedias}
                               close={() => {
                                 setModalState('')
                               }}
                               update={(tempData: any) =>
                                 postV2('/Professional/partialupdate', {
                                   updateType: EnumProfileUpdateType.SocialMedia,
                                   socialMediaList: [
                                     {
                                       socialMediaID: SocialMediaId.Twitter,
                                       socialMedia: 'Twitter',
                                       value: tempData.twitter?.link ?? ''
                                     },
                                     {
                                       socialMediaID: SocialMediaId.Linkedin,
                                       socialMedia: 'Linkedin',
                                       value: tempData.linkedin?.link ?? ''
                                     },
                                     {
                                       socialMediaID: SocialMediaId.Facebook,
                                       socialMedia: 'Facebook',
                                       value: tempData.facebook?.link ?? ''
                                     },
                                     {
                                       socialMediaID: SocialMediaId.Dribble,
                                       socialMedia: 'Dribble',
                                       value: tempData.dribble?.link ?? ''
                                     },
                                     {
                                       socialMediaID: SocialMediaId.Behance,
                                       socialMedia: 'Behance',
                                       value: tempData.behance?.link ?? ''
                                     },
                                     {
                                       socialMediaID: SocialMediaId.Instagram,
                                       socialMedia: 'Instagram',
                                       value: tempData.instagram?.link ?? ''
                                     },
                                   ]
                                 })
                               }
                               updated={() => {
                                 fetchProfileData()
                               }}/>
      }
      <CActiveJobsList
        projects={activeJobs}
        getTranslation={getTranslation}
        isOpen={editModalState == 'my-active-jobs'}
        update={() => {
          if (mainContext?.userData) {
            getActiveProjectsById(userQuery ? userQuery.keyId : mainContext.userData.nameId).then(resp => {
              if (resp instanceof TError) {
                mainContext?.showToast(NotificationType.Fail, 'getProjects')
              } else {
                //@ts-ignore
                setActiveJobs(resp)
              }
            })
          }
        }}
        close={() => setEditModalState('')}
      />
      <CCompletedJobsList
        projects={completedJobs}
        getTranslation={getTranslation}
        isOpen={editModalState == 'my-completed-jobs'}
        update={() => {
          if (mainContext?.userData) {
            getCompletedProjectsById(userQuery ? userQuery.keyId : mainContext.userData.nameId).then(resp => {
              if (resp instanceof TError) {
                mainContext?.showToast(NotificationType.Fail, 'getProjects')
              } else {
                //@ts-ignore
                setActiveJobs(resp)
              }
            })
          }
        }}
        close={() => setEditModalState('')}
      />
      {profileData?.experiences &&
          <CExperiencesList
              experiences={profileData.experiences}
              professions={professions}
              getTranslation={getTranslation}
              isOpen={editModalState == 'tecrube'}
              edit={(p) => {
                setEditData(p)
                setModalState('tecrube')
              }}
              onUpdate={() => fetchProfileData()}
              close={() => setEditModalState('')}
          />
      }
      {profileData?.certificates &&
          <CCertificatesList
              certificates={profileData.certificates.map(c => {
                return {...c}
              })}
              edit={(c) => {
                setEditData(c)
                setModalState('sertifikalar')
              }}
              getTranslation={getTranslation}
              isOpen={editModalState == 'sertifikalar'}
              close={() => setEditModalState('')}
              onUpdate={() => fetchProfileData()}
          />
      }
      {profileData?.educations &&
          <CEducationsList
              educations={profileData.educations}
              getTranslation={getTranslation}
              isOpen={modalState == 'educationEdit'}
              onUpdate={() => fetchProfileData()}
              edit={(e) => {
                setEditData(e)
                setModalState('education')
              }}
              close={() => {
                setModalState('')
              }}
          />
      }
      {profileData?.portfolios &&
          <CPortfolioList
              portfolios={profileData.portfolios}
              edit={(p) => {
                setEditData(p)
                setModalState('portfolyo')
              }}
              getTranslation={getTranslation}
              isOpen={modalState == 'portfolioEdit'}
              onUpdate={() => fetchProfileData()}
              close={() => {
                setModalState('')
              }}
          />
      }
      <ComponentShowModal onClose={() => setShowModal(undefined)} component={showModal}/>
    </>}
  </div>
}
