import {useContext, useEffect, useState} from "react";
import {MainContext} from "../context/MainContext";
import {FilterModal} from "./Filter/FilterModal";
import {CCheckbox} from "./CCheckbox";
import styles from './Profile/Profile.module.scss'
import {TExperience} from "../types/TExperience";
import {getV2, postV2} from "../utils/request";
import {mergeCss} from "../utils/cssMerge";
import {FilterConfig} from "../types/TFilter";
import {TError} from "../types/TError";
import {NotificationType} from "./Toast/Toast";

interface IProps {
  getTranslation: (key: string) => string
  isOpen: boolean
  close: () => void
  onUpdate: () => void
  experiences: TExperience[]
  professions: FilterConfig[]
  edit: (p: TExperience) => void
}

export function CExperiencesList({getTranslation, isOpen, close, onUpdate, edit, experiences, professions}: IProps) {
  const mainContext = useContext(MainContext)

  const [requesting, setRequesting] = useState(false)
  const [tempExperiences, setTempExperiences] = useState<TExperience[]>([])

  useEffect(() => {
    if (isOpen === true) {
      setTempExperiences([...experiences.map(c => ({...c}))])
    }
  }, [isOpen]);

  function changeVisibility(i: number) {
    let newProjects = [...tempExperiences].map((t, ii) => {
      if (ii == i) {
        t.visible = !t.visible
      }
      return t
    });
    setTempExperiences(newProjects)
  }

  function changeDeletion(e: any, i: number) {
    e.preventDefault();
    e.stopPropagation();
    let newProjects = [...tempExperiences].map((t, ii) => {
      if (ii == i) {
        t.deleted = t.deleted != undefined ? !t.deleted : true
      }
      return t
    });
    setTempExperiences(newProjects)
  }


  async function updateExperienceVisibility(p: TExperience) {
    return await postV2('/ProfessionalExperience/visible', {key: p.key, visible: p.visible}).then((resp) => {
      if (resp.data.result == true) {
        mainContext?.showToast(NotificationType.Success, p.visible?'makeVisible':'makeInvisible')
        return true
      }
      if (resp instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, p.visible?'makeVisible':'makeInvisible')
      }
      return false
    });
  }

  async function deleteExperience(p: TExperience) {
    return await getV2('/ProfessionalExperience/delete?key=' + p.key).then((resp) => {
      if (resp.data.result == true) {
        mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
        return true
      }
      if (resp instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
      }
      return false
    });
  }

  async function save() {
    setRequesting(true)
    const newProjects = [...tempExperiences]

    let didFail = false
    let isFound = false
    for (let i = 0; i < tempExperiences.length; i++) {
      let find = experiences.find(p => p.id == tempExperiences[i].id);
      if (find && newProjects[i].deleted === true) {
        isFound = true
        const resp = await deleteExperience(tempExperiences[i])
        if (resp == false) {
          didFail = true
          newProjects[i].deleted = find.deleted;
        }
      } else if (find && find.visible != tempExperiences[i].visible) {
        isFound = true
        const resp = await updateExperienceVisibility(tempExperiences[i])
        if (resp == false) {
          didFail = true
          newProjects[i].visible = find.visible;
        }
      }
    }
    setRequesting(false)
    if (isFound) {
      onUpdate()
    }
    if (didFail == true) {
      mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
    } else if (isFound) {
      mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
      close();
    }
    setTempExperiences(newProjects)
  }

  function Edit(e: any, exp: TExperience) {
    e.preventDefault()
    e.stopPropagation()
    edit(exp)
  }

  const currentYear = new Date().getFullYear() + "";
  return <FilterModal
    title={getTranslation('tecrubeler')}
    header={''}
    isOpen={isOpen}
    onClose={close}
    onSave={save}
    requesting={requesting}
    saveDisabled={requesting}
    closeDisabled={requesting}
    customHeaderClass={'!pb-0'}
  >
    <div className={'w-full'}>
      <div className="flex-col gap-[36px] text-grey text-14-24-500 max-h-[60vh]">
        {getTranslation('gorunmesi-istenen-projeler')}
        <div className={'flex-col gap-[24px] overflow-y-auto'}>
          {tempExperiences.map((p, i) => {
            return <div onClick={() => changeVisibility(i)}
                        className={`rounded-[16px] py-[26px] px-[12px] flex ${p.deleted ? 'bg-[#FFAAAA]' : p.visible ? 'bg-[#DEDEFF]' : 'bg-[#F6F6FF]'}`}>
              <CCheckbox
                customClass={'!self-center !pb-0'}
                selected={p.visible}
                onChange={() => {
                }}
                disabled={requesting}
                textHtml={<></>}/>
              <div className={'flex-col gap-[12px] w-full overflow-x-hidden'}>

                <div className={`${styles.content} flex justify-between`}>
                  <div className={'flex-col'}>
                    <div className={`flex-col`}>
                      <h1>{professions?.find(pf => p.professionID == pf.id)?.label ?? ''}</h1>
                      <h2>{p.companyName}&nbsp;
                        <div className={'purpleDot'}/>
                        &nbsp;{'veri yok'}</h2>
                      <h3>{p.humanReadableBeginDate}&nbsp;
                        <div className={'purpleDot'}/>
                        &nbsp;{p.humanReadableEndDate}&nbsp;
                        {/*<div className={'purpleDot'}/>*/}
                        {/*&nbsp;{getDiffFromToday(new Date(props.startDate))}&nbsp;{mainContext?.getTranslation('common', 'gundur')}*/}
                      </h3>
                    </div>
                    <div className={'flex-col gap-4 pt-[12px]'}>
                      <h3>{mainContext?.getTranslation('common', 'aciklama')}</h3>
                      <h2>{p.remark}</h2>
                    </div>
                  </div>
                  <div className={'flex gap-[16px] mr-[18px]'}>
                    <div role={'button'} className={'pt-[1px]'}
                         onClick={(e) => Edit(e, experiences[i])}>
                      <img className={'w-[22px] h-[22px]'} src={'/images/editButton.svg'}/>
                    </div>
                    <div className={'w-[24px] h-[24px] '} onClick={(e) => changeDeletion(e, i)}
                         role={'button'}>
                      <img src={tempExperiences[i].deleted ? '/images/trashBlack.svg' : '/images/trashPurple.svg'}/>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          })}
        </div>

      </div>
    </div>
  </FilterModal>
}
