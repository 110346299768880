import {DataProps} from "../types/DataProps";
import {TMarketplace, TMediaTextCardsRow} from "../types/TComponents";

export function OPofftHelpToWorkSection(getTranslation: (key: string) => string, data?: DataProps): TMediaTextCardsRow {
  return {
    data: [
      {
        topComponentImage: data?.pagePictureModelList?.[0]?.getUrl() ?? "",
        header: getTranslation('card1'),
        topComponentVideo: getTranslation('link1'),
      },
      {
        topComponentImage: data?.pagePictureModelList?.[1]?.getUrl() ?? "",
        header: getTranslation('card2'),
        topComponentVideo: getTranslation('link2'),
      },
      {
        topComponentImage: data?.pagePictureModelList?.[2]?.getUrl() ?? "",
        header: getTranslation('card3'),
        topComponentVideo: getTranslation('link3'),
      },
      {
        topComponentImage: data?.pagePictureModelList?.[3]?.getUrl() ?? "",
        header: getTranslation('card4'),
        topComponentVideo: getTranslation('link4'),
      },
    ],
  };
}

export function OPofftPartnersSection(getTranslation: (key: string) => string, data?: DataProps): TMediaTextCardsRow {
  return {
    data: [
      {
        topComponentImage: data?.pagePictureModelList?.[0]?.getUrl() ?? "",
        header: getTranslation('card1-title')?.replace("\\n", "<br/>") ?? "",
        sub: getTranslation('card1-text') ?? "",
        onClick: () => {
          window.location.href = ('https://tr.peerbie.com/');
        }
      },
      {
        topComponentImage: data?.pagePictureModelList?.[1]?.getUrl() ?? "",
        header: getTranslation('card2-title')?.replace("\\n", "<br/>") ?? "",
        sub: getTranslation('card2-text') ?? "",
        onClick: () => {
          window.location.href = ('https://mukellef.co/');
        }
      },
      {
        topComponentImage: data?.pagePictureModelList?.[2]?.getUrl() ?? "",
        header: getTranslation('card3-title')?.replace("\\n", "<br/>") ?? "",
        sub: getTranslation('card3-text') ?? "",
        onClick: () => {
          window.location.href = ('https://www.moneypay.com.tr/');
        }
      },
      {
        topComponentImage: data?.pagePictureModelList?.[3]?.getUrl() ?? "",
        header: getTranslation('card4-title')?.replace("\\n", "<br/>") ?? "",
        sub: getTranslation('card4-text') ?? "",
        onClick: () => {
          window.location.href = ('https://www.kariyerdonusumakademisi.com/');
        }
      },
    ],
  };
}

export function OPofftMarketplaceSection(getTranslation: (parent: string, child: string) => string): TMarketplace {
  return {
    filters: [
      {
        image: "/images/filter_service.svg",
        title: getTranslation('common', 'filtre-hizmet-secenekleri') ?? "",
        type: "select",
        items: [
          {text: "Ses Kaydı"},
          {text: "Tüm Renkler"},
          {text: "Resimli Arka Plan Dahil"},
          {text: "Altyazılar Dahil"},
          {text: "Orjinal Dizayn"},
          {text: "Senaryo Yazmak"},
          {text: "Altyazılar Dahil"},
          {text: "Film Şeridi"},
        ],
        moreItems: [
          {text: "Dahil Olan Karakterler"},
          {text: "Müzık/Ses Tasarım Dahil"},
        ],
      },
      {
        image: "/images/filter_budget.svg",
        title: getTranslation('common', 'filtre-butce') ?? "",
        type: "range",
        items: [
          {text: getTranslation('common', 'minimum-dakika')},
          {text: getTranslation('common', 'maksimum-dakika')},
        ],
      },
      {
        image: "/images/filter_deliveryduration.svg",
        title: getTranslation('common', 'filtre-teslimat-suresi') ?? "",
        type: "radio",
        items: [
          {text: "Express 24 Saat", val: "1"},
          {text: "3 Güne Kadar", val: "2"},
          {text: "7 Güne Kadar", val: "3"},
          {text: "İstedğin Zaman", val: "4"},
        ],
      },
    ],
  };
}
