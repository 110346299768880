import styles from './Profile.module.scss'
import {useContext, useState} from "react";
import {MainContext} from "../../context/MainContext";
import {JobDetailModal} from "./JobDetailModal";
import {TJob} from "../../types/TJob";

interface IProps {
  job: TJob
}

const CProfileJobCard: React.FC<IProps> = ({job}: IProps) => {
  const mainContext = useContext(MainContext);

  const [selected, setSelected] = useState(-1)

  // const startTime=dateToMonthYearString(job.startTime)
  // const startTime = dateToMonthYearString(job.startDate)
  const currentYear = new Date().getFullYear() + "";
  return <div className={`${styles.jobCard} ${styles.content} h-full !max-w-[604px]`} onClick={(e) => {
    if (selected == -1) {
      setSelected(0)
    }
  }}>
    <div className={'flex justify-between items-start'}>
      <div className={'flex-col'}>
        <h1>{job.title}</h1>
        <h2>{job.firm}&nbsp;
          <div className={'purpleDot'}/>
          &nbsp;{job.workType}</h2>
        <h3 style={{whiteSpace: 'noWrap'}}>{job.startDate}&nbsp;
          <div className={'purpleDot'}/>
          &nbsp;{(job.endDate == undefined || job.endDate == currentYear) ? mainContext?.getTranslation('common', 'halen') : job.endDate}
          &nbsp;
          {/*<div className={'purpleDot'}/>*/}
          {/*&nbsp;{getDiffFromToday(new Date(job.startDate))}&nbsp;{mainContext?.getTranslation('common', 'gundur')}*/}
        </h3>
      </div>
      {job.location &&
          <div className={'flex justify-end gap-[8px]'}>
              <img src={'/images/Location.svg'}/>
              <p>{job.location}</p>
          </div>
      }
    </div>
    <div className={'flex-col'}>
      <h4>{mainContext?.getTranslation('common', 'aciklama')}</h4>
      <p className={styles.description}>{job.description}</p>
      {/*<p className={styles.description} dangerouslySetInnerHTML={{__html:job.description}}></p>*/}
    </div>

    {job.medias && <div className={'flex gap-[16px] overflow-auto'}>
      {job.medias!.map((m, i) => <img role={'button'} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setSelected(i)
      }
      } className={'rounded-[16px] max-w-[170px]'} src={m}/>)}
    </div>}

    <JobDetailModal title={job.title} data={selected >= 0 ? job : undefined} onClose={() => setSelected(-1)}
                    selectedMediaIndex={selected}/>
  </div>
}


export default CProfileJobCard;
