export enum EnumProfileUpdateType {
  Remark = 'Remark',
  ActiveSearchProject = 'ActiveSearchProject',
  WorkingPriceType = 'WorkingPriceType',
  SocialMedia = 3,
  Profession = "Profession",
  Language = 'Language',
  Skill = "Skill",
  WorkingType = 'WorkingType',
  Permission = "Permission",
  ProfilePhoto = "ProfilePhoto",
  Logo = "Logo",
  PageImage = "PageImage",
  Address = "Adress"
}

export enum SocialMediaId {
  Facebook = 1,
  Twitter,
  Linkedin,
  Instagram,
  Behance,
  Dribble
}
