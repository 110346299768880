import {FilterConfig, FilterType, JobFilter, JobFilterType} from "src/types/TFilter";
import {MainContext} from "../context/MainContext";
import {useContext, useEffect, useState} from "react";
import {Modal} from "@mui/material";
import {FilterPaymentType} from "./Filter/FilterPaymentType";
import {FilterProfession} from "./Filter/FilterProfession";
import {FilterLanguage} from "./Filter/FilterLanguage";
import {FilterSkill} from "./Filter/FilterSkill";
import {FilterWorkType} from "./Filter/FilterWorkType";
import {FilterLocation} from "./Filter/FilterLocation";
import {FilterServiceOptions} from "./Filter/FilterServiceOptions";
import {FilterDeliveryDate} from "./Filter/FilterDeliveryDate";
import {FilterPaymentRange} from "./Filter/FilterPaymentRange";
import {FilterProjects} from "../service/projectRequest";
import {PageState} from "../types/Context";
import {NotificationType} from "./Toast/Toast";

interface IProps {
  onFiltersApplied: (filters: Map<string, FilterConfig[]>) => void;
}

const filters: JobFilter[] = [
  {name: JobFilterType.PaymentType, key: '', type: FilterType.Text},
  {name: JobFilterType.PaymentRange, key: '', type: FilterType.Range},
  {name: JobFilterType.DeliveryDate, key: '', type: FilterType.Text},
  {name: JobFilterType.Profession, key: '', type: FilterType.Text},
  {name: JobFilterType.Skill, key: '', type: FilterType.Text},
  {name: JobFilterType.Language, key: '', type: FilterType.Text},
  {name: JobFilterType.WorkType, key: '', type: FilterType.Text},
  {name: JobFilterType.Location, key: '', type: FilterType.Text},
];

export function CDashboardFilters({onFiltersApplied}: IProps) {

  const mainContext = useContext(MainContext);

  const [appliedFilters, setAppliedFilters] = useState(new Map<string, FilterConfig[]>())
  const [editFilter, setEditFilter] = useState<undefined | JobFilter>(undefined)
  const [resetter, setResetter] = useState(0)

  useEffect(() => {
    if (mainContext?.userData?.token) {
      const currentFilterMap = new Map<string, FilterConfig[]>()

      const savedFiltersStr = localStorage.getItem('jobfilters')
      if (savedFiltersStr != null) {
        try {
          const savedFilters = JSON.parse(savedFiltersStr)
          for (let i = 0; i < filters.length; i++) {
            const f = filters[i];
            const sf = savedFilters[f.name]
            if (sf != undefined) {
              currentFilterMap.set(f.name, sf)
            }
          }
          setAppliedFilters(currentFilterMap)
        } catch (e) {
          mainContext?.showToast(NotificationType.Fail)
          debugger
        }
      }
      if (mainContext.isExpired('dashboardf1')) {
        setTimeout(() => {
          mainContext.setSigninupState(PageState.Signin)
        }, 1500)
      } else {
        onFiltersChanged(currentFilterMap)
      }
    }

  }, [mainContext?.userData?.token]);


  function checkIfFiltersEmpty(AppliedFilters: Map<string, FilterConfig[]>) {
    var isEmpty = true;
    for (let i = 0; i < filters.length; i++) {
      const f = filters[i]
      if (AppliedFilters.has(f.name)) {
        const val = AppliedFilters.get(f.name)
        if (val != undefined && val.length > 0) {
          isEmpty = false
          break
        }
      }
    }
    return isEmpty
  }

  function onFiltersChanged(map: Map<string, FilterConfig[]>) {
    var obj = Object.fromEntries(map);
    var jsonString = JSON.stringify(obj);

    localStorage.setItem('jobfilters', jsonString)
    setAppliedFilters(map)
    onFiltersApplied(map)
  }

  return (
    <>
      <div className="pt-[24px]">
        {
          <div
            className="rounded-[16px] p-[24px] bg-white flex-col"
            style={{
              boxShadow: "0px 20px 80px 0px #EBECF0",
            }}
          >
            <div className="flex justify-between items-center pb-[8px]">
              <div className="text-purple text-18-24-600">{mainContext?.getTranslation('filters', 'filtreler')}</div>
              {!checkIfFiltersEmpty(appliedFilters) &&
                  <div role={'button'} onClick={() => {
                    let newMap = new Map<string, FilterConfig[]>();
                    setAppliedFilters(newMap)
                    onFiltersApplied(newMap)
                  }} className="text-light-red text-14-16-600">{mainContext?.getTranslation('filters', 'temizle')}</div>
              }
            </div>
            {filters.map((filter, index) => {

              const appliedFilter = appliedFilters.get(filter.name)

              return (
                <div key={filter.name + '_selected_options'} className={'flex flex-col gap-[12px] py-[8px]'}>
                  <div className="flex justify-between">
                    <div
                      className="text-fullblack text-14-24-700">{mainContext!.getTranslation('filters', filter.name)}</div>
                    <div role={'button'} onClick={() => {
                      setEditFilter(filter)
                    }}><img src="/images/filterEdit.svg" className="arrow"></img>
                    </div>
                  </div>
                  <div
                    className={`flex ${filter.name == JobFilterType.DeliveryDate ? 'gap-[4px]' : 'flex-col'}`}>
                    {appliedFilter != undefined &&
                      (() => {
                        switch (filter.name) {
                          case JobFilterType.PaymentRange:
                            if (appliedFilter.length == 3)
                              return <p
                                className={'text-14-24-500 text-black'}>{appliedFilter[0].valueStr + ' ' + appliedFilter[2].code + ' - ' + appliedFilter[1].valueStr + ' ' + appliedFilter[2].code}</p>
                            else
                              return <></>
                          default:
                            return appliedFilter.map((f) => {
                              return <p className={'text-14-24-500 text-black'}>{f.label}</p>
                            })
                        }
                      })()
                    }
                  </div>
                </div>
              );
            })}
          </div>
        }
      </div>
      <FilterPaymentType isOpen={editFilter != undefined && editFilter.name == JobFilterType.PaymentType}
                         filters={appliedFilters.get(JobFilterType.PaymentType)}
                         onSave={(filters) => {
                           const m = appliedFilters
                           if (filters == undefined) {
                             m.set(JobFilterType.PaymentType, [])
                           } else {
                             m.set(JobFilterType.PaymentType, filters)
                           }
                           onFiltersChanged(m)
                         }}
                         onClose={() => setEditFilter(undefined)}/>
      <FilterPaymentRange isOpen={editFilter != undefined && editFilter.name == JobFilterType.PaymentRange}
                          filters={appliedFilters.get(JobFilterType.PaymentRange)}
                          onSave={(filters) => {
                            const m = appliedFilters
                            if (filters == undefined) {
                              m.set(JobFilterType.PaymentRange, [])
                            } else {
                              m.set(JobFilterType.PaymentRange, filters)
                            }
                            onFiltersChanged(m)
                          }}
                          onClose={() => setEditFilter(undefined)}/>
      <FilterProfession isOpen={editFilter != undefined && editFilter.name == JobFilterType.Profession}
                        filters={appliedFilters.get(JobFilterType.Profession)}
                        onSave={(filters) => {
                          const m = appliedFilters
                          if (filters == undefined) {
                            m.set(JobFilterType.Profession, [])
                          } else {
                            m.set(JobFilterType.Profession, filters)
                          }
                          onFiltersChanged(m)
                        }}
                        onClose={() => setEditFilter(undefined)}/>
      <FilterLanguage isOpen={editFilter != undefined && editFilter.name == JobFilterType.Language}
                      filters={appliedFilters.get(JobFilterType.Language)}
                      onSave={(filters) => {
                        const m = appliedFilters
                        if (filters == undefined) {
                          m.set(JobFilterType.Language, [])
                        } else {
                          m.set(JobFilterType.Language, filters)
                        }
                        onFiltersChanged(m)
                      }}
                      onClose={() => setEditFilter(undefined)}/>
      <FilterSkill isOpen={editFilter != undefined && editFilter.name == JobFilterType.Skill}
                   filters={appliedFilters.get(JobFilterType.Skill)}
                   onSave={(filters) => {
                     const m = appliedFilters
                     if (filters == undefined) {
                       m.set(JobFilterType.Skill, [])
                     } else {
                       m.set(JobFilterType.Skill, filters)
                     }
                     onFiltersChanged(m)
                   }}
                   onClose={() => setEditFilter(undefined)}/>
      <FilterWorkType isOpen={editFilter != undefined && editFilter.name == JobFilterType.WorkType}
                      filters={appliedFilters.get(JobFilterType.WorkType)}
                      onSave={(filters) => {
                        const m = appliedFilters
                        if (filters == undefined) {
                          m.set(JobFilterType.WorkType, [])
                        } else {
                          m.set(JobFilterType.WorkType, filters)
                        }
                        onFiltersChanged(m)
                      }}
                      onClose={() => setEditFilter(undefined)}/>
      <FilterLocation isOpen={editFilter != undefined && editFilter.name == JobFilterType.Location}
                      filters={appliedFilters.get(JobFilterType.Location)}
                      onSave={(filters) => {
                        const m = appliedFilters
                        if (filters == undefined) {
                          m.set(JobFilterType.Location, [])
                        } else {
                          m.set(JobFilterType.Location, filters)
                        }
                        onFiltersChanged(m)
                      }}
                      onClose={() => setEditFilter(undefined)}/>
      {/*<FilterServiceOptions isOpen={editFilter != undefined && editFilter.name == JobFilterType.ServiceOptions}*/}
      {/*                      onClose={() => setEditFilter(undefined)}/>*/}
      <FilterDeliveryDate isOpen={editFilter != undefined && editFilter.name == JobFilterType.DeliveryDate}
                          filters={appliedFilters.get(JobFilterType.DeliveryDate)}
                          onSave={(filters) => {
                            const m = appliedFilters
                            if (filters == undefined) {
                              m.set(JobFilterType.DeliveryDate, [])
                            } else {
                              m.set(JobFilterType.DeliveryDate, filters)
                            }
                            onFiltersChanged(m)
                          }}
                          onClose={() => setEditFilter(undefined)}/>

    </>
  );
}
