import {
  Button,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { MainContext } from "src/context/MainContext";
import { useContext, useEffect, useState } from "react";
import { Translation } from "../types/Translation";
import CLoadingAnimation from "../components/CLoadingAnimation";
import { useNavigate } from "react-router-dom";
import { CShopProfile } from "src/components/CShopProfile";
import CEllipseTextBox from "src/components/CEllipseTextBox";
import { Experts } from "src/components/Shop/Experts";
import { Faq } from "src/components/Shop/Faq";

export enum ShopType {
  None = "",
  Provider = "provider",
  Recipient = "recipient",
}

export function ProviderServices() {
  const navigate = useNavigate();
  const mainContext = useContext(MainContext);
  const [selectedRoute, setSelectedRoute] = useState(ShopType.None);
  useEffect(() => {
    // if (localStorage.getItem("userData") == null) {
    //   navigate("/");
    // }
  }, []);

  const [translations, setTranslations] = useState<
    Map<string, Translation> | undefined
  >(undefined);

  const imgUrl =
    "https://www.pofft.com/Upload/PagePicture/20231003011722_2.jpg";

  return (
    <div className="justify-between main-width-desktop m-auto main-padding pt-[96px] pb-[48px] lg:pb-[72px] items-center h-full">
      <div className="flex w-full relative">
        <CShopProfile />
        <div className={"w-full relative"}>
          <div className={"flex-col w-full flex-1 h-full max-h-[100%]"}>
            <div className="p-[24px] pt-0 pr-0 dashboardHeader">
              <div className="pt-[24px]">
                <div
                  className="rounded-[16px] div-mindaro py-[16px] px-[24px]"
                  style={{ boxShadow: "0px 20px 80px 0px #EBECF0" }}
                >
                  <div className="flex justify-between items-center">
                    <div className="text-24-40-500 text-black">
                      {mainContext?.getTranslation("common", "hadi-basla")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-full ml-[24px] shop-select ">
              {false && (
                <div className={" h-full w-full flex px-[24px] z-[9999]"}>
                  <div
                    className={
                      "h-full w-full flex flex-col justify-center items-center"
                    }
                  >
                    <CLoadingAnimation size={120} />
                  </div>
                </div>
              )}

              <div className="publishProject">
                <section className="welcomeShop">
                  <CEllipseTextBox text="pofft Dükkan" />
                  <h1 className="title">pofft Dükkan’a Hoş Geldin!</h1>
                  <p className="description">
                    poftt Dükkan; ürün ve hizmetlerini standart listeleme ve
                    fiyatlandırma ile hizmet alanlara sunabileceğin, gömülü bir
                    e-ticaret platformu.
                  </p>
                  <p className="description">
                    Ürün ve hizmetlerini listeleyerek hızlıca pofft Dükkan’da
                    yerini alabilir, satışa başlayabilirsin.
                  </p>
                  <div className="action">
                    <Button className="purpleButtonMini" onClick={() => {}}>
                      <span>Proje Yayınla</span>
                    </Button>
                  </div>
                </section>
                <section className="howToWork">
                  <h1 className="title">Nasıl Çalışır?</h1>
                  <div className="cards">
                    <div className="card">
                      <img src="/images/shop-card1.svg" />
                      <p className="description">
                        Lorem ipsum dolor sit amet consectetur. Dui quam justo
                        platea sit.
                      </p>
                    </div>
                    <div className="card">
                      <img src="/images/shop-card2.svg" />
                      <p className="description">
                        Lorem ipsum dolor sit amet consectetur. Dui quam justo
                        platea sit.
                      </p>
                    </div>
                    <div className="card">
                      <img src="/images/shop-card3.svg" />
                      <p className="description">
                        Lorem ipsum dolor sit amet consectetur. Dui quam justo
                        platea sit.
                      </p>
                    </div>
                  </div>
                </section>
                <section className="joinUs">
                  <h1 className="title">Aramıza Katıl!</h1>
                  <p className="description">
                    Lorem ipsum dolor sit amet consectetur.
                  </p>
                  <Experts />
                </section>

                <section className="faq">
                  <h1 className="title">Sıkça Sorulan Sorular</h1>
                  <p className="description">
                    Lorem ipsum dolor sit amet consectetur.
                  </p>
                  <Faq />
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
