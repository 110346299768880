import {useNavigate} from "react-router-dom";
import {OBottomMenu} from "../objects/OMenu";
import {MainContext} from "src/context/MainContext";
import {useContext} from "react";

function CFooter() {
  const mainContext = useContext(MainContext);

  const navigate = useNavigate();
  return (
    <div className="div-lightest-purple w-full pt-[75px] flex-col">
      <div className="main-padding main-width m-auto flex-col lg:flex-row space-around gap-[44px]">
        <div className="flex-col w-[150px] lg:w-unset text-center lg:text-start self-center lg:self-start">
          <img src={"/images/pofft_logo.svg"}/>
          <div className="py-[44px] flex-col gap-[16px] text-grey text-14-17-400">
            {/* <div
              className="menu-text"
              onClick={() => {
                navigate("/hakkimizda");
              }}
            >
              {mainContext?.getTranslation("menu", "hakkimizda")}
            </div> */}
            {/* <div
              className="menu-text"
              onClick={() => {
                navigate("/partner");
              }}
            >
              {mainContext?.getTranslation("menu", "partner")}
            </div> */}
            {/*<div*/}
            {/*  className="menu-text"*/}
            {/*  onClick={() => {*/}
            {/*    navigate("/blogs");*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {mainContext?.getTranslation("menu", "blog")}*/}
            {/*</div>*/}
            {/* <div
              className="menu-text"
              onClick={() => {
                navigate("/press");
              }}
            >
              {mainContext?.getTranslation("menu", "basinda-biz")}
            </div> */}
          </div>
          <div className="flex justify-between">
            <img
              onClick={() => {
                window.open("https://www.facebook.com/pofft/", "_blank");
              }}
              className="w-[24px] h-[24px]"
              src="/images/social_Facebook.svg"
            />
            <img
              onClick={() => {
                window.open("https://www.instagram.com/pofftcom/", "_blank");
              }}
              className="w-[24px] h-[24px]"
              src="/images/social_Instagram.svg"
            />
            <img
              onClick={() => {
                window.open("https://twitter.com/pofftcom", "_blank");
              }}
              className="w-[24px] h-[24px]"
              src="/images/social_X.svg"
            />
            <img
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/pofft/",
                  "_blank"
                );
              }}
              className="w-[24px] h-[24px]"
              src="/images/social_Linkedin.svg"
            />
          </div>
        </div>

        {mainContext?.translationsLoaded &&
          OBottomMenu(mainContext!.getTranslation).map((menu,i) => {
            return (
              <div key={menu.link+i}
                className="flex-col gap-[16px] max-w-[240px] flex-1 text-center self-center lg:text-start lg:self-start">
                <div
                  role="button"
                  className="text-16-24-500 pb-[8px] menu-text"
                  onClick={() => {
                    window.location.href = (menu.link);
                  }}
                >
                  {menu.title}
                </div>
                {menu.items?.map((item,ii) => {
                  return (
                    <div
                      key={item.text+ii}
                      role="button"
                      className="menu-text text-14-24-400 text-grey"
                      onClick={() => {
                        if (item.link) {
                          window.location.href = (item.link);
                        } else if (item.func) {
                          item.func();
                        }
                      }}
                    >
                      {item.text}
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
      <div className="pt-[72px] pb-[24px] main-padding main-width">
        <div
          className="text-10-16-400 text-light-grey whitespace-pre-line"
          dangerouslySetInnerHTML={{
            __html:
              mainContext
                ?.getTranslation("menu", "footer")
                .replaceAll("\\n", "\n") ?? "",
          }}
        ></div>
      </div>
    </div>
  );
}

export default CFooter;
