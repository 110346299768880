import {useContext, useEffect, useState} from "react";
import {MainContext} from "../context/MainContext";
import {getProfessionalData, ProfessionalProfileData} from "../service/profileRequest";
import {Translation} from "../types/Translation";
import {isOfferableJobStatus, JobStatus, parseJobsWithOfferResponse, TJob, TJobWithOffer} from "../types/TJob";
import {TError} from "../types/TError";
import {getCountries, getTranslations, getWorkingTypes} from "../service/commonRequest";
import {getV2} from "../utils/request";
import {PageState} from "../types/Context";
import CLoadingAnimation from "./CLoadingAnimation";
import {CProfileCard} from "./Profile/CProfileCard";
import {CUpdateOfferModal} from "./CUpdateOfferModal";
import {CInspectOfferByProfessionalModal} from "./CInspectOfferByProfessionalModal";
import {useNavigate} from "react-router-dom";
import {NotificationType} from "./Toast/Toast";
import {UserType} from "../types/TUserData";

export enum JobListingPage {
  Applications,
  IncomingOffers
}

interface IProps {
  pageType: JobListingPage
}

export function JobListing({pageType}: IProps) {
  const navigate = useNavigate()
  const mainContext = useContext(MainContext);
  const [profileData, setProfileData] = useState<ProfessionalProfileData | undefined>(undefined)
  const [translations, setTranslations] = useState<
    Map<string, Translation> | undefined
  >(undefined);
  const [editApplication, setEditApplication] = useState<TJobWithOffer | undefined>(undefined)
  const [inspectApplication, setInspectApplication] = useState<TJobWithOffer | undefined>(undefined)
  const [projects, setProjects] = useState<TJobWithOffer[] | undefined>(undefined)
  const [priceMinWidth, setPriceMinWidth] = useState(0)
  const [companyPriceMinWidth, setCompanyPriceMinWidth] = useState(0)

  function fetchProfileData() {
    setProfileData(undefined)
    getProfessionalData().then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'getData')
      } else {
        setProfileData(r)
      }
    })
  }

  async function fetchProjects() {
    setProjects(undefined)
    getV2('/ProjectOffer/listbyprofessionalID?professionalKey=' + mainContext?.userData?.nameId).then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'getData')
        return
      }
      let filteredJobs: TJobWithOffer[] = parseJobsWithOfferResponse(r.data, mainContext?.getTranslation('common', 'halen')).filter(p => {
        if (pageType == JobListingPage.IncomingOffers) {
          return isOfferableJobStatus(p.status)
        }
        return true;
      });
      setProjects(filteredJobs)
    })
  }

  function getTranslation(parent: string, child: string): string {
    return translations?.get(parent)?.sentences.get(child) ?? "";
  }

  function getPageTranslation(child: string): string {
    return getTranslation('sayfa', child) ?? "";
  }

  function getDaysBefore(p: TJob) {
    if (p.endDateObj) {
      const now = new Date()
      const diff = p.endDateObj.getTime() - now.getTime()
      const days = Math.floor(diff / (1000 * 60 * 60 * 24))
      return mainContext?.getTranslation('common', 'gun-once').replace('{val}', days.toString())
    } else {
      return ''
    }
  }

  async function fetchData() {
    getTranslations('proje-sayfa', (translations, data) => {
      setTranslations(translations);
      fetchDataAsync();
    })
  }

  async function fetchDataAsync() {
    fetchProfileData()
    var err = undefined
    err = await getCountries(mainContext)
    if (err instanceof TError) {
      return
    }
    err = await getWorkingTypes(mainContext)
    if (err instanceof TError) {
      return
    }
    fetchProjects()
  }

  useEffect(() => {
    if (mainContext?.userData?.token) {
      const isExpired = mainContext.isExpired('joblisting')
      if (isExpired) {
        setTimeout(() => {
          mainContext.setSigninupState(PageState.Signin)
        }, 1500)
        return
      }
      if (mainContext?.publicToken) {
        fetchData()
      }
    }
  }, [mainContext?.userData?.token, mainContext?.publicToken]);

  useEffect(() => {
    if ((projects?.length ?? 0) > 0) {
      const priceComponents = document.getElementsByClassName('price')
      let minPW = 0
      for (var i = 0; i < priceComponents.length; i++) {
        const c = priceComponents[i]
        //@ts-ignore
        const minW: number = c.offsetWidth
        if (minW > minPW) {
          minPW = minW
        }
      }
      setPriceMinWidth(minPW)

      const cPriceComponents = document.getElementsByClassName('companyprice')
      let minCPW = 0
      for (var i = 0; i < cPriceComponents.length; i++) {
        const c = cPriceComponents[i]
        //@ts-ignore
        const minW: number = c.offsetWidth
        if (minW > minCPW) {
          minCPW = minW
        }
      }
      setPriceMinWidth(minCPW)
    }
  }, [projects]);

  const isAllLoaded = profileData != undefined && projects != undefined && translations != undefined

  return <div
    className="main-width main-padding m-auto pt-[96px] pb-[48px] lg:pb-[72px] items-center h-full flex-col gap-[30px]">
    {!isAllLoaded ? <div className={'h-[100vh]'}><CLoadingAnimation/></div> : <>
      <div className={'flex w-full justify-between py-[48px] items-start gap-[40px]'}>
        <CProfileCard hideVisibilityIcon customClass={'!px-0 !w-[240px] flex justify-center'}/>
        <div className={'flex-col gap-[24px] flex-1 items-start py-[18px]'}>
          <p
            className={'text-18-40-500 text-purple'}>{getPageTranslation(pageType == JobListingPage.Applications ? 'basvurularim' : 'gelen-teklifler')}</p>
          <div className={'flex-col gap-[20px] w-full'}>
            {(projects && projects.length > 0) ? projects.map((p, i) => {
              return <div
                className={'flex gap-[36px] div-lightest-purple rounded-[16px] py-[32px] pl-[16px] pr-[36px] text-black items-center'}>
                <div className={'flex-col flex-1'} role={'button'} onClick={() => {
                  navigate('/project/' + p.projectKeyID)
                }}>
                  <h1 className={'text-15-24-700'}>{p.title}</h1>
                  <h2 className={'text-14-24-400 text-grey'}>{`${p.firm}`}</h2>
                  <h2 className={'text-14-24-400 text-grey'}>{`${getDaysBefore(p)}`}</h2>
                </div>
                {pageType == JobListingPage.Applications &&
                    <div>
                        <div className={'flex-col w-[255px]'}>
                            <h1 className={'text-15-24-700'}>{p.statusStr}</h1>
                          {/*<h1 className={'text-15-24-700'}>{getPageTranslation('proje-' + p.status.toLowerCase())}</h1>*/}
                          {/*TODO: backend data*/}
                            <h2 className={'text-14-24-400 text-grey'}>{getPageTranslation(`projectStatus2_${p.status}`)}</h2>
                        </div>
                    </div>
                }
                {pageType == JobListingPage.IncomingOffers &&
                    <div className={'flex-col p-[10px] bg-white rounded-[10px]'}>
                        <h1 className={`companyprice whitespace-nowrap text-15-24-700`}
                            style={{minWidth: `${companyPriceMinWidth}px`}}>{(p.companyRequestPrice != undefined && p.companyRequestPrice > 0) ? `${p.companyRequestPrice} TL` : '-'}</h1>
                        <h2 className={'text-10-24-400 text-grey'}>{getPageTranslation('sirketin-teklifi')}</h2>
                    </div>
                }
                <div className={'flex-col p-[10px] bg-white rounded-[10px]'}>
                  <h1 className={`price whitespace-nowrap text-15-24-700`}
                      style={{minWidth: `${priceMinWidth}px`}}>{`${p.price} TL`}</h1>
                  <h2 className={'text-10-24-400 text-grey'}>{getPageTranslation('onerdigin-teklif')}</h2>
                </div>
                {pageType == JobListingPage.Applications &&
                    <div className={'flex'}>
                      {(p.status == JobStatus.Waiting || (
                        (p.status == JobStatus.CompanyUpdateOffer && (mainContext?.userData?.userType ?? UserType.Company) == UserType.Professional) ||
                        (p.status == JobStatus.ProfessionalUpdateOffer && (mainContext?.userData?.userType ?? UserType.Professional) == UserType.Company))) ?
                        <div className={'flex justify-center w-[200px]'}>
                          <div role={'button'} onClick={() => setEditApplication(p)}
                               className={'div-green rounded-[8px] px-[24px] py-[12px]'}>
                            <p className={'text-12-16-600 text-white'}>{getPageTranslation('teklifi-guncelle')}</p>
                          </div>
                        </div> :
                        <p className={'w-[200px] text-center text-purple text-13-24-500'}>
                          {getPageTranslation(`projectStatus3_${p.status}`)}
                        </p>
                      }
                    </div>
                }
                {pageType == JobListingPage.IncomingOffers &&
                    <div className={'flex gap-[14px] pl-[12px]'}>
                        <div role={'button'} onClick={() => {
                          navigate('/project/' + p.projectKeyID)
                        }}
                             className={'bg-white border-green rounded-[8px] px-[24px] py-[12px] table'}>
                            <p className={'text-12-16-600 text-green table-cell align-middle'}>{getPageTranslation('ilani-gor')}</p>
                        </div>
                        <div role={'button'} onClick={() => {
                          setEditApplication(p)
                        }}
                             className={'div-green rounded-[8px] px-[24px] py-[12px] table'}>
                            <p className={'text-12-16-600 text-white table-cell align-middle'}>{
                              getPageTranslation('teklifi-guncelle')
                            }</p>
                        </div>
                    </div>
                }
              </div>
            }) : <div className={'flex justify-center items-center p-[24px]'}>
              <div className={'text-grey text-24-24-500'}>
                {pageType == JobListingPage.Applications ? getPageTranslation('basvuru-yapmadin') : getPageTranslation('teklifin-yok')}
              </div>
            </div>}
          </div>
        </div>
      </div>
    </>}
    <CUpdateOfferModal getTranslation={getPageTranslation} isOpen={editApplication != undefined} job={editApplication}
                       close={() => setEditApplication(undefined)} update={() => fetchProjects()}/>
    <CInspectOfferByProfessionalModal getTranslation={getPageTranslation} isOpen={inspectApplication != undefined}
                                      job={inspectApplication}
                                      close={() => setInspectApplication(undefined)} update={() => fetchProjects()}/>
  </div>
}
