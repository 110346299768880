import {Box, Modal} from "@mui/material";
import './Modal.scss'
import {useContext} from "react";
import {MainContext} from "../../context/MainContext";
import {ModalButton} from "../Input/ModalButton";

interface IProps {
  title?: string;
  isOpen: boolean
  onClose: () => void
  onSave?: () => void
  children: React.ReactElement
  saveDisabled?: boolean
  requesting?: boolean
}

export function BModal({title, isOpen, onClose, onSave, saveDisabled,requesting, children}: IProps) {

  const mainContext = useContext(MainContext);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '16px',
    minWidth: '700px'
  };

  return <Modal open={isOpen} onClose={onClose} disableAutoFocus={true}>
    <Box sx={style}>
      <div className={'bModal'}>
        {title ?
          <div className={'title'}>
            <span>{title}</span>
            <img onClick={onClose} src={'/images/closeModal.svg'}/>
          </div> : <div className={'flex justify-end'}>
            <img onClick={onClose} src={'/images/closeModal.svg'}/>
          </div>}
        {children}
        {onSave &&
            <div className={'pt-[60px] flex justify-end gap-[12px]'}>
                <ModalButton className={`bg-white button ${requesting==true?'':'cancel'}`}
                             loading={false}
                             textClassName={'text-light-red'} disabled={requesting==true}
                             text={mainContext?.getTranslation("common", "iptal") ?? ''} onClick={onClose}/>
                <ModalButton className={'div-green button'}
                             textClassName={''} loading={requesting==true} disabled={requesting==true ||saveDisabled==true}
                             text={mainContext?.getTranslation("common", "kaydet") ?? ''} onClick={onSave}/>
            </div>
        }
      </div>
    </Box>
  </Modal>
}
