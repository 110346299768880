import { Checkbox } from "@chakra-ui/react";
import { Button, TextField } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import CHeroPartner from "src/components/CHeroPartner";
import CLoadingAnimation from "src/components/CLoadingAnimation";
import { DataProps } from "src/types/DataProps";
import { Translation, parseTranslation } from "src/types/Translation";
import {filter, getV2, postV2} from "src/utils/request";
import { MainContext } from "../context/MainContext";
import { isDesktop } from "../utils/window";
import {
  multipleNameRegex,
  emailRegex,
  optionalNameRegex,
  phoneRegex,
} from "../types/Regex";
import { CSuccess } from "src/components/CSuccess";
import { CFail } from "../components/CFail";
import { createTableHTML, rowData } from "src/utils/table";
import {getTranslations} from "../service/commonRequest";

export default function Partner() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, []);
  const mainContext = useContext(MainContext);
  const [data, setData] = useState<DataProps[] | undefined>(undefined);
  const [translations, setTranslations] = useState<
    Map<string, Translation> | undefined
  >(undefined);

  function getTranslation(child: string): string {
    return translations?.get("sayfa")?.sentences.get(child) ?? "";
  }

  useEffect(() => {
    if (mainContext?.publicToken) {
      getTranslations('partner',(translations,data)=>{
        setData(data);
        setTranslations(translations);
      })
    }
  }, [mainContext?.publicToken]);

  useEffect(() => {
    if (data != undefined) {
      setTimeout(() => {
        const refs = window.location.href.split("#");
        if (refs !== null && refs.length > 1) {
          const targetto = refs[1];
          const target = document.getElementById(targetto);
          const scrollToY = target!.offsetTop;
          console.log(`Y: ${targetto} px: ${scrollToY}`);
          window.scrollTo(0, scrollToY - 100);
        } else {
          window.scrollTo(0, 0);
        }
      }, 250);
    }
  }, [data]);

  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [text, setText] = useState("");
  const [contact, setContact] = useState(false);
  const [contactSms, setContactSms] = useState(false);
  const [contactPhone, setContactPhone] = useState(false);
  const [contactEmail, setContactEmail] = useState(false);

  const [nameError, setNameError] = useState<undefined | string>(undefined);
  const [emailError, setEmailError] = useState<undefined | string>(undefined);
  const [phoneError, setPhoneError] = useState<undefined | string>(undefined);
  const [textError, setTextError] = useState<undefined | string>(undefined);
  const [companyNameError, setCompanyNameError] = useState<undefined | string>(
    undefined
  );

  const [requesting, setRequesting] = useState(false);
  const [formState, setFormState] = useState<boolean | string>(false);

  useEffect(() => {
    if (name.length == 0) {
      setNameError(undefined);
    } else {
      multipleNameRegex.test(name)
        ? setNameError(undefined)
        : setNameError(
            mainContext!.getTranslation("giris-kayit", "gecersiz-ad")
          );
    }
  }, [name]);

  useEffect(() => {
    if (phone.length == 0) {
      setPhoneError(undefined);
    } else {
      phoneRegex.test(phone)
        ? setPhoneError(undefined)
        : setPhoneError(
            mainContext!.getTranslation("giris-kayit", "gecersiz-telefon")
          );
    }
  }, [phone]);

  useEffect(() => {
    if (companyName.length == 0) {
      setCompanyNameError(undefined);
    } else {
      optionalNameRegex.test(companyName)
        ? setCompanyNameError(undefined)
        : setCompanyNameError(
            mainContext!.getTranslation("giris-kayit", "gecersiz-sirketismi")
          );
    }
  }, [companyName]);

  useEffect(() => {
    if (email.length == 0) {
      setEmailError(undefined);
    } else {
      emailRegex.test(email)
        ? setEmailError(undefined)
        : setEmailError(
            mainContext!.getTranslation("giris-kayit", "gecersiz-email")
          );
    }
  }, [email]);

  const canSendForm =
    contact == true &&
    email.length > 0 &&
    emailError == undefined &&
    name.length > 0 &&
    nameError == undefined &&
    (phone.length == 0 || phoneError == undefined) &&
    (companyName.length == 0 || companyNameError == undefined);

  async function sendForm() {
    setRequesting(true);

    const datas:rowData[]=[
      {data1:'Yeni bir form dolduruldu'},
      {data1:'Ad',data2:name},
      {data1:'Email',data2:email}
    ]

    if (phone.length > 0) {
      datas.push({data1:'Telefon',data2:phone})
    }
    if (companyName.length > 0) {
      datas.push({data1:'Şirket',data2:companyName})
    }
    if (contactEmail || contactPhone || contactSms) {
      let channels = '';
      if (contactEmail) {
        channels = channels + " email";
      }
      if (contactPhone) {
        channels = channels + " telefon";
      }
      if (contactSms) {
        channels = channels + " sms";
      }
      datas.push({data1:'Ulaşılmak istenen kanal(lar)',data2:channels})
    }
    if (text.length > 0) {
      datas.push({data1:'Birlikte neler yapabiliriz',data2:text})
    }


    postV2("/Common/sendemailform?subject=pofft Bize ulaşın, " + email+"&body="+createTableHTML(datas),{},true).then((resp) => {
      setRequesting(false);
      if (resp.data == true) {
        setFormState(true);
      } else {
        setFormState(resp.data.message);
      }
    });
  }

  return data == undefined ? (
    <div className="h-[100vh] w-[100vw] flex-col justify-center">
      <CLoadingAnimation />
    </div>
  ) : (
    (() => {
      const pictures = filter(data, "sayfa");

      return (
        <>
          <CSuccess
            open={formState == true}
            onClose={() => {
              setFormState(false);
            }}
            text="Başarıyla iletildi"
          />
          <CFail
            open={formState != true && formState != false}
            onClose={() => {
              setFormState(false);
            }}
            text={formState as string}
          />
          <CHeroPartner getTranslation={getTranslation} data={pictures} />
          <a id="bizeyazin"></a>
          <div className="main-width m-auto main-padding">
            <div className="contactus">
              {isDesktop() && (
                <img
                  className="absolute right-0 top-0 bottom-0 left-0 h-full object-cover"
                  src="/images/Banner2.svg"
                />
              )}
              <div className="w-full flex-col items-center place-content-center lg:pt-[150px] lg:pb-[170px] relative rounded-[16px] mobileonly:py-[24px]">
                <div className="flex-col items-center max-w-[640px]">
                  <div className="text-32-40-500">
                    {mainContext?.getTranslation("common", "bize-yazin")}
                  </div>
                  <div className="pt-[16px] text-16-24-400 text-grey text-center">
                    {mainContext?.getTranslation("contact", "bize-yazin-text")}
                  </div>

                  <div className="pt-[8px] w-full">
                    <div className="pt-[16px]">
                      <TextField
                        disabled={requesting}
                        id="outlined-basic"
                        label={
                          mainContext?.getTranslation("common", "input-isim") +
                          " *"
                        }
                        variant="outlined"
                        className="basicInput pt-[16px] !w-full form-text-grey"
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      {nameError && (
                        <div className="text-red-400 text-12-14-500 pt-[8px] pl-[16px]">
                          {nameError}
                        </div>
                      )}
                    </div>
                    <div className="pt-[16px]">
                      <TextField
                        id="outlined-basic"
                        disabled={requesting}
                        label={mainContext?.getTranslation(
                          "common",
                          "input-sirketadi"
                        )}
                        variant="outlined"
                        className="basicInput pt-[16px] !w-full form-text-grey"
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                      />
                      {companyNameError && (
                        <div className="text-red-400 text-12-14-500 pt-[8px] pl-[16px]">
                          {companyNameError}
                        </div>
                      )}
                    </div>
                    <div className="pt-[16px]">
                      <TextField
                        id="outlined-basic"
                        disabled={requesting}
                        label={
                          mainContext?.getTranslation(
                            "common",
                            "input-eposta"
                          ) + " *"
                        }
                        variant="outlined"
                        className="basicInput pt-[16px] !w-full form-text-grey"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                      {emailError && (
                        <div className="text-red-400 text-12-14-500 pt-[8px] pl-[16px]">
                          {emailError}
                        </div>
                      )}
                    </div>
                    <div className="pt-[16px]">
                      <TextField
                        id="outlined-basic"
                        disabled={requesting}
                        label={mainContext?.getTranslation(
                          "common",
                          "input-telefon"
                        )}
                        inputMode="tel"
                        type="number"
                        variant="outlined"
                        className="basicInput pt-[16px] !w-full form-text-grey"
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                      />
                      {phoneError && (
                        <div className="text-red-400 text-12-14-500 pt-[8px] pl-[16px]">
                          {phoneError}
                        </div>
                      )}
                    </div>
                    <div className="pt-[16px]">
                      <TextField
                        id="outlined-basic"
                        disabled={requesting}
                        label={mainContext?.getTranslation(
                          "common",
                          "input-neyapabiliriz"
                        )}
                        multiline={true}
                        variant="outlined"
                        className="basicInput pt-[16px] !w-full form-text-grey"
                        onChange={(e) => {
                          setText(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      className={`flex justify-start text-left items-center pt-[16px]`}
                      // style={requesting ? { pointerEvents: "none" } : {}}
                    >
                      <Checkbox
                        disabled={requesting}
                        className="checkBox"
                        // isChecked={checkKvkk}
                        onChange={(e) => {
                          setContact(e.target.checked);
                        }}
                      />
                      <div
                        className="text-12-16-400 text-black flex-col justify-center pl-[12px] flex-1"
                        onClick={() => {
                          // setCheckKvkk(checkKvkk);
                        }}
                        // style={requesting ? { pointerEvents: "none" } : {}}
                      >
                        {mainContext?.getTranslation(
                          "common",
                          "bize-yazin-checkbox"
                        )}
                      </div>
                    </div>
                    <div className="text-12-16-400 text-black pt-[16px]">
                      {mainContext?.getTranslation(
                        "common",
                        "bize-yazin-ticari-ileti"
                      )}
                    </div>
                    <div
                      className={`flex justify-start text-left items-center pt-[16px]`}
                      // style={requesting ? { pointerEvents: "none" } : {}}
                    >
                      <Checkbox
                        disabled={requesting}
                        className="checkBox"
                        // isChecked={checkKvkk}
                        onChange={(e) => {
                          setContactSms(e.target.checked);
                        }}
                      />
                      <div
                        className="text-12-16-400 text-black flex-col justify-center pl-[6px]"
                        onClick={() => {
                          // setCheckKvkk(checkKvkk);
                        }}
                        // style={requesting ? { pointerEvents: "none" } : {}}
                      >
                        {mainContext
                          ?.getTranslation("common", "sms")
                          .toUpperCase()}
                      </div>
                      <div className="w-[40px]" />
                      <Checkbox
                        disabled={requesting}
                        className="checkBox"
                        // isChecked={checkKvkk}
                        onChange={(e) => {
                          setContactPhone(e.target.checked);
                        }}
                      />
                      <div
                        className="text-12-16-400 text-black flex-col justify-center pl-[6px]"
                        onClick={() => {
                          // setCheckKvkk(checkKvkk);
                        }}
                        // style={requesting ? { pointerEvents: "none" } : {}}
                      >
                        {mainContext?.getTranslation("common", "telefon")}
                      </div>
                      <div className="w-[40px]" />
                      <Checkbox
                        disabled={requesting}
                        className="checkBox"
                        // isChecked={checkKvkk}
                        onChange={(e) => {
                          setContactEmail(e.target.checked);
                        }}
                      />
                      <div
                        className="text-12-16-400 text-black flex-col justify-center pl-[6px]"
                        onClick={() => {
                          // setCheckKvkk(checkKvkk);
                        }}
                        // style={requesting ? { pointerEvents: "none" } : {}}
                      >
                        {mainContext?.getTranslation("common", "eposta")}
                      </div>
                    </div>
                    <div className="pt-[24px] mobileonly:text-center">
                      <Button
                        disabled={!canSendForm || requesting}
                        className="purpleButton"
                        onClick={() => {
                          sendForm();
                        }}
                      >
                        <span>
                          {mainContext?.getTranslation("common", "gonder")}
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-[48px] lg:pt-[168px]"></div>
        </>
      );
    })()
  );
}
