import {useContext, useEffect, useState} from "react";
import {getV2} from "../utils/request";
import {RPartners} from "../types/RPartners";
import CSwiper3Loop from "./CSwiper3Loop";
import CLoadingAnimation from "./CLoadingAnimation";
import {isDesktop} from "../utils/window";
import CSwiper1Picture from "./CSwiper1Picture";
import {MainContext} from "../context/MainContext";
import {TError} from "../types/TError";
import {NotificationType} from "./Toast/Toast";
import {getTranslations} from "../service/commonRequest";

interface IProps {
  parentClass?: string
}

function COnesWithPofft({parentClass}: IProps) {
  const mainContext = useContext(MainContext)

  const [response, setResponse] = useState<undefined | RPartners>(undefined);

  useEffect(() => {
    if (mainContext?.publicToken) {
      getV2("/page/getpage?position=partner-logo",true).then((resp) => {
        if(resp instanceof TError){
          mainContext.showToast(NotificationType.Fail, 'getPartners')
          return
        }
        const partnersData = RPartners.parse(resp);
        setResponse(partnersData);
      });
    }
  }, [mainContext?.publicToken]);

  function getComponents(): JSX.Element {
    const list: JSX.Element[] = [];

    const pictureLength = response!.pagePictureModelList.length;
    for (let r = 0; r < pictureLength / 3; r++) {
      const subList: JSX.Element[] = [];
      for (let i = r * 3; i < r * 3 + 3; i++) {
        if (i + 1 <= pictureLength) {
          subList.push(
            <div>
              <img
                className="max-w-[192px]"
                src={response!.pagePictureModelList[i].getUrl()}
              ></img>
            </div>
          );
        }
      }
      list.push(<div className="flex justify-around w-full">{subList}</div>);
    }

    return <div className="flex-col">{list}</div>;
  }

  return (
    <div className={`flex-col justify-center w-full pt-[48px] ${parentClass}`}>
      {/* {response && getComponents()} */}
      {response == undefined ? (
        <CLoadingAnimation/>
      ) : isDesktop() ? (
        <CSwiper3Loop
          notActiveEffect={true}
          swipeAmount={3}
          data={{
            cards: response.pagePictureModelList.map((item) => {
              return (
                <div key={item.getUrl().toLowerCase()} className="flex-col justify-between h-full content">
                  <img src={item.getUrl()}/>
                </div>
              );
            }),
          }}
        />
      ) : (
        <CSwiper1Picture
          data={response.pagePictureModelList.map((item) => {
            return (
              <div className="flex-col justify-between h-full content">
                <img src={item.getUrl()}/>
              </div>
            );
          })}
        />
      )}
    </div>
  );
}

export default COnesWithPofft;
