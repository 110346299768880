import {useContext, useEffect, useState} from "react";
import {MainContext} from "../context/MainContext";
import {FilterModal} from "./Filter/FilterModal";
import {CCheckbox} from "./CCheckbox";
import styles from './Profile/Profile.module.scss'
import {TCertificate} from "../types/TCertificate";
import {dateToMonthYearString} from "../utils/date";
import {getV2, postV2} from "../utils/request";
import {TPortfolio} from "../types/TPortfolio";
import {TError} from "../types/TError";
import {NotificationType} from "./Toast/Toast";

interface IProps {
  getTranslation: (key: string) => string
  isOpen: boolean
  onUpdate: () => void
  close: () => void
  edit: (p: TCertificate) => void
  certificates: TCertificate[]
}

export function CCertificatesList({getTranslation, isOpen, close, onUpdate, edit, certificates}: IProps) {
  const mainContext = useContext(MainContext)

  const [requesting, setRequesting] = useState(false)
  const [tempCertificates, setTempCertificates] = useState<TCertificate[]>([])

  useEffect(() => {
    if (isOpen === true) {
      setTempCertificates([...certificates.map(c => ({...c}))])
    }
  }, [isOpen]);

  function changeVisibility(i: number) {
    let newProjects = [...tempCertificates].map((t, ii) => {
      if (ii == i) {
        t.visible = !t.visible
      }
      return t
    });
    setTempCertificates(newProjects)
  }

  function changeDeletion(e: any, i: number) {
    e.preventDefault();
    e.stopPropagation();
    let newProjects = [...tempCertificates].map((t, ii) => {
      if (ii == i) {
        t.deleted = t.deleted != undefined ? !t.deleted : true
      }
      return t
    });
    setTempCertificates(newProjects)
  }


  async function updateCertificateVisibility(p: TCertificate) {
    return await postV2('/ProfessionalCertificate/visible', {id: p.id, visible: p.visible}).then((resp) => {
      if (resp.data.result == true) {
        mainContext?.showToast(NotificationType.Success, p.visible ? 'makeVisible' : 'makeInvisible')
        return true
      }
      if (resp instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, p.visible ? 'makeVisible' : 'makeInvisible')
      }
      return false
    });
  }

  async function deleteCertificate(p: TCertificate) {
    return await getV2('/ProfessionalCertificate/delete?key=' + p.key).then((resp) => {
      if (resp.data.result == true) {
        mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
        return true
      }
      if (resp instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
      }
      return false
    });
  }

  async function save() {
    const newProjects = [...tempCertificates]
    setRequesting(true)
    let didFail = false
    let isFound = false
    for (let i = 0; i < tempCertificates.length; i++) {
      let find = certificates.find(p => p.id == tempCertificates[i].id);
      if (find && newProjects[i].deleted === true) {
        isFound = true
        const resp = await deleteCertificate(tempCertificates[i])
        if (resp == false) {
          didFail = true
          newProjects[i].visible = find.visible;
        }
      } else if (find && find.visible != tempCertificates[i].visible) {
        isFound = true
        const resp = await updateCertificateVisibility(tempCertificates[i])
        if (resp == false) {
          didFail = true
          newProjects[i].visible = find.visible;
        }
      }
    }
    setRequesting(false)
    if (isFound) {
      onUpdate()
    }
    if (didFail == true) {
      mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
    } else if (isFound) {
      mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
      close();
    }
    setTempCertificates(newProjects)
  }

  function Edit(e: any, c: TCertificate) {
    e.preventDefault();
    e.stopPropagation();
    edit(c)
  }

  const currentYear = new Date().getFullYear() + "";
  return <FilterModal
    title={getTranslation('sertifikalar')}
    header={''}
    isOpen={isOpen}
    onClose={close}
    onSave={save}
    requesting={requesting}
    saveDisabled={requesting}
    closeDisabled={requesting}
    customHeaderClass={'!pb-0'}
  >
    <div className={'w-full'}>
      <div className="flex-col gap-[36px] text-grey text-14-24-500 max-h-[60vh]">
        {getTranslation('gorunmesi-istenen-projeler')}
        <div className={'flex-col gap-[24px] overflow-y-auto'}>
          {tempCertificates.map((c, i) => {
            return <div onClick={() => changeVisibility(i)}
                        className={`rounded-[16px] py-[26px] px-[12px] flex ${c.deleted ? 'bg-[#FFAAAA]' : c.visible ? 'bg-[#DEDEFF]' : 'bg-[#F6F6FF]'}`}>
              <CCheckbox
                customClass={`!self-center !pb-0 ${c.deleted ? 'opacity-0' : ''}`}
                selected={c.visible}
                onChange={() => {
                }}
                disabled={requesting}
                textHtml={<></>}/>
              <div className={'flex-col gap-[12px] w-full'}>
                <div className={`${styles.content} flex justify-between`}>
                  <div className={'flex-col text-start items-start'}>
                    <h1 className={'!items-start !text-start'}>{c.title}</h1>
                    <h2 className={'!items-start !text-start text-14-24-500'}>{c.remark}</h2>
                    <h3
                      className={'!items-start !text-start text-12-24-400'}>{dateToMonthYearString(c.transactionDate)}</h3>
                  </div>
                  <div className={'flex gap-[16px] mr-[18px]'}>
                    <div role={'button'} className={'pt-[1px]'}
                         onClick={(e) => Edit(e, c)}>
                      <img className={'w-[22px] h-[22px]'} src={'/images/editButton.svg'}/>
                    </div>
                    <div className={'w-[24px] h-[24px] '} onClick={(e) => changeDeletion(e, i)}
                         role={'button'}>
                      <img src={tempCertificates[i].deleted ? '/images/trashBlack.svg' : '/images/trashPurple.svg'}/>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          })}
        </div>

      </div>
    </div>
  </FilterModal>
}
