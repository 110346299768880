import {CustomContentProps, OptionsWithExtraProps, SnackbarContent, SnackbarMessage, useSnackbar} from "notistack";
import {forwardRef} from "react";
import {getToastError, getToastSuccess} from "../../utils/toatsts";

export enum NotificationType {
  Success,
  Fail,
  Warning
}

export interface ToastProps {
  notificationType: NotificationType
  isApp: boolean
}

interface WebToastProps extends CustomContentProps {
  Props: ToastProps
}

export const BasicWebToast: OptionsWithExtraProps<any> = {
  variant: 'webNotification',
  isApp: false,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right'
  },
  maxSnack: 3,
  transitionDuration: {enter: 325, appear: 300, exit: 100},
}
export const SuccessWebToast: OptionsWithExtraProps<any> = {
  Props: {
    isApp: false,
    notificationType: NotificationType.Success
  },
  ...BasicWebToast
}
export const FailWebToast: OptionsWithExtraProps<any> = {
  Props: {
    isApp: false,
    notificationType: NotificationType.Fail
  },
  ...BasicWebToast
}

export const WebToast = forwardRef<HTMLDivElement, WebToastProps>((props, ref) => {

  const {closeSnackbar} = useSnackbar();

  const {
    id,
    message,
    Props,
    ...other
  } = props

  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      <div className={'py-[12px] px-[16px] rounded-[12px] flex justify-between w-full mt-[54px] max-w-[500px]'}
           style={{
             backgroundColor: Props.notificationType == NotificationType.Success ? '#00D490' : '#EA4747',
             color: '#FFF'
           }}>
        <div className={`flex justify-start items-center gap-[7px]`}>
          <img src={
            Props.notificationType == NotificationType.Success ?
              '/images/toastSuccess.svg' :
              Props.notificationType == NotificationType.Fail ?
                '/images/toastFail.svg' :
                '/images/toastWarning.svg'
          }/>
          <div className={'text-14-24-600 text-white'}>
            {message}
          </div>
        </div>
        <div className={'flex items-center gap-[5px] ml-[16px]'} onClick={() => {
          closeSnackbar(id)
        }}>
          <div className={'w-[1px] min-w-[1px] h-full'} style={{
            backgroundColor: Props.notificationType == NotificationType.Success ? '#B3FAAD' : '#FFA09D',
          }}></div>
          <img className={'h-fit'} src={Props.notificationType == NotificationType.Success ?
            '/images/toastCloseSuccess.svg' :
            Props.notificationType == NotificationType.Fail ?
              '/images/toastCloseFail.svg' :
              '/images/toastCloseWarning.svg'}/>
        </div>
      </div>
    </SnackbarContent>
  )
})

export function ShowManualToast(caller: any,type: NotificationType, message: string) {
  switch (type) {
    case NotificationType.Success:
      caller(message, SuccessWebToast);
      break;
    case NotificationType.Fail:
      caller(message, FailWebToast)
      break;
  }
}

export function ShowToast(caller: any, type: NotificationType, key: string = 'unknown') {
  switch (type) {
    case NotificationType.Success:
      const s = getToastSuccess(key) ?? 'İşlem Başarılı.(1)'
      caller(s, SuccessWebToast);
      break;
    case NotificationType.Fail:
      const f = getToastError(key) ?? 'Bilinmeyen bir hata oluştu, lütfen tekrar deneyin.(0)'
      caller(f, FailWebToast)
      break;
  }
}
