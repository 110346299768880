import styles from "../pages/ProjectDetail/ProjectDetail.module.scss";
import {Button} from "@mui/material";
import {BModal} from "./Modal/BModal";
import {useNavigate} from "react-router-dom";

interface IProps {
  isOpen: boolean
  close: () => void
  getTranslation: (parent: string, child: string) => string
  showUpdateButton: boolean
  updateText: string
}

export function COfferUpdatedModal({isOpen, close, getTranslation, showUpdateButton, updateText}: IProps) {
  const navigate = useNavigate();

  return <BModal isOpen={isOpen} onClose={close}
                 children={<div className={'flex flex-col items-center pt-[6px]'}>
                   <img className={'w-[356px]'} src={'/images/OfferSuccess.svg'}/>
                   <div className={'pt-[31px]'}>
                     <div className={'successTexts bigText'}>
                       <div>
                         {getTranslation('sayfa', 'tebrikler')}
                       </div>
                       <div>
                         {updateText}
                       </div>
                     </div>
                     <div className={`pt-[16px] ${styles.tebriksDetay}`}>
                       {getTranslation('sayfa', 'teklif-iletildi-metin')}
                     </div>
                     <div className={'pt-[41px] flex justify-center gap-[24px]'}>
                       {showUpdateButton &&
                           <Button className={'purpleButtonMini'}>
                             {getTranslation('sayfa', 'teklifi-guncelle')}
                           </Button>
                       }
                       <Button className={'purpleButtonMini !bg-[#05D490]'} onClick={() => navigate('/dashboard')}>
                         {getTranslation('sayfa', 'is-ara')}
                       </Button>
                       <Button className={'purpleButtonMini !bg-[#FD7571]'} onClick={() => navigate('/applications')}>
                         {getTranslation('sayfa', 'basvurularim')}
                       </Button>
                     </div>
                   </div>
                 </div>}/>
}
