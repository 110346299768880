import {postV2} from "../utils/request";
import {TError} from "./TError";
import {NotificationType} from "../components/Toast/Toast";

export enum PaymentStatus {
  Waiting = 'Waiting',
  Success = 'Success',
  Error = 'Error',
}

export interface TPaymentRequest {
  token: string
  tokenExpiresAt: Date
  checkoutFormContent: string
}

export function parsePaymentRequest(data: any): TPaymentRequest {
  return {
    token: data.token,
    tokenExpiresAt: new Date(data.systemTime + (data.tokenExpireTime * 1000)),
    checkoutFormContent: /*decodeURIComponent*/(data.checkoutFormContent).replace('<script type="text/javascript">', '').replace('</script>', ''),
  }
}

export function requestPayment(offerKey: string, context: any): Promise<TPaymentRequest | false> {
  const callbackUrl=window.location.origin + '/post-payment'
  return postV2(`/Payment/projectpayment?projectOfferKey=${offerKey}&callbackUrl=${callbackUrl}`, {}).then(r => {
      if (r instanceof TError) {
        context.showToast(NotificationType.Fail, 'payment')
        return false
      }
      if (r.data.status != 'success') {
        context.showToast(NotificationType.Fail, 'payment')
        return false
      }
      return parsePaymentRequest(r.data)
    }
  )
}

export function checkPaymentStatus(token: string): Promise<PaymentStatus> {
  const data = {
    culture: 'tr',
    token: token
  }
  return postV2(`/Payment/paymentresult?culture=tr&token=${token}`, data).then(r => {
      if (r instanceof TError) {
        return PaymentStatus.Error
      }
      if (r.data.status != 'Waiting') {
        console.log('stat: ' + JSON.stringify(r.data))
      }
      return r.data.status
    }
  )
}
