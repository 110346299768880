import {useContext, useEffect, useState} from "react";
import {MainContext} from "../../context/MainContext";
import {FilterConfig, FilterPageProps, JobFilterType} from "../../types/TFilter";
import {CSelectWithSearchForFilter} from "../Input/CSelectWithSearchForFilter";
import {getV2, postV2} from "../../utils/request";
import {TError} from "../../types/TError";
import {FilterModal} from "../Filter/FilterModal";
import {EnumProfileUpdateType} from "../../types/EnumProfileUpdateType";
import {CSelectWithSearchForFilterSingle} from "../Input/CSelectWithSearchForFilterSingle";
import {NotificationType} from "../Toast/Toast";

const jobFilterType = JobFilterType.PaymentType;

interface IProps {
  selectedValue?: number
  isOpen: boolean
  options: FilterConfig[]
  close: () => void
  update: (newValue?: number) => void
}

export function CProfilePaymentMethodModal({isOpen, close, update, options, selectedValue}: IProps) {
  const mainContext = useContext(MainContext);

  const [tempVal, setTempVal] = useState<FilterConfig | undefined>(undefined)

  function handleSave() {
    if (mainContext?.isExpired('profilepaymentmodal')) {
      return
    }
    postV2('/Professional/partialupdate', {
      updateType: EnumProfileUpdateType.WorkingPriceType,
      workingPriceTypeID: tempVal ? tempVal.id : undefined,
    }).then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
      } else {
        mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
        update(tempVal?.id)
        close();
      }
    }).catch((e) => {
      mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
    })
  }

  function onCancel() {
    close();
  }

  useEffect(() => {
    if (selectedValue) {
      const found = options.find(o => o.id == selectedValue)
      setTempVal(found)
    } else {
      setTempVal(undefined)
    }
  }, [selectedValue]);

  useEffect(() => {
    if (isOpen) {
      if (selectedValue) {
        const found = options.find(o => o.id == selectedValue)
        setTempVal(found)
      } else {
        setTempVal(undefined)
      }
    }
  }, [isOpen]);

  return <FilterModal
    title={mainContext!.getTranslation('filters', jobFilterType)}
    header={mainContext!.getTranslation('filters', 'hangisini-tercih-edersin')}
    desc={mainContext!.getTranslation('filters', 'teslim-suresine-gore-secim')}
    isOpen={isOpen} onClose={onCancel} onSave={handleSave}>
    <CSelectWithSearchForFilterSingle options={options} val={tempVal} loading={options == undefined}
      //@ts-ignore
                                      onChange={setTempVal}
    />
  </FilterModal>
}
