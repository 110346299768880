import {FilterModal} from "../Filter/FilterModal";
import {useContext, useEffect, useState} from "react";
import {MainContext, MainContextType} from "../../context/MainContext";
import {FilterConfig} from "../../types/TFilter";
import {CSelectWithSearchForFilterSingle} from "../Input/CSelectWithSearchForFilterSingle";
import {postV2} from "../../utils/request";
import {cities, countries, getCities} from "../../service/commonRequest";
import {TError} from "../../types/TError";
import {Phone} from "../Input/Phone";
import {CompanyProfileData, deepCopyCompany} from "../../types/TCompanyData";
import {parseCompanyDataRequest} from "../../service/companyRequest";
import {NotificationType} from "../Toast/Toast";

interface IProps {
  getTranslation: (key: string) => string
  isOpen: boolean
  close: () => void
  updated: () => void
  company?: CompanyProfileData
}

export function CCompanyContactEditModal({getTranslation, isOpen, close, company, updated}: IProps) {
  const mainContext = useContext(MainContext)
  const [tempCompany, setTempCompany] = useState<CompanyProfileData | undefined>(company)

  const [country, setCountry] = useState<FilterConfig | undefined>(undefined)
  const [Cities, setCities] = useState<FilterConfig[]>([])
  const [city, setCity] = useState<FilterConfig | undefined>(undefined)
  const [isUpdating, setIsUpdating] = useState(false)

  const isUpdatable = ((country == undefined && city == undefined) || (country != undefined && city != undefined)) &&
    ((tempCompany?.phone ?? '').length == 0 || (tempCompany?.phone ?? '').trim().length > 12)

  function SetCountry(filterConfig?: FilterConfig) {
    setCity(undefined)
    setCountry(filterConfig)
    if (filterConfig != undefined) {
      GetCities(filterConfig.id)
      setTempCompany({...tempCompany!, countryID: filterConfig.id})
    } else {
      setCities([])
      setTempCompany({...tempCompany!, countryID: 0})
    }
  }

  function SetCity(filterConfig?: FilterConfig) {
    setCity(filterConfig)
    if (filterConfig != undefined) {
      setTempCompany({...tempCompany!, cityID: filterConfig.id})
    } else {
      setTempCompany({...tempCompany!, cityID: 0})
    }
  }

  async function GetCities(countryId: number) {
    setCities([])
    await getCities(mainContext, countryId).then((r) => {
      if (r instanceof TError) {
      } else {
        setCities(r)
      }
    })
  }

  useEffect(() => {
    if (isOpen && company) {
      if (company.countryID > 0) {
        setCountry(countries.find(c => c.id == company.countryID))
        GetCities(company.countryID)
      } else {
        setCountry(undefined)
      }
      if (company.cityID > 0) {
        let find = cities.find(c => c.id == company.cityID);
        setCity(find)
      } else {
        setCity(undefined)
      }
      setTempCompany(deepCopyCompany(company))
    } else {
      setTempCompany(undefined)
    }
  }, [isOpen])

  function save() {
    if (mainContext?.isExpired('companycontactedit')) {
      return
    }
    if (tempCompany == undefined) {
      return;
    }
    const companyDataParsed = parseCompanyDataRequest(tempCompany!)
    setIsUpdating(true)
    postV2('/Company/update', companyDataParsed).then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateCompanyInfo')
      } else {
        mainContext?.showToast(NotificationType.Success, 'updateCompanyInfo')
        updated()
      }
    }).catch((e) => {
      mainContext?.showToast(NotificationType.Fail, 'updateCompanyInfo')
    }).finally(() => {
      setIsUpdating(false)
    })
  }

  return <FilterModal
    title={getTranslation('sirket-iletisimi')}
    header={getTranslation('sirket-telefon-numarasi')}
    isOpen={isOpen}
    onClose={close}
    onSave={save}
    requesting={isUpdating}
    saveDisabled={!isUpdatable || isUpdating}
    customHeaderClass={'!pb-[20px]'}
  >
    <div>
      <div className={'w-full'}>
        <Phone val={tempCompany?.phone ?? ''} setVal={(p) => setTempCompany({...tempCompany!, phone: p})}/>
      </div>
      <div className={'pt-[32px]'}>
        <div className={'modalTitle pb-[16px]'}>{getTranslation('sirket-adresi')}</div>
      </div>
      <input placeholder={mainContext?.getTranslation('profile', 'adres-giriniz')}
             className={'standardInput !color-[#6D6D6D]'}
             value={tempCompany?.address}
             onChange={(e) => {
               let newVal = e.target.value;
               if (newVal.length > 255) {
                 return
               }
               setTempCompany({...tempCompany!, address: newVal})
             }}
      />
      <div className={'pt-[16px] w-full flex gap-[28px]'}>
        <div className={'flex-col flex-1'}>
          <div className={'modalTitle py-[16px]'}>{mainContext?.getTranslation('common', 'ulke')}</div>
          {/*@ts-ignore*/}
          <CSelectWithSearchForFilterSingle onChange={SetCountry} options={countries} val={country}
                                            loading={false}/>
        </div>
        <div className={'flex-col flex-1'}>
          <div className={'modalTitle py-[16px]'}>{mainContext?.getTranslation('common', 'sehir')}</div>
          {/*@ts-ignore*/}
          <CSelectWithSearchForFilterSingle disabled={country == undefined || Cities.length == 0} onChange={SetCity}
                                            options={Cities} val={city}
                                            loading={false}/>
        </div>
      </div>
    </div>
  </FilterModal>
}
