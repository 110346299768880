import { useContext } from "react";
import { MainContext } from "src/context/MainContext";
import { PageState } from "src/types/Context";

interface IProps {
  onSwitch?: () => void;
}

export function CSignInUpSwitcher({ onSwitch }: IProps) {
  const mainContext = useContext(MainContext);
  return (
    <>
      <div className="text-14-24-400 text-black">
        {mainContext?.signinupState == PageState.Signup
          ? mainContext!.getTranslation("giris-kayit", "hemen-girisyap-oncesi")
          : mainContext!.getTranslation("giris-kayit", "hemen-kayitol-oncesi")}
        <span
          className="text-purple"
          role={'button'}
          onClick={() => {
            onSwitch?.();
            if (mainContext?.signinupState == PageState.Signin) {
              mainContext?.setSigninupState(PageState.Signup);
            } else if (mainContext?.signinupState == PageState.Signup) {
              mainContext?.setSigninupState(PageState.Signin);
            }
          }}
        >
          {mainContext?.signinupState == PageState.Signup
            ? mainContext!.getTranslation("giris-kayit", "hemen-girisyap")
            : mainContext!.getTranslation(
                "giris-kayit",
                "hemen-kayitol"
              )}
        </span>
        {mainContext?.signinupState == PageState.Signup
          ? mainContext!.getTranslation("giris-kayit", "hemen-girisyap-sonrasi")
          : mainContext!.getTranslation("giris-kayit", "hemen-kayitol-sonrasi")}
      </div>
    </>
  );
}
