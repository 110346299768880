import {useContext, useEffect, useState} from "react";
import {MainContext} from "../../context/MainContext";
import {getProfessionalData, ProfessionalProfileData} from "../../service/profileRequest";
import {Translation} from "../../types/Translation";
import {TError} from "../../types/TError";
import {NotificationType} from "../../components/Toast/Toast";
import {getV2} from "../../utils/request";
import {PageState} from "../../types/Context";
import CLoadingAnimation from "../../components/CLoadingAnimation";
import {CProfileCard} from "../../components/Profile/CProfileCard";
import {parsePaymentHistoriesResponse, TPaymentHistory} from "../../types/TPaymentHistory";
import {getCurrencies, getTranslations} from "../../service/commonRequest";
import {ModalButton} from "../../components/Input/ModalButton";
import {requestPayment, TPaymentRequest} from "../../types/TPaymentRequest";
import {UserType} from "../../types/TUserData";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";

var paymentInterval: any = undefined;

export function Payments() {
  const mainContext = useContext(MainContext);
  const [profileData, setProfileData] = useState<ProfessionalProfileData | undefined>(undefined)
  const [translations, setTranslations] = useState<
    Map<string, Translation> | undefined
  >(undefined);
  const [payments, setPayments] = useState<TPaymentHistory[] | undefined>(undefined)
  const [priceMinWidth, setPriceMinWidth] = useState(0)

  const {id} = useParams();

  function fetchProfileData() {
    setProfileData(undefined)
    getProfessionalData().then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'getData')
      } else {
        setProfileData(r)
      }
    })
  }

  function getTranslation(parent: string, child: string): string {
    return translations?.get(parent)?.sentences.get(child) ?? "";
  }

  function getPageTranslation(child: string): string {
    return getTranslation('sayfa', child) ?? "";
  }

  function getDaysBefore(p: TPaymentHistory) {
    if (p.transactionDateObj) {
      const now = new Date()
      const diff = now.getTime() - p.transactionDateObj.getTime()
      const days = Math.floor(diff / (1000 * 60 * 60 * 24))
      return mainContext?.getTranslation('common', 'gun-once').replace('{val}', days.toString())
    } else {
      return ''
    }
  }

  async function fetchPayments() {
    setPayments(undefined)
    await getCurrencies()
    if (mainContext?.userData?.userType == UserType.Company) {
      getV2('/Booking/listuserforown').then(r => {
        if (r instanceof TError) {
          mainContext?.showToast(NotificationType.Fail, 'getPayments')
        }
        setPayments(parsePaymentHistoriesResponse(r.data))
      })
    } else {
      getV2('/Booking/listuser').then(r => {
        if (r instanceof TError) {
          mainContext?.showToast(NotificationType.Fail, 'getPayments')
        }
        setPayments(parsePaymentHistoriesResponse(r.data))
      })
    }
  }

  async function fetchData() {
    getTranslations('odeme-sayfa', (translations, data) => {
      setTranslations(translations);
      fetchProfileData()
      fetchPayments()
    })
  }

  useEffect(() => {
    if (mainContext?.userData?.token) {
      const isExpired = mainContext.isExpired('payments')
      if (isExpired) {
        setTimeout(() => {
          mainContext.setSigninupState(PageState.Signin)
        }, 1500)
        return
      }
      if (mainContext?.publicToken) {
        fetchData()
      }
    }
  }, [mainContext?.userData?.token, mainContext?.publicToken]);

  useEffect(() => {
    if ((payments?.length ?? 0) > 0) {
      const priceComponents = document.getElementsByClassName('price')
      let minPW = 0
      for (var i = 0; i < priceComponents.length; i++) {
        const c = priceComponents[i]
        //@ts-ignore
        const minW: number = c.offsetWidth
        if (minW > minPW) {
          minPW = minW
        }
      }
      setPriceMinWidth(minPW)

      const cPriceComponents = document.getElementsByClassName('companyprice')
      let minCPW = 0
      for (var i = 0; i < cPriceComponents.length; i++) {
        const c = cPriceComponents[i]
        //@ts-ignore
        const minW: number = c.offsetWidth
        if (minW > minCPW) {
          minCPW = minW
        }
      }
      setPriceMinWidth(minCPW)
    }

    if (id != undefined && payments != undefined) {
      let found = payments?.find(p => p.key == id);
      if (found != undefined)
        selectPayment(found)
    }
  }, [payments]);

  const navigate = useNavigate()
  const isAllLoaded = profileData != undefined && payments != undefined && translations != undefined
  const [selectedPayment, setSelectedPayment] = useState<TPaymentHistory | undefined>(undefined)

  const [paymentRequest, setPaymentRequest] = useState<undefined | TPaymentRequest>(undefined)
  const [paymentLoaded, setPaymentLoaded] = useState(false)

  function makePayment() {
    requestPayment(selectedPayment?.transactionRelationKey + '', mainContext).then(r => {
      if (r == false) {
        return
      }
      setPaymentRequest(r)
      localStorage.setItem('payment', JSON.stringify(r));
      const script = document.createElement('script')
      script.id = 'paymentModal'
      script.textContent = r.checkoutFormContent
      script.type = 'text/javascript'
      document.body.appendChild(script)
    })
  }

  useEffect(() => {
    if (paymentRequest != undefined) {
      paymentInterval = setInterval(() => {
        const elements = document.getElementsByClassName(mainContext?.getTranslation('backend', 'iyzicoClass') ?? 'iyzicoClass');
        if (elements.length > 0) {
          setPaymentLoaded(true)
        }
      }, 1000)
    }

    if (paymentLoaded) {
      paymentInterval = setInterval(() => {
        const elements = document.getElementsByClassName(mainContext?.getTranslation('backend', 'iyzicoClass') ?? 'iyzicoClass');
        if (elements.length == 0) {
          // setPaymentLoaded(false)
          // setPaymentRequest(undefined)
          clearInterval(paymentInterval)
          paymentInterval = undefined
          window.location.reload()
          // document.getElementById('paymentModal')?.remove();
        }
      }, 1000)
    }

  }, [paymentLoaded, paymentRequest]);

  function selectPayment(payment: TPaymentHistory) {
    if (mainContext?.userData?.userType == UserType.Company) {
      setSelectedPayment(payment)
      navigate('/payments/' + payment.key, {replace: true})
    }
  }

  function returnPayment() {
    setSelectedPayment(undefined);
    navigate('/payments', {replace: true})
  }

  return <div
    className="main-width main-padding m-auto pt-[96px] pb-[48px] lg:pb-[72px] items-center h-full flex-col gap-[30px]">
    {!isAllLoaded ? <div className={'h-[100vh]'}><CLoadingAnimation/></div> : <>
      <div className={'flex w-full justify-between py-[48px] items-start gap-[40px]'}>
        <CProfileCard hideVisibilityIcon customClass={'!px-0 !w-[240px] flex justify-center'}/>
        <div className={'flex-col gap-[24px] flex-1 items-start py-[18px]'}>
          <div className={'flex gap-[16px] items-center w-full'}>
            <p role={selectedPayment != undefined ? 'button' : 'text'} onClick={returnPayment}
               className={'text-18-40-500 text-purple'}>{getPageTranslation('odemeler')}</p>
            {selectedPayment != undefined &&
                <>
                    <p className={'text-16-24-500 text-purple'}>
                      {'>'}
                    </p>
                    <p className={'text-18-40-500 text-purple'}>
                      {selectedPayment.transactionRelationStr} ({selectedPayment.relationStr})
                    </p>
                    <div className={'flex-1 flex justify-end'}>
                        <ModalButton loading={false} disabled={false} text={getPageTranslation('odeme-yap')}
                                     onClick={makePayment}/>
                    </div>
                </>
            }
          </div>
          {selectedPayment != undefined &&
              <div className={'flex-col gap-[20px] w-full'}>
                {selectedPayment.paymentPlans?.map((plan, i) => {

                  return <div key={'paymentplan' + i}
                              className={'flex gap-[36px] div-lightest-purple rounded-[16px] py-[32px] pl-[16px] pr-[36px] text-black items-center'}>
                    <div className={'flex-1 items-center flex gap-[24px]'}>
                      <div className='flex-col items-center w-[100px]'>
                        <h1 className={'text-15-24-700'}>{plan.installmentNo}</h1>
                        <h2 className={'text-14-24-400 text-grey text-center'}>{getPageTranslation('taksit-no')}</h2>
                      </div>
                    </div>
                    <div className={'flex-col p-[10px] bg-white rounded-[10px] items-end'}>
                      <h1 className={`price whitespace-nowrap text-15-24-700`}
                          style={{minWidth: `0px`}}>{plan.paymentDateStr ?? '-'}</h1>
                      <h2 className={'text-10-24-400 text-grey'}>{getPageTranslation('odeme-tarihi')}</h2>
                    </div>
                    <div className={'flex-col p-[10px] bg-white rounded-[10px] w-[150px] items-end'}>
                      <h1 className={`whitespace-nowrap text-15-24-700`}
                          style={{minWidth: `0px`}}>{(plan.amount != undefined && plan.amount > 0) ? `${plan.amount} ${plan.currency?.code}` : '-'}</h1>
                      <h2 className={'text-10-24-400 text-grey'}>{mainContext?.getTranslation('common', 'tutar')}</h2>
                    </div>

                    <div className={'w-[200px] flex justify-end'}>
                      <div className={'flex-col p-[10px] bg-white rounded-[10px]'}>
                        <h1 className={`price whitespace-nowrap text-15-24-700`}
                            style={{minWidth: `0px`}}>{getPageTranslation(`state-professional-${(plan.isPaid) ? selectedPayment.roomRelationStatus?.toLowerCase() : 'notpaid'}`)}</h1>
                        <h2 className={'text-10-24-400 text-grey'}>{getPageTranslation('odeme')}</h2>
                      </div>
                    </div>
                  </div>
                })}
              </div>
          }
          <div className={`${selectedPayment == undefined ? 'flex-col' : 'hidden'} gap-[20px] w-full`}>
            {(payments == null || payments.length == 0) && <div className={'flex justify-center items-center p-[24px]'}>
                <div className={'text-grey text-24-24-500'}>
                  {getPageTranslation('odemen-yok')}
                </div>
            </div>}
            {payments?.map((history, i) => {
              return <div
                className={`${mainContext?.userData?.userType == UserType.Professional ? 'div-lightest-purple' : 'paymentBox'} flex gap-[36px] rounded-[16px] py-[32px] pl-[16px] pr-[36px] text-black items-center`}
                key={'paymenthistory' + i} onClick={() => {
                selectPayment(history)
              }}>
                <div className={'flex-col flex-1'}>
                  <h1 className={'text-15-24-700'}>{history.transactionRelationStr}</h1>
                  <h2 className={'text-14-24-400 text-grey'}>{`${history.relationStr}`}</h2>
                  <h2 className={'text-14-24-400 text-grey'}>{`${getDaysBefore(history)}`}</h2>
                </div>
                <div className={'flex-col p-[10px] bg-white rounded-[10px]'}>
                  <h1 className={`companyprice whitespace-nowrap text-15-24-700`}
                      style={{minWidth: `0px`}}>{(history.price != undefined && history.price > 0) ? `${history.price} ${history.currencyCode}` : '-'}</h1>
                  <h2 className={'text-10-24-400 text-grey'}>{getPageTranslation('anlasilan-tutar')}</h2>
                </div>
                <div className={'flex-col p-[10px] bg-white rounded-[10px]'}>
                  <h1 className={`price whitespace-nowrap text-15-24-700`}
                      style={{minWidth: `0px`}}>{(history.totalPayment != undefined && history.totalPayment > 0) ? `${history.totalPayment} ${history.currencyCode}` : '0'}</h1>
                  <h2 className={'text-10-24-400 text-grey'}>{getPageTranslation('odenen-tutar')}</h2>
                </div>
                <div className={'flex-col p-[10px] bg-white rounded-[10px]'}>
                  <h1 className={`price whitespace-nowrap text-15-24-700`}
                      style={{minWidth: `0px`}}>{getPageTranslation(`state-professional-${history.roomRelationStatus?.toLowerCase()}`)}</h1>
                  <h2 className={'text-10-24-400 text-grey'}>{getPageTranslation('odeme')}</h2>
                </div>
              </div>
            })}
          </div>
        </div>
      </div>
    </>
    }
  </div>
}
