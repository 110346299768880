import {useContext, useEffect, useState} from "react";
import {MainContext} from "../context/MainContext";
import {FilterModal} from "./Filter/FilterModal";
import {CCheckbox} from "./CCheckbox";
import styles from './Profile/Profile.module.scss'
import {postV2} from "../utils/request";
import {TJob} from "../types/TJob";
import {NotificationType} from "./Toast/Toast";

interface IProps {
  getTranslation: (key: string) => string
  isOpen: boolean
  close: () => void
  update: () => void
  projects: TJob[]
}

export function CCompletedJobsList({getTranslation, isOpen, close, update, projects}: IProps) {
  const mainContext = useContext(MainContext)

  const [requesting, setRequesting] = useState(false)
  const [tempProjects, setTempProjects] = useState<TJob[]>([])

  useEffect(() => {
    if (isOpen === true) {
      setTempProjects([...projects])
    }
  }, [isOpen]);

  async function updateProjectVisibility(p: TJob) {
    return await postV2('/ProjectOffer/visible', {projectOfferKeyID: p.id, visible: p.visible}).then((resp) => {
      if (resp.data.result == true) {
        mainContext?.showToast(NotificationType.Success, p.visible ? 'makeVisible' : 'makeInvisible')
        return true
      }
      mainContext?.showToast(NotificationType.Fail, p.visible ? 'makeVisible' : 'makeInvisible')
      return false
    });
  }

  async function save() {
    const newProjects = [...tempProjects]

    let didFail = false
    for (let i = 0; i < tempProjects.length; i++) {
      let find = projects.find(p => p.id == tempProjects[i].id);
      if (find && find.visible != tempProjects[i].visible) {
        const resp = await updateProjectVisibility(tempProjects[i])
        if (resp == false) {
          didFail = true
          newProjects[i].visible = find.visible;
        }
      }
    }
    if (didFail == true) {
      mainContext?.showToast(NotificationType.Fail, 'updateProject')
    } else {
      mainContext?.showToast(NotificationType.Success, 'updateProject')
      update()
      close()
    }
    setTempProjects(newProjects)
  }

  function Delete(e: any) {
    e.preventDefault();
    e.stopPropagation();
    console.log('delete')
  }

  function changeVisibility(i: number) {
    let newProjects = [...tempProjects].map((t, ii) => {
      if (ii == i) {
        t.visible = !t.visible
      }
      return t
    });
    setTempProjects(newProjects)
  }

  const currentYear = new Date().getFullYear() + "";
  return <FilterModal
    title={getTranslation('my-completed-jobs')}
    header={''}
    isOpen={isOpen}
    onClose={close}
    onSave={save}
    customHeaderClass={'!pb-0'}
  >
    <div className={'w-full'}>
      <div className="flex-col gap-[36px] text-grey text-14-24-500 max-h-[60vh]">
        {getTranslation('gorunmesi-istenen-projeler')}
        <div className={'flex-col gap-[24px] overflow-y-auto'}>
          {tempProjects.map((p, i) => {
            return <div onClick={() => changeVisibility(i)}
                        className={`rounded-[16px] py-[26px] px-[12px] flex ${p.visible ? 'bg-[#DEDEFF]' : 'bg-[#F6F6FF]'}`}>
              <CCheckbox
                customClass={'!self-center !pb-0'}
                selected={p.visible}
                onChange={() => {
                }}
                disabled={requesting}
                textHtml={<></>}/>
              <div className={'flex-col gap-[12px] w-full overflow-x-hidden'}>
                <div className={'flex justify-between'}>
                  {p.location ? <div className={'flex gap-[8px]'}>
                    <img src={'/images/Location.svg'}/>
                    <p>{p.location}</p>
                  </div> : <></>}
                  {/*<div className={'w-[24px] h-[24px] mr-[18px]'} onClick={Delete} role={'button'}>*/}
                  {/*  <img src={'/images/trashPurple.svg'}/>*/}
                  {/*</div>*/}
                </div>

                <div className={`${styles.content}`}>
                  <div className={`flex-col`}>
                    <h1>{p.title}</h1>
                    <h2>{p.firm}&nbsp;
                      <div className={'purpleDot'}/>
                      &nbsp;{p.workType}</h2>
                    <h3>{p.startDate}&nbsp;
                      <div className={'purpleDot'}/>
                      &nbsp;{(p.endDate == undefined || p.endDate == currentYear) ? mainContext?.getTranslation('common', 'halen') : p.endDate}
                      &nbsp;
                      {/*<div className={'purpleDot'}/>*/}
                      {/*&nbsp;{getDiffFromToday(new Date(props.startDate))}&nbsp;{mainContext?.getTranslation('common', 'gundur')}*/}
                    </h3>
                  </div>
                  <div className={'flex-col gap-4 pt-[12px]'}>
                    <h3>{mainContext?.getTranslation('common', 'aciklama')}</h3>
                    <h2>{p.description}</h2>
                  </div>
                </div>
                {p.medias &&
                    <div className={'w-full'}>
                        <div className={'flex gap-[16px] overflow-x-scroll'}>
                          {p.medias!.map((m, i) =>
                            <img role={'button'}
                                 className={'rounded-[16px] max-w-[170px]'} src={m}
                                 onClick={() => {
                                   // setSelected(i)
                                 }}/>
                          )}
                        </div>
                    </div>
                }

              </div>
            </div>
          })}
        </div>

      </div>
    </div>
  </FilterModal>
}
