import { Button } from "@mui/material";
import CHeroCompany from "../components/CHeroCompany";
import { useContext, useEffect, useState } from "react";
import CLoadingAnimation from "src/components/CLoadingAnimation";
import { DataProps } from "src/types/DataProps";
import {filter, getV2} from "src/utils/request";
import { Translation, parseTranslation } from "src/types/Translation";
import { MainContext } from "src/context/MainContext";
import { PageState } from "src/types/Context";
import {getTranslations} from "../service/commonRequest";

export default function Expert() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, []);

  const mainContext = useContext(MainContext);

  const [data, setData] = useState<DataProps[] | undefined>(undefined);
  const [translations, setTranslations] = useState<
    Map<string, Translation> | undefined
  >(undefined);

  function getTranslation(child: string): string {
    return translations?.get("sayfa")?.sentences.get(child) ?? "";
  }

  useEffect(() => {
    if (mainContext?.publicToken) {
      getTranslations('sirket-sayfa',(translations,data)=>{
        setData(data);
        setTranslations(translations);
      })
    }
  }, [mainContext?.publicToken]);

  useEffect(() => {
    if (data != undefined) {
      setTimeout(() => {
        const refs = window.location.href.split("#");
        if (refs !== null && refs.length > 1) {
          const targetto = refs[1];
          const target = document.getElementById(targetto);
          const scrollToY = target!.offsetTop;
          console.log(`Y: ${targetto} px: ${scrollToY}`);
          window.scrollTo(0, scrollToY-100);
        } else {
          window.scrollTo(0, 0);
        }
      }, 250);
    }
  }, [data]);

  return data == undefined ? (
    <div className="h-[100vh] w-[100vw] flex-col justify-center">
      <CLoadingAnimation />
    </div>
  ) : (
    (() => {
      const pictures = filter(data!, "sayfa");
      return (
        <>
          <CHeroCompany getTranslation={getTranslation} data={pictures} />
          <a id='katil'></a>
          <div className="flex justify-between main-width m-auto main-padding lg:pt-[136px] relative items-center h-full">
            <div className="bg-white main-dropshadow rounded-[16px] py-[24px] lg:py-[56px] w-full flex-col items-center px-[16px] text-center lg:text-start">
              <div className="text-32-40-500">
                {getTranslation("footer-header")}
              </div>
              <div className="pt-[16px] text-16-24-400 text-grey">
                {getTranslation("footer-title")}
              </div>
              <div className="pt-[24px]">
                <Button
                  className="purpleButton"
                  onClick={() => {
                    mainContext?.setSigninupState(PageState.Signup);
                  }}
                >
                  <span>{getTranslation("footer-button")}</span>
                </Button>
              </div>
              <div className="text-16-24-400 pt-[16px]">
                <span>
                  {mainContext?.getTranslation(
                    "common",
                    "veya-hesabin-varsa-gir-oncesi"
                  )}
                </span>
                <span
                  className="text-16-24-600 text-purple"
                  role="button"
                  onClick={() => {
                    mainContext?.setSigninupState(PageState.Signin);
                  }}
                >
                  {mainContext?.getTranslation("common", "giris-yap")}
                </span>
                <span>
                  {mainContext?.getTranslation(
                    "common",
                    "veya-hesabin-varsa-gir-sonrasi"
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="pt-[48px] lg:pt-[168px]"></div>
        </>
      );
    })()
  );
}
