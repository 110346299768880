import {useContext, useEffect, useState} from "react";
import {MainContext} from "../context/MainContext";
import {FilterModal} from "./Filter/FilterModal";
import styles from './Profile/Profile.module.scss'
import {getV2} from "../utils/request";
import {TPortfolio} from "../types/TPortfolio";
import {TError} from "../types/TError";
import {NotificationType} from "./Toast/Toast";

interface IProps {
  getTranslation: (key: string) => string
  isOpen: boolean
  close: () => void
  onUpdate: () => void
  portfolios: TPortfolio[]
  edit: (p: TPortfolio) => void
}

export function CPortfolioList({getTranslation, isOpen, close, onUpdate, portfolios, edit}: IProps) {
  const mainContext = useContext(MainContext)

  const [requesting, setRequesting] = useState(false)
  const [tempPortfolios, setTempPortfolios] = useState<TPortfolio[]>([])

  useEffect(() => {
    if (isOpen === true) {
      setTempPortfolios([...portfolios])
    }
  }, [isOpen]);

  async function deletePortfolio(p: TPortfolio) {
    return await getV2('/ProfessionalPortfolio/delete?key=' + p.key).then((resp) => {
      if (resp.data.result == true) {
        mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
        return true
      }
      if (resp instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
      }
      return false
    });
  }

  async function save() {
    setRequesting(true)
    const newProjects = [...tempPortfolios]

    let didFail = false
    let isFound = false
    for (let i = 0; i < tempPortfolios.length; i++) {
      let find = portfolios.find(p => p.id == tempPortfolios[i].id);
      if (find && newProjects[i].deleted === true) {
        isFound = true
        const resp = await deletePortfolio(tempPortfolios[i])
        if (resp == false) {
          didFail = true
          newProjects[i].deleted = find.deleted;
        }
      }
    }
    setRequesting(false)
    if (isFound) {
      onUpdate()
    }
    if (didFail == true) {
      mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
    } else if (isFound) {
      mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
      close();
    }
    setTempPortfolios(newProjects)
  }

  function Delete(i: number) {
    let newPortfolios = [...tempPortfolios].map((t, ii) => {
      if (ii == i) {
        t.deleted = t.deleted != undefined ? !t.deleted : true
      }
      return t
    });
    setTempPortfolios(newPortfolios)
    console.log('delete')
  }

  const currentYear = new Date().getFullYear() + "";
  return <FilterModal
    title={getTranslation('portfolyo')}
    header={''}
    isOpen={isOpen}
    onClose={close}
    onSave={save}
    saveDisabled={requesting}
    closeDisabled={requesting}
    customHeaderClass={'!pb-0'}
  >
    <div className={'w-full'}>
      <div className="flex-col gap-[36px] text-grey text-14-24-500 max-h-[60vh]">
        {getTranslation('gorunmesi-istenen-projeler')}
        <div className={'flex-col gap-[24px] overflow-y-auto'}>
          {tempPortfolios.map((p, i) => {
            return <div
              className={`rounded-[16px] py-[26px] px-[12px] flex ${p.deleted ? 'bg-[#FFAAAA]' : 'bg-[#F6F6FF]'}`}>
              <div className={'flex-col gap-[12px] pl-[18px] w-full overflow-x-hidden'}>
                <div className={'flex justify-between'}>
                  <h1>{p.name}</h1>
                  <div className={'flex gap-[16px] mr-[18px]'}>
                    <div role={'button'} className={'pt-[1px]'}
                         onClick={(e) => edit(portfolios[i])}>
                      <img className={'w-[22px] h-[22px]'} src={'/images/editButton.svg'}/>
                    </div>
                    <div className={'w-[24px] h-[24px] '} onClick={(e) => Delete(i)}
                         role={'button'}>
                      <img src={tempPortfolios[i].deleted ? '/images/trashBlack.svg' : '/images/trashPurple.svg'}/>
                    </div>
                  </div>
                </div>
                <div className={`${styles.content}`}>
                  <div className={'flex-col gap-4 pt-0'}>
                    <h3>{mainContext?.getTranslation('common', 'aciklama')}</h3>
                    <h2>{p.remark}</h2>
                  </div>
                </div>
                {p.medias &&
                    <div className={'w-full pr-[18px]'}>
                        <div className={'flex gap-[16px] overflow-x-auto scrollMediaList'}>
                          {p.medias!.map((m, i) =>
                            <img role={'button'}
                                 className={'rounded-[16px] max-w-[170px]'} src={m.url}
                                 onClick={() => {
                                   // setSelected(i)
                                 }}/>
                          )}
                        </div>
                    </div>
                }
              </div>
            </div>
          })}
        </div>

      </div>
    </div>
  </FilterModal>
}
