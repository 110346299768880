import {TMediaObject} from "./TMediaObject";
import {dateStrToString} from "../utils/date";
import {parsePhone} from "../utils/util";
import {TJob} from "./TJob";
import {parseProjectDetail, TProject} from "./TProject";

export interface SocialMedia {
  socialMedia: string
  socialMediaID: number
  link?: string
}

export interface CompanyContactPerson {
  name: string,
  surname: string,
  email: string,
  phone: string,
  isDefined: boolean
}

export interface SocialMedias {
  instagram?: SocialMedia,
  facebook?: SocialMedia,
  linkedin?: SocialMedia,
  twitter?: SocialMedia,
  behance?: SocialMedia,
  dribble?: SocialMedia,
}

export interface CompanyProfileData {
  name: string,
  address: string,
  phone: string,
  countryID: number,
  cityID: number,
  about: string,
  bannerText: string,
  date: Date,
  smsPermission: boolean,
  callPermission: boolean,
  emailPermission: boolean,
  contactPerson: CompanyContactPerson
  socialMedias: SocialMedias,

  profilePhoto?: TMediaObject,
  pagePhoto?: TMediaObject,
  allProfilePhotos?: TMediaObject[]
}


export function deepCopyCompany(data: CompanyProfileData): CompanyProfileData {
  return JSON.parse(JSON.stringify(data))
}

// export interface TCompanyJob {
//   name: string
//   createDateObj?: Date
//   createDate: string
//   incomingOfferCount: number
//   contactPerson: CompanyContactPerson
//   isActive: boolean
// }

export interface TCompanyJobList {
  activeJobs: TProject[]
  inactiveJobs: TProject[]
}

export function parseContactPerson(c: any): CompanyContactPerson {
  let val = {
    name: c.contactPersonName??'',
    surname: c.contactPersonSurname??'',
    email: c.contactPersonEmail??'',
    phone: c.contactPersonPhone?parsePhone(c.contactPersonPhone):'',
    isDefined:false
  };
  if(val.name.length>0 || val.surname.length>0){
    val.isDefined = true;
  }
  return val
}

export function parseCompanyJobListResponse(responseData: any): TCompanyJobList {

  const jobList: TCompanyJobList = {
    activeJobs: [],
    inactiveJobs: []
  }

  responseData.forEach((item: any) => {

    const job=parseProjectDetail(item)

    if (job.isActive) {
      jobList.activeJobs.push(job)
    } else {
      jobList.inactiveJobs.push(job)
    }

  });

  return jobList;
}
