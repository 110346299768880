import {yyyymmToString} from "../utils/date";

export interface TExperience {
  id: number;
  key:string;
  professionalId: number,
  companyName: string;
  remark: string;
  professionID: number;
  professionName: string;
  year: number;
  endYear: number;
  month: number;
  endMonth: number;
  humanReadableBeginDate?: string;
  humanReadableEndDate?: string;
  transactionDate: string;
  visible: boolean;
  deleted?: boolean;
}

export function parseToExperience(c: any): TExperience {
  return {
    id: c.id,
    key:c.key,
    professionalId: c.professionalId,
    companyName: c.companyName,
    remark: c.remark,
    year: c.year,
    endYear: c.endYear,
    month: c.month,
    endMonth: c.endMonth,
    professionID: c.professionID,
    professionName: c.professionName,
    transactionDate: c.transactionDate,
    visible: c.visible??false,
    humanReadableBeginDate: yyyymmToString(c.year, c.month),
    humanReadableEndDate: yyyymmToString(c.endYear, c.endMonth),
  }
}
