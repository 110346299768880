import styles from './CompanyJobAgreement.module.scss'
import {useContext, useEffect, useState} from "react";
import {parseTranslation, Translation} from "../types/Translation";
import {getV2} from "../utils/request";
import {Button} from "@mui/material";
import {MainContext} from "../context/MainContext";
import {getTranslations} from "../service/commonRequest";

export function CompanyJobAgreement() {
  const mainContext = useContext(MainContext)

  const jobName = "Deneme"
  const firmName = 'CoinClip'
  const expert = 'xxx'
  const ucret = '30.000 TL'
  const sure = '2 Ay'
  const odemeSekli = 'Proje Bitiminde Ödeme'
  const uzmanlik = 'UI/UX Design'

  const [translations, setTranslations] = useState<
    Map<string, Translation> | undefined
  >(undefined);
  useEffect(() => {
    if (mainContext?.userData?.token) {
      if (mainContext?.publicToken) {
        getTranslations('anlasma-sayfa', (translations, data) => {
          setTranslations(translations);
        })
      }
    }
  }, [mainContext?.userData?.token, mainContext?.publicToken]);

  function getTranslation(parent: string, child: string): string {
    return translations?.get(parent)?.sentences.get(child) ?? "";
  }

  function detailCard(upperText: string, bottomText: string) {
    return <div className={'rounded-[16px] bg-white px-[24px] py-[16px] flex flex-col gap-[12px]'}>
      <div className={styles.detailUpText}>{upperText}</div>
      <div className={styles.detailBottomText}>{bottomText}</div>
    </div>
  }

  return <div
    className="main-width-desktop m-auto main-padding pt-[150px] pb-[48px] lg:pb-[72px] items-center h-full flex justify-center">
    <div className={'w-full flex flex-col gap-[31px] max-w-[1040px]'}>
      <div className={'flex gap-[22px]'}>
        <div className={'whiteShadowCard flex gap-[20px] px-[28px] py-[48px] min-w-[331px] items-center'}>
          <div className={'relative'}>
            <div
              className={'absolute bottom-0 right-[6px] rounded-full border-[1px] border-white bg-green-500 h-[16px] w-[16px]'}></div>
            <img className={'rounded-full bg-gray-300'} src={mainContext?.userData?.picture}/>
          </div>
          <div className={'flex flex-col gap-[8px]'}>
            <div className={styles.firmName}>{firmName}</div>
            <div className={styles.location}>
              <img src={'/images/locationSmall.svg'}/>
              {'Istanbul, Türkiye'}
            </div>
          </div>
        </div>
        {/*<div className={'whiteShadowCard flex gap-[29px] px-[27px] py-[34px] min-w-[687px] items-center'}>*/}
        <div className={'whiteShadowCard flex gap-[29px] px-[27px] py-[34px] items-center'}>
          <img src={'/images/jobAgreementSuccess.svg'}/>
          <div className={'successTexts'}>
            <div>
              {getTranslation('sayfa', 'harika')}
            </div>
            <div>
              {getTranslation('sayfa', 'uzmanla-anlastin').replace('{val}', jobName)}
            </div>
          </div>
        </div>
      </div>
      <div className={'whiteShadowCard px-[24px] pt-[36px] pb-[90px] flex flex-col gap-[14px]'}>
        <div className={styles.jobTitle}>
          {jobName}
        </div>
        <div className={styles.jobDescription}>
          {getTranslation('sayfa', 'uzmanla-anlastin-text').replace('{val}', expert)}
        </div>
        <div className={styles.jobDescription}>
          {getTranslation('sayfa', 'peerbie-kullanim-once')}
          <span role={'button'} className={styles.colored}>{getTranslation('sayfa', 'peerbie-kullanim-tikla')}</span>
          {getTranslation('sayfa', 'peerbie-kullanim-sonra')}
        </div>
        <div
          className={'mt-[28px] rounded-[16px] div-light-purple px-[22px] pt-[20px] pb-[30px] flex flex-col gap-[25px]'}>
          <div className={styles.detailUpText}>
            {getTranslation('sayfa', 'uzmanla-yapilan-anlasma')}
          </div>
          <div className={'grid grid-cols-4 gap-[18px]'}>
            {detailCard(getTranslation('sayfa', 'anlasilan-ucret'), ucret)}
            {detailCard(getTranslation('sayfa', 'proje-suresi'), sure)}
            {detailCard(getTranslation('sayfa', 'ucret-sekli'), odemeSekli)}
            {detailCard(getTranslation('sayfa', 'uzmanlik'), uzmanlik)}
          </div>
        </div>
        <div className={'mt-[46px] w-full flex justify-end'}>
          <Button role={'button'} className="purpleButton" onClick={() => {
          }}>
            <span>{getTranslation('sayfa', 'basla')}</span>
          </Button>
        </div>
      </div>
    </div>

  </div>
}
