import {useContext, useEffect, useState} from "react";
import {MainContext} from "../../context/MainContext";
import {FilterConfig, FilterPageProps, JobFilterType} from "../../types/TFilter";
import {CSelectWithSearchForFilter} from "../Input/CSelectWithSearchForFilter";
import {getV2, postV2} from "../../utils/request";
import {TError} from "../../types/TError";
import {FilterModal} from "../Filter/FilterModal";
import {EnumProfileUpdateType} from "../../types/EnumProfileUpdateType";
import {NotificationType} from "../Toast/Toast";

const jobFilterType = JobFilterType.Profession;

interface IProps {
  selectedValues?: string[]
  isOpen: boolean
  options: FilterConfig[]
  close: () => void
  updated: (newValues?: number[]) => void
}

export function CProfileProfessionsModal({isOpen, close, updated, selectedValues, options}: IProps) {
  const mainContext = useContext(MainContext);

  const [tempVal, setTempVal] = useState<FilterConfig[] | undefined>(undefined)
  const [isUpdating, setIsUpdating] = useState(false)

  function handleSave() {
    let professions = tempVal == undefined ? undefined : tempVal.map(t => "" + t.id);
    setIsUpdating(true)
    postV2('/Professional/partialupdate', {
      updateType: EnumProfileUpdateType.Profession,
      professions: professions,
    }).then(r => {
      if (r.data.result == true) {
        mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
        close();
        updated(tempVal?.map(v => v.id) ?? undefined);
      } else {
        mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
        return
      }
    }).catch((e) => {
      mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
    }).finally(() => {
      setIsUpdating(false)
    })
  }

  function onCancel() {
    close();
  }

  useEffect(() => {
    if (selectedValues) {
      const found = options.filter(o => {
        return selectedValues.find(v => (o.id + "") == v) != undefined
      })
      setTempVal(found)
    } else {
      setTempVal(undefined)
    }
  }, [options]);

  useEffect(() => {
    if (isOpen) {
      if (selectedValues) {
        const found = options.filter(o => {
          return selectedValues.find(v => (o.id + "") == v) != undefined
        })
        setTempVal(found)
      } else {
        setTempVal(undefined)
      }
    }
  }, [isOpen]);

  return <FilterModal
    requesting={isUpdating}
    title={mainContext!.getTranslation('filters', jobFilterType)}
    header={mainContext!.getTranslation('filters', 'uzmanliga-gore-is')}
    desc={mainContext!.getTranslation('filters', 'birden-fazla-secim')}
    saveDisabled={isUpdating}
    isOpen={isOpen} onClose={onCancel} onSave={handleSave}>
    <CSelectWithSearchForFilter options={options} val={tempVal} isMultiple={true} loading={options == undefined}
      //@ts-ignore
                                onChange={setTempVal}
    />
  </FilterModal>
}
