import {isDesktop} from "src/utils/window";
import {TMediaTextCardsRow} from "../types/TComponents";
import CMediaTextCard from "./CMediaTextCard";
import CSwiper1Partner from "./CSwiper1Partner";

interface PHelpToWorkRow {
  data: TMediaTextCardsRow;
}

function CHelpToWorkRow({data}: PHelpToWorkRow) {
  return (
    <div className={`flex justify-between gap-[40px] pt-[48px] ${data.parentClass}`}>
      {isDesktop() ? (
        data.data.map((card, index) => {
          return (
            <CMediaTextCard
              key={card.header + index}
              data={card}
              topComponentClass="h-[152px]"
              textClass="pt-[16px] pb-[24px] px-[16px] gap-[8px] text-start"
            />
          );
        })
      ) : (
        <CSwiper1Partner
          data={{
            cards: data.data.map((card, i) => {
              return (
                <CMediaTextCard
                  key={card.header + i}
                  data={card}
                  topComponentClass="h-[152px]"
                  textClass="pt-[16px] pb-[16px] gap-[8px] px-[16px] text-start h-[80px]"
                />
              );
            }),
          }}
        />
      )}
    </div>
  );
}

export default CHelpToWorkRow;
