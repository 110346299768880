interface PEllipseTextBox {
  text: string;
}

function CEllipseTextBox({text}: PEllipseTextBox) {
  return (
    <div className="m-auto px-[32px] py-[16px] rounded-[24px] div-lightest-purple text-purple text-20-16-700">
      {text}
    </div>
  );
}


export default CEllipseTextBox;