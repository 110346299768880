import {useContext, useEffect, useState} from "react";
import {MainContext} from "../../context/MainContext";
import {postV2} from "../../utils/request";
import {Box, IconButton, InputAdornment, Modal, TextField} from "@mui/material";
import {VisibilityOffOutlined, VisibilityOutlined} from "@mui/icons-material";
import {passRegex} from "../../types/Regex";
import CLoadingAnimation from "../CLoadingAnimation";
import {CompanyProfileData} from "../../types/TCompanyData";
import {TError} from "../../types/TError";
import {NotificationType} from "../Toast/Toast";

interface IProps {
  getTranslation: (child: string) => string
  isOpen: boolean
  close: () => void
  updated: (callback?: any) => void
  data: CompanyProfileData
}

export function CCompanyPassUpdateModal({getTranslation, isOpen, close, updated, data}: IProps) {
  const mainContext = useContext(MainContext)

  const [isUpdatingPass, setIsUpdatingPass] = useState(false)
  const [tempData, setTempData] = useState(data)

  useEffect(() => {
    if (isOpen) {
      setTempData(data)
    }
  }, [data, isOpen]);

  function updatePass() {
    if (mainContext?.isExpired('companypass')) {
      return
    }
    setIsUpdatingPass(true);
    postV2('/Login/changePassword', {
      'oldPassword': pass,
      'password': pass1,
      'passwordAgain': pass2,
    }).then(resp => {
      setIsUpdatingPass(false);
      if (resp instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
      } else {
        setOldpassError(undefined);
        setPass1Error(undefined);
        setPass2Error(undefined);
        setPass('')
        setPass1('')
        setPass2('')
        mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
      }
    })
  }


  const [pass, setPass] = useState("");
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [oldpassError, setOldpassError] = useState<string | undefined>(undefined);
  const [pass1Error, setPass1Error] = useState<string | undefined>(undefined);
  const [pass2Error, setPass2Error] = useState<string | undefined>(undefined);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  useEffect(() => {
    var noErrorFound = true;

    if (pass1.length == 0) {
      setPass1Error(undefined);
    } else {
      if (pass1.length < 8) {
        setPass1Error(
          mainContext!.getTranslation("giris-kayit", "enaz-8karakter")
        );
        noErrorFound = false;
      } else {
        if (passRegex.test(pass1)) {
          setPass1Error(undefined);
        } else {
          setPass1Error(
            mainContext!.getTranslation("giris-kayit", "sifre-ozel-karakter")
          );
          noErrorFound = false;
        }
      }
    }

    if (pass2.length == 0) {
      setPass2Error(undefined);
    } else {
      if (pass2.length < 8) {
        setPass2Error(
          mainContext!.getTranslation("giris-kayit", "enaz-8karakter")
        );
        noErrorFound = false;
      } else {
        if (passRegex.test(pass2)) {
          setPass2Error(undefined);
        } else {
          setPass2Error(
            mainContext!.getTranslation("giris-kayit", "sifre-ozel-karakter")
          );
          noErrorFound = false;
        }
      }
    }

    if (noErrorFound && pass2 != pass1) {
      setPass2Error("Şifreleriniz uyuşmamaktadır");
    }
  }, [pass1, pass2]);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '16px',
    minWidth: '700px'
  };

  const updatePassDisabled = pass.length < 7 || pass1.length < 7 || pass2.length < 7 || pass1Error != undefined || pass2Error != undefined || oldpassError != undefined;

  const newpass1Error = (pass1.length > 0 && pass2.length > 0 && pass1 != pass2) ? mainContext?.getTranslation('common', 'yeni-sifre-uyusmuyor') :
    (pass1.length == 0 && pass2.length > 0) ? mainContext?.getTranslation('common', 'alan-bos-birakilamaz') : pass1Error
  const newpass2Error = (pass1.length > 0 && pass2.length > 0 && pass1 != pass2) ? mainContext?.getTranslation('common', 'yeni-sifre-uyusmuyor') :
    (pass2.length == 0 && pass1.length > 0) ? mainContext?.getTranslation('common', 'alan-bos-birakilamaz') : pass2Error


  return <Modal open={isOpen} onClose={close} disableAutoFocus={true}>
    <Box sx={style}>
      <div className={'filterModal max-h-[85vh]'}>
        <div className={'title'}>
          <span>{mainContext?.getTranslation('profile', 'sifremi-degistir')}</span>
          <img onClick={close} src={'/images/closeModal.svg'}/>
        </div>
        <div className={'flex-col y-scroll'}>
          <div className={'flex-col pt-[28px] gap-[21px]'}>
            <p className={'text-14-24-700 text-grey'}>{mainContext?.getTranslation('profile', 'eski-sifre')}</p>
            <TextField
              id="outlined-basic"
              label={mainContext?.getTranslation("profile", "eski-sifre-giriniz")}
              variant="outlined"
              className="basicInput !w-full"
              value={pass}
              onChange={(e) => {
                setPass(e.target.value);
              }}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffOutlined/>
                      ) : (
                        <VisibilityOutlined/>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={'flex-col pt-[28px] gap-[21px]'}>
            <p className={'text-14-24-700 text-grey'}>{mainContext?.getTranslation('profile', 'yeni-sifre')}</p>
            <TextField
              id="outlined-basic"
              label={mainContext?.getTranslation("profile", "yeni-sifre-giriniz")}
              variant="outlined"
              className="basicInput !w-full"
              value={pass1}
              onChange={(e) => {
                setPass1(e.target.value);
              }}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffOutlined/>
                      ) : (
                        <VisibilityOutlined/>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {newpass1Error && <div className="text-red-400 text-sm">{newpass1Error}</div>}
          </div>
          <div className={'flex-col pt-[28px] gap-[21px]'}>
            <p className={'text-14-24-700 text-grey'}>{mainContext?.getTranslation('profile', 'yeni-sifre-tekrar')}</p>
            <TextField
              id="outlined-basic"
              label={mainContext?.getTranslation("profile", "yeni-sifre-giriniz")}
              variant="outlined"
              className="basicInput !w-full"
              value={pass2}
              onChange={(e) => {
                setPass2(e.target.value);
              }}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffOutlined/>
                      ) : (
                        <VisibilityOutlined/>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {newpass2Error && <div className="text-red-400 text-sm">{newpass2Error}</div>}
          </div>
          <div className={'w-[96px] self-end mt-[50px]'}>
            {/*{passError && <div className="text-red-400 text-sm py-[16px]">{passError}</div>}*/}
            {isUpdatingPass === true ?
              <CLoadingAnimation size={40}/>
              :
              <div role={'button'}
                   className={'button save' + ((updatePassDisabled != undefined && updatePassDisabled == true) ? ' disabledBtn' : '')}
                   style={isUpdatingPass ? {backgroundColor: '#ccc'} : {}}
                   onClick={updatePass}>{mainContext!.getTranslation('common', 'kaydet')}</div>
            }
          </div>
        </div>
      </div>
    </Box>
  </Modal>
}
