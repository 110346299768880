import { useContext, useEffect } from "react";
import { isDesktop } from "../utils/window";
import CHeroHeaderText from "./CHeroHeaderText";
import CVerticalImageCard from "./CVerticalImageCard";
import { MainContext } from "src/context/MainContext";
import { PageState } from "src/types/Context";

interface HeroProps {
  data: any;
  getTranslation: (val: string) => string;
}

function CHeroHome({ data, getTranslation }: HeroProps) {
  const mainContext = useContext(MainContext); 
  
  useEffect(() => {
    if (!isDesktop()) {
      const div = document.getElementById("heroDiv");
      const image = document.getElementById("heroImage");
      if (div && image) {
        console.log(div.offsetHeight + "px");
        image.style.height = div.offsetHeight + "px";
      }
    }
  }, []);
  
  const components = {
    title: getTranslation("firsatlarla-dolu") ?? "",
    header: getTranslation("bulusma-yeri")?.replace("\\n", "<br/>") ?? "",
    sub: getTranslation("teknoloji-burada") ?? "",
    buttonText: mainContext?.getTranslation("common", "hemen-basla") ?? "",
    buttonFunction: ()=>{
      mainContext?.setSigninupState(PageState.Signup);
    },
    card1: {
      img: data?.pagePictureModelList?.[0].getUrl(),
      header: getTranslation("card1-job"),
      sub1: getTranslation("card1-name"),
      sub2: getTranslation("card1-location"),
      starText: undefined,
    },
    card2: {
      img: data?.pagePictureModelList?.[1].getUrl(),
      header: getTranslation("card2-job"),
      sub1: getTranslation("card2-name"),
      sub2: getTranslation("card2-location"),
      starText: getTranslation("card2-extra"),
    },
  };

  return (
    <div className="pb-[48px] lg:pb-[72px]">
      <div className="homeHero">
        {isDesktop() && (
          <div className="homeHeroImage">
            <img src="/images/home_hero_background.svg" />
          </div>
        )}
        {!isDesktop() && (
          <img
            id="heroImage"
            className="absolute w-full"
            src="/images/home_hero_mobile_background.svg"
          />
        )}
        <div
          id="heroDiv"
          className="flex-col lg:flex-row justify-between main-width m-auto main-padding pt-[132px] lg:pt-[152px] items-center relative"
        >
          <CHeroHeaderText data={components} />
          <div className="homeHeroCardHolder">
            <div className="absolute w-[192px] lg:w-[302px] left-0 top-0 z-10">
              <CVerticalImageCard data={components.card1} />
            </div>
            <div className="absolute w-[192px] lg:w-[302px] right-0 bottom-0 z-20">
              <CVerticalImageCard data={components.card2} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CHeroHome;
