import { Box, Button, Modal } from "@mui/material";
import Lottie from "react-lottie";
import * as animationData from "../animation/lottie-success.json";
import { useContext } from "react";
import { MainContext } from "src/context/MainContext";

interface IProps {
  open: boolean;
  text: string;
  onClose: () => void;
}

export function CSuccess({ open, text, onClose }: IProps) {
  const mainContext = useContext(MainContext);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    border: "none",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Lottie options={defaultOptions} isStopped={false} isPaused={false} />
        <div className="pt-[20px] text-grey text-18-24-600 text-center">
          {text}
        </div>
        <div className="w-full flex pt-[24px] justify-center">
          <Button
            className="purpleButton !py-[10px]"
            onClick={() => {
              onClose();
            }}
          >
            {mainContext?.getTranslation("common", "kapat")}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
