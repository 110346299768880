import {SvgIcon} from "@mui/material";

export function DateIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <g clip-path="url(#clip0_2221_14737)">
        <path
          d="M20.2089 4H18.2228V5.33333H20.0561V20H2.94504V5.33333H4.77837V4H2.79226C2.6494 4.0026 2.50842 4.03588 2.37735 4.09794C2.24629 4.15999 2.12772 4.24961 2.02841 4.36166C1.9291 4.47371 1.85099 4.60601 1.79856 4.751C1.74612 4.89599 1.72039 5.05083 1.72281 5.20667V20.1267C1.72039 20.2825 1.74612 20.4373 1.79856 20.5823C1.85099 20.7273 1.9291 20.8596 2.02841 20.9717C2.12772 21.0837 2.24629 21.1733 2.37735 21.2354C2.50842 21.2974 2.6494 21.3307 2.79226 21.3333H20.2089C20.3518 21.3307 20.4928 21.2974 20.6238 21.2354C20.7549 21.1733 20.8735 21.0837 20.9728 20.9717C21.0721 20.8596 21.1502 20.7273 21.2026 20.5823C21.2551 20.4373 21.2808 20.2825 21.2784 20.1267V5.20667C21.2808 5.05083 21.2551 4.89599 21.2026 4.751C21.1502 4.60601 21.0721 4.47371 20.9728 4.36166C20.8735 4.24961 20.7549 4.15999 20.6238 4.09794C20.4928 4.03588 20.3518 4.0026 20.2089 4Z"
          fill="#FD7571"/>
        <path d="M5.39062 9.33325H6.61285V10.6666H5.39062V9.33325Z" fill="#FD7571"/>
        <path d="M9.05469 9.33325H10.2769V10.6666H9.05469V9.33325Z" fill="#FD7571"/>
        <path d="M12.7227 9.33325H13.9449V10.6666H12.7227V9.33325Z" fill="#FD7571"/>
        <path d="M16.3906 9.33325H17.6128V10.6666H16.3906V9.33325Z" fill="#FD7571"/>
        <path d="M5.39062 12.6667H6.61285V14.0001H5.39062V12.6667Z" fill="#FD7571"/>
        <path d="M9.05469 12.6667H10.2769V14.0001H9.05469V12.6667Z" fill="#FD7571"/>
        <path d="M12.7227 12.6667H13.9449V14.0001H12.7227V12.6667Z" fill="#FD7571"/>
        <path d="M16.3906 12.6667H17.6128V14.0001H16.3906V12.6667Z" fill="#FD7571"/>
        <path d="M5.39062 16H6.61285V17.3333H5.39062V16Z" fill="#FD7571"/>
        <path d="M9.05469 16H10.2769V17.3333H9.05469V16Z" fill="#FD7571"/>
        <path d="M12.7227 16H13.9449V17.3333H12.7227V16Z" fill="#FD7571"/>
        <path d="M16.3906 16H17.6128V17.3333H16.3906V16Z" fill="#FD7571"/>
        <path
          d="M6.61111 6.66659C6.77319 6.66659 6.92863 6.59635 7.04323 6.47132C7.15784 6.3463 7.22222 6.17673 7.22222 5.99992V1.99992C7.22222 1.82311 7.15784 1.65354 7.04323 1.52851C6.92863 1.40349 6.77319 1.33325 6.61111 1.33325C6.44903 1.33325 6.2936 1.40349 6.17899 1.52851C6.06438 1.65354 6 1.82311 6 1.99992V5.99992C6 6.17673 6.06438 6.3463 6.17899 6.47132C6.2936 6.59635 6.44903 6.66659 6.61111 6.66659Z"
          fill="#FD7571"/>
        <path
          d="M16.3885 6.66659C16.5505 6.66659 16.706 6.59635 16.8206 6.47132C16.9352 6.3463 16.9996 6.17673 16.9996 5.99992V1.99992C16.9996 1.82311 16.9352 1.65354 16.8206 1.52851C16.706 1.40349 16.5505 1.33325 16.3885 1.33325C16.2264 1.33325 16.0709 1.40349 15.9563 1.52851C15.8417 1.65354 15.7773 1.82311 15.7773 1.99992V5.99992C15.7773 6.17673 15.8417 6.3463 15.9563 6.47132C16.0709 6.59635 16.2264 6.66659 16.3885 6.66659Z"
          fill="#FD7571"/>
        <path d="M8.44531 4H14.5564V5.33333H8.44531V4Z" fill="#FD7571"/>
      </g>
      <defs>
        <clipPath id="clip0_2221_14737">
          <rect width="22" height="24" fill="white" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
