import {parsePaymentPlans, TPaymentPlan} from "./TPaymentPlan";

export interface TPaymentHistory {
  key: string,
  transactionType: string,
  transactionRelationKey: string,
  transactionRelationStr: string,
  relationType: string,
  relationKey: string,
  relationStr: string,
  transactionDateStr?: string,
  transactionDateObj?: Date,
  currencyCode?: string
  price?: number
  totalPayment?: number
  roomRelationStatus?: string
  relationPicture?: string
  paymentPlans?: TPaymentPlan[]
}

export function parsePaymentHistoriesResponse(responseData: any): TPaymentHistory[] {
  let map = responseData.map((item: any): TPaymentHistory | undefined => parsePaymentHistoryResponse(item)).filter((item: any) => item != undefined);
  return map ?? []
}

export function parsePaymentHistoryResponse(item: any): TPaymentHistory | undefined {
  if (item == undefined) {
    return undefined
  }

  let model = {
    key: item.key,
    transactionType: item.transactionType,
    transactionRelationKey: item.transactionRelationKey,
    transactionRelationStr: item.transactionRelationStr,
    relationType: item.relationType,
    relationKey: item.relationKey,
    relationStr: item.relationStr,
    price: item.price ?? 0,
    totalPayment: item.totalPayment ?? 0,
    currencyCode: item.currencyCode,
    roomRelationStatus: item.roomRelationStatus,
    relationPicture: item.relationPicture,
    paymentPlans: item.paymentPlan ? parsePaymentPlans(item.paymentPlan) : undefined,
  };
  return model
}
