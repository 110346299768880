import {Box, styled} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import styles from './Profile.module.scss'
import {useContext} from "react";
import {MainContext} from "../../context/MainContext";
import {useTranslation} from "../../hooks/TranslationHook";
import {useNavigate} from "react-router-dom";

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

interface IProps {
  hideVisibilityIcon?: boolean
  customClass?:string
}

export function CProfileCard({hideVisibilityIcon,customClass}: IProps) {
  const navigate = useNavigate();
  const mainContext = useContext(MainContext)
  const profileTranslation = useTranslation('profil-sayfa', 'sayfa')
  return (
    <div
      className={styles.profileCard}
      style={{boxShadow: "0px 20px 80px 0px #EBECF0"}}
    >
      <div className={`flex-col px-[46px] py-[24px] items-center relative bg-white ${customClass??''}`}>
        {!hideVisibilityIcon &&
            <div className="absolute w-[24px] h-[24px] top-[12px] right-[12px]">
                <img src="/images/visibility.svg"/>
            </div>
        }
        <div className="relative w-[83px] h-[82px] p-[4px] flex justify-center items-center">
          <img
            className="w-full h-full rounded-full bg-gray-300"
            src={mainContext?.userData?.picture}
          />
          <img
            className="absolute w-full h-full rounded-full"
            src="/images/availableRing.svg"
          />
        </div>
        <div className="text-black text-18-24-600 text-center underline pt-[16px]">
          {mainContext?.userData?.name}
        </div>
        <div className="text-grey text-16-24-500 pt-[8px]">
          {mainContext?.userData?.title}
        </div>
        <div className="text-grey text-14-24-500 pt-[4px]">
          {mainContext?.userData?.address}
        </div>
      </div>
      {(mainContext?.userData?.score??0)<100 &&
          <div className={styles.completeProfileCard}>
              <p className="text-black text-18-24-600">{profileTranslation('profilini-tamamla')}</p>
              <div className="pt-[8px] flex items-center gap-[16px]">
                  <Box className={''} sx={{flexGrow: 1}}>
                      <BorderLinearProgress variant="determinate" value={mainContext?.userData?.score??0}/>
                  </Box>
                  <span className="text-grey text-14-22-400">% {mainContext?.userData?.score??0}</span>
              </div>
              <div className="pt-[24px] text-black text-16-24-500">
                {profileTranslation('profilini-tamamla-aciklama')}
              </div>
              <div className={styles.completeInfoDiv} role={'button'} onClick={()=>navigate('/profile')}>
                {profileTranslation('profilini-tamamla-link')}
              </div>
          </div>
      }
    </div>
  );
}
