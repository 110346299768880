import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { FaqItems } from "./faqConstants";

export function Faq() {
  return (
    <>
      <div className="faq-section">
        <Accordion className="faq-item">
          <AccordionSummary
            expandIcon={<img src={"/images/menu_arrow.svg"} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Lorem ipsum dolor sit amet consectetur?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet consectetur. Egestas auctor dui
              elementum adipiscing blandit luctus tellus elementum libero. Elit
              vitae scelerisque risus laoreet nec et nam mi viverra. Turpis
              semper laoreet dolor cras morbi dolor. Porttitor pellentesque
              pulvinar enim purus risus amet. Vitae blandit dolor faucibus
              varius elit pellentesque in in. Eget eget viverra feugiat feugiat
              augue duis diam lobortis. Sollicitudin habitasse amet eu vitae.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="faq-item">
          <AccordionSummary
            expandIcon={<img src={"/images/menu_arrow.svg"} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Lorem ipsum dolor sit amet consectetur?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet consectetur. Egestas auctor dui
              elementum adipiscing blandit luctus tellus elementum libero. Elit
              vitae scelerisque risus laoreet nec et nam mi viverra. Turpis
              semper laoreet dolor cras morbi dolor. Porttitor pellentesque
              pulvinar enim purus risus amet. Vitae blandit dolor faucibus
              varius elit pellentesque in in. Eget eget viverra feugiat feugiat
              augue duis diam lobortis. Sollicitudin habitasse amet eu vitae.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="faq-item">
          <AccordionSummary
            expandIcon={<img src={"/images/menu_arrow.svg"} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Lorem ipsum dolor sit amet consectetur?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet consectetur. Egestas auctor dui
              elementum adipiscing blandit luctus tellus elementum libero. Elit
              vitae scelerisque risus laoreet nec et nam mi viverra. Turpis
              semper laoreet dolor cras morbi dolor. Porttitor pellentesque
              pulvinar enim purus risus amet. Vitae blandit dolor faucibus
              varius elit pellentesque in in. Eget eget viverra feugiat feugiat
              augue duis diam lobortis. Sollicitudin habitasse amet eu vitae.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="faq-item">
          <AccordionSummary
            expandIcon={<img src={"/images/menu_arrow.svg"} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Lorem ipsum dolor sit amet consectetur?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet consectetur. Egestas auctor dui
              elementum adipiscing blandit luctus tellus elementum libero. Elit
              vitae scelerisque risus laoreet nec et nam mi viverra. Turpis
              semper laoreet dolor cras morbi dolor. Porttitor pellentesque
              pulvinar enim purus risus amet. Vitae blandit dolor faucibus
              varius elit pellentesque in in. Eget eget viverra feugiat feugiat
              augue duis diam lobortis. Sollicitudin habitasse amet eu vitae.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion className="faq-item">
          <AccordionSummary
            expandIcon={<img src={"/images/menu_arrow.svg"} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Lorem ipsum dolor sit amet consectetur?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Lorem ipsum dolor sit amet consectetur. Egestas auctor dui
              elementum adipiscing blandit luctus tellus elementum libero. Elit
              vitae scelerisque risus laoreet nec et nam mi viverra. Turpis
              semper laoreet dolor cras morbi dolor. Porttitor pellentesque
              pulvinar enim purus risus amet. Vitae blandit dolor faucibus
              varius elit pellentesque in in. Eget eget viverra feugiat feugiat
              augue duis diam lobortis. Sollicitudin habitasse amet eu vitae.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}
