import {FilterModal} from "./FilterModal";
import {useContext, useEffect, useState} from "react";
import {MainContext} from "../../context/MainContext";
import {FilterConfig, FilterPageProps, JobFilterType} from "../../types/TFilter";
import './Filter.scss'
import {CSelectWithSearchForFilter} from "../Input/CSelectWithSearchForFilter";
import {getV2} from "../../utils/request";
import {TError} from "../../types/TError";
import {CSelectWithSearchForFilterSingle} from "../Input/CSelectWithSearchForFilterSingle";

const jobFilterType = JobFilterType.Location;

export function FilterLocation({isOpen, onSave, onClose, filters}: FilterPageProps) {
  const mainContext = useContext(MainContext);

  const [tempCountry, setTempCountry] = useState<FilterConfig | undefined>(undefined)
  const [tempCity, setTempCity] = useState<FilterConfig | undefined>(undefined)
  const [countryOptions, setCountryOptions] = useState<FilterConfig[] | undefined>(undefined)
  const [cityOptions, setCityOptions] = useState<FilterConfig[] | undefined>(undefined)

  useEffect(() => {
    if (mainContext?.userData?.token) {
      getV2(`/Common/country/list`).then((resp) => {
        if (resp instanceof TError) {
          return
        }
        setCountryOptions(
          resp.data.map((item: any) => {
            return {id: item.id, label: item.name};
          })
        );
      });
    }
  }, [mainContext?.userData?.token]);

  useEffect(() => {
    if (!(filters && filters.length > 1 && tempCountry?.label == filters[0].label)) {
      setTempCity(undefined)
      setCityOptions(undefined)
    }
    if (tempCountry != undefined) {
      if (mainContext?.userData?.token) {
        getV2(`/Common/city/list?countryID=${tempCountry.id}`).then((resp) => {
          if (resp instanceof TError) {
            return
          }
          setCityOptions(
            resp.data.map((item: any) => {
              return {id: item.id, label: item.name};
            })
          );
        });
      }
    }
  }, [mainContext?.userData?.token, tempCountry])

  function handleSave() {
    const Filters: FilterConfig[] = []

    if (tempCountry != undefined) {
      Filters.push(tempCountry)
      if (tempCity != undefined) {
        Filters.push(tempCity)
      }
    }

    if (Filters.length > 0) {
      onSave(Filters)
    } else {
      onSave(undefined)
    }

    onClose();
  }

  function onCancel() {
    onClose();
  }


  useEffect(() => {
    if (filters) {
      if (filters.length > 0) {
        setTempCountry(filters[0])
      }
      if (filters.length > 1) {
        setTempCity(filters[1])
      }
    } else {
      setTempCountry(undefined)
      setTempCity(undefined)
    }
  }, [filters]);

  return <FilterModal
    title={mainContext!.getTranslation('filters', jobFilterType)}
    header={mainContext!.getTranslation('filters', 'calisma-lokasyonu')}
    desc={mainContext!.getTranslation('filters', 'uygun-secenegi-belirt')}
    isOpen={isOpen} onClose={onCancel} onSave={handleSave}
    customHeaderClass={'!pb-[30px]'}>
    <div className={'flex justify-between gap-[28px]'}>
      <div className={'flex-1 flex-col gap-[20px]'}>
        <div className={'header'}>
          {mainContext?.getTranslation('common', 'ulke')}
        </div>
        <CSelectWithSearchForFilterSingle loading={countryOptions == undefined}
                                    options={countryOptions} val={tempCountry}
                                    onChange={(e) => {
                                      //@ts-ignore
                                      setTempCountry(e)
                                    }}/>
      </div>
      <div className={'flex-1 flex-col gap-[20px]'}>
        <div className={'header'}>
          {mainContext?.getTranslation('common', 'sehir')}
        </div>
        <CSelectWithSearchForFilterSingle disabled={cityOptions == undefined} val={tempCity}
                                    loading={(tempCountry != undefined && cityOptions == undefined)}
                                    options={cityOptions ?? []}
                                    onChange={(e) => {
                                      //@ts-ignore
                                      setTempCity(e)
                                    }}/>
      </div>
    </div>

  </FilterModal>
}
