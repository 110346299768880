import CLoadingAnimation from "../CLoadingAnimation";

interface IProps {
  loading: boolean
  disabled: boolean
  text: string
  onClick: () => void
  className?: string
  textClassName?: string
  leadingComponent?: JSX.Element
}

export function ModalButton({disabled, loading, text, onClick, className, textClassName, leadingComponent}: IProps) {
  return <div role={'button'}
              style={{pointerEvents: disabled ? 'none' : 'auto'}}
              onClick={onClick}
              className={((disabled || loading) ? 'loadingButton ' : '') + 'rounded-[8px] px-[24px] py-[12px] ' + (className ?? 'div-purple')}>
    {loading &&
        <CLoadingAnimation size={32}/>
    }
    {leadingComponent}
    <p
      className={(textClassName ? (textClassName) : 'text-14-16-600 text-white') + (loading ? ' opacity-0' : '')}>{text}</p>
  </div>
}
