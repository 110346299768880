export interface TEducation{
  id:string
  educationType:EducationType
  schoolName:string
  departmentName:string
  schoolId:number
  departmentId:number
  beginMonth:number,
  beginYear:number,
  endMonth:number,
  endYear:number,
  beginDateYM:string
  endDateYM:string
  details:string[]
  visible:boolean
  deleted?: boolean;
}

export enum EducationType{
  HighSchool = 'HighSchool',
  University = 'University',
  Degree = 'Degree',
  PhD = 'PhD',
}
export const EducationTypeOptions = Object.values(EducationType);
