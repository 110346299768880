import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { isDesktop } from "../utils/window";

interface IProps {
  data: any[];
}

function CSwiper1TopPicture({ data }: IProps) {
  const [swiper, setSwiper] = useState<any | undefined>(undefined);

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(0, 0);
    }
  }, [swiper]);

  useEffect(() => {
    if (!isDesktop()) {
      const els = document.getElementsByClassName("swiper-initialized");
      if (els && els.length > 0) {
        // const padding = (window.innerWidth - 312) / 2;
        const padding = window.innerWidth * 0.05;

        for (let index = 0; index < els.length; index++) {
          const element = els[index];
          // @ts-ignore
          element.style.paddingLeft = `${padding}px`;
          // @ts-ignore
          element.style.paddingRight = `${padding}px`;
        }
      }
    }
  }, []);

  return (
    <div className="w-full flex">
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={0}
        className={`swiper swiper-flex-height swiper-pictures`}

        onSwiper={(swiper) => {
          setSwiper(swiper);
        }}
      >
        {data.map((card:any) => {
          return (
            <SwiperSlide>
              <div className="contentHolder noShadow" style={{height:'100%'}}>
                {card}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

export default CSwiper1TopPicture;
