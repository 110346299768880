import {FilterModal} from "./FilterModal";
import {useContext, useEffect, useState} from "react";
import {MainContext} from "../../context/MainContext";
import {FilterPageProps, FilterConfig, JobFilterType} from "../../types/TFilter";
import './Filter.scss'
import 'dayjs/locale/tr';
import {NumericFormat} from "react-number-format";
import {CSelectWithSearchForFilter} from "../Input/CSelectWithSearchForFilter";
import {getV2} from "../../utils/request";
import {TextField} from "@mui/material";
import {TError} from "../../types/TError";
import {CSelectWithSearchForFilterSingle} from "../Input/CSelectWithSearchForFilterSingle";

export interface PaymentRangeValue {
  amount: number
  type: string
}

const jobFilterType = JobFilterType.PaymentRange;

interface CustomFilter {
  value: number | undefined
  counter: number
}

export function FilterPaymentRange({isOpen, onClose, onSave, filters}: FilterPageProps) {
  const mainContext = useContext(MainContext);
  // const [tempVal, setTempVal] = useState<PaymentRangeValue | null>(null)

  const [priceBegin, setPriceBegin] = useState<number | undefined>(undefined)
  const [priceEnd, setPriceEnd] = useState<number | undefined>(undefined)
  const [currency, setCurrency] = useState<FilterConfig | undefined>(undefined)

  const [options, setOptions] = useState<FilterConfig[] | undefined>(undefined)

  useEffect(() => {
    if(mainContext?.userData?.token) {
      getV2(`/Common/currency/list`).then((resp) => {
        if (resp instanceof TError) {
          return
        }
        setOptions(
          resp.data.map((item: any) => {
            return {id: item.id, label: item.name, code: item.code, symbol: item.symbol};
          })
        );
      });
    }
  }, [mainContext?.userData?.token]);

  function handleSave() {
    if (invalidRangeErrorExists || missingValueErrorExists) {
      return
    }
    onSave(currency ? [{id:0,label:'priceBegin',valueNumber:priceBegin,valueStr:''+priceBegin},{id:0,label:'priceEnd',valueNumber:priceEnd,valueStr:''+priceEnd},currency] : undefined)
    onClose();
  }
  const missingValueErrorExists = ((priceBegin != undefined && priceBegin > 0) || (priceEnd != undefined && priceEnd > 0)) && (currency == undefined)
  const invalidRangeErrorExists = (priceBegin != undefined && priceEnd != undefined && priceEnd <= priceBegin)

  useEffect(() => {
    if(filters) {
      if (filters.length > 0) {
        setPriceBegin(filters[0].valueNumber)
      }
      if (filters.length > 1) {
        setPriceEnd(filters[1].valueNumber)
      }
      if (filters.length > 2) {
        setCurrency(filters[2])
      }
    }
  }, [filters]);

  return <FilterModal
    title={mainContext!.getTranslation('filters', jobFilterType)}
    header={mainContext!.getTranslation('filters', 'istediginiz-ucret')}
    customHeaderClass={'!pb-0'}
    saveDisabled={missingValueErrorExists || invalidRangeErrorExists}
    isOpen={isOpen} onClose={onClose} onSave={handleSave}>
    <div className={'input-select'}>
      <div className={'pt-[16px] flex-col gap-[12px]'}>
        {/*<p className={'text-16-24-500 text-black'}>{mainContext!.getTranslation('common', 'aralik-giriniz')}</p>*/}
        <div className={'flex gap-[28px]'}>
          <div className={'flex flex-col gap-[8px] w-full max-w-[304px]'}>
            <div className={'priceTitle'}>{mainContext!.getTranslation('filters', 'en-dusuk')}</div>
            <NumericFormat
              className={'priceInput'}
              thousandSeparator={','}
              customInput={TextField}
              onValueChange={(values) => setPriceBegin(1)}
              value={priceBegin}
              suffix={currency==undefined?'':' '+currency.code}
            />
          </div>
          <div className={'flex flex-col gap-[8px] w-full max-w-[304px]'}>
            <div className={'priceTitle'}>{mainContext!.getTranslation('filters', 'en-yuksek')}</div>
            <NumericFormat
              className={'priceInput'}
              thousandSeparator={','}
              customInput={TextField}
              onValueChange={(values) => setPriceEnd(values.floatValue)}
              value={priceEnd}
              suffix={currency==undefined?'':' '+currency.code}
            />
          </div>
        </div>
        <CSelectWithSearchForFilterSingle options={options} val={currency}
          //@ts-ignore
                                    onChange={setCurrency}/>
        {(invalidRangeErrorExists || missingValueErrorExists) &&
            <div className={'w-full pt-[16px] text-red-500 text-14-17-400'}>
              {invalidRangeErrorExists && mainContext!.getTranslation('filters', 'fiyat-aralik-hatasi')}
              {missingValueErrorExists && mainContext!.getTranslation('filters', 'para-birimi-hatasi')}
            </div>
        }
      </div>
    </div>
  </FilterModal>
}
