import { IDataProps } from "src/types/DataProps";
import { isDesktop } from "src/utils/window";

function CHeroExpert({getTranslation,data}: IDataProps) {
  return (
    <div className="homeHero">
      <div className="homeHeroImage">
      {isDesktop()?
        <img src="/images/expert_hero_background.svg" />
        :
        <img src="/images/expert_hero_mobile_background.svg" /> 
      }
      </div>
      <div className="flex justify-between main-width m-auto main-padding pt-[132px] lg:pt-[152px] relative items-center h-full flex-col lg:flex-row">
        <div className="flex-col justify-left flex-1 text-center lg:text-start">
          <div className="text-20-24-400 text-grey">{getTranslation('hero-header')}</div>
          <div className="pt-[16px] text-32-40-500 text-black">
          {getTranslation('hero-title')}
          </div>
          <div className="pt-[16px] text-16-24-400 text-grey">
          {getTranslation('hero-content')}
          </div>
        </div>
        <div className="w-full lg:w-[min(850px,75%)]">
          <img src={data?.pagePictureModelList?.[0].getUrl()??''} />
        </div>
      </div>
    </div>
  );
}

export default CHeroExpert;
