import {useContext, useEffect, useRef, useState} from "react";
import {
  MessageType,
  parseMessageRooms,
  parseMessages,
  TMessage,
  TMessageRoom,
  TMessageRoomRelationType,
  TMessageRoomRelationTypeToStr
} from "../../types/TMessage";
import {PageState} from "../../types/Context";
import {MainContext, MainContextType} from "../../context/MainContext";
import {postV2} from "../../utils/request";
import {Translation} from "../../types/Translation";
import CLoadingAnimation from "../../components/CLoadingAnimation";
import {ModalButton} from "../../components/Input/ModalButton";
import {dateToString} from "../../utils/date";
import {NotificationType} from "../../components/Toast/Toast";
import {useParams} from "react-router";
import {UserType} from "../../types/TUserData";
import {TError} from "../../types/TError";
import {getTranslations} from "../../service/commonRequest";
import {BModal} from "../../components/Modal/BModal";
import {useDropzone} from "react-dropzone";
import {byteArrayToBase64} from "../../utils/file";
import {FilterConfig} from "../../types/TFilter";
import {CSelectWithSearchForFilterSingle} from "../../components/Input/CSelectWithSearchForFilterSingle";
import {UploadedFile} from "../../components/Message/UploadedFile";
import {RequestedRevision} from "../../components/Message/RequestedRevision";
import {MessageHook} from "../../hooks/MessageHook";


enum LoadingMessagesState {
  Idle,
  Loading,
  Finished
}

const messageListLength = 50

export function Messages() {
  const mainContext = useContext(MainContext)
  const {userId} = useParams()
  const [messageRooms, setMessageRooms] = useState<TMessageRoom[] | undefined>(undefined)
  const [selectedRoom, setSelectedRoom] = useState<TMessageRoom | undefined>(undefined)
  const [messages, setMessages] = useState<TMessage[] | undefined>(undefined)
  const [newMessage, setNewMessage] = useState('')
  const [sendingMessage, setSendingMessage] = useState(false)
  const [loadingMessages, setLoadingMessages] = useState(LoadingMessagesState.Idle)
  const [projectOptions, setProjectOptions] = useState<FilterConfig[] | undefined>(undefined)
  const [tempOption, setTempOption] = useState<FilterConfig | undefined>(undefined)
  const [roomSelectionNumber, setRoomSelectionNumber] = useState(0)

  const [requestingMessages, setRequestingMessages] = useState(false)

  const {messages: newMessages, addMessage, setHookProps} = MessageHook()

  const [afiles, setAFiles] = useState<File[]>([])
  const {acceptedFiles, getRootProps, getInputProps, inputRef} = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
    }
  });
  useEffect(() => {
    setAFiles(acceptedFiles)
  }, [acceptedFiles]);
  const files = afiles.map(file => (
    <li key={file.name}>
      {file.name}
    </li>
  ));

  const [translations, setTranslations] = useState<
    Map<string, Translation> | undefined
  >(undefined);

  function getTranslation(child: string): string {
    return translations?.get('sayfa')?.sentences.get(child) ?? "";
  }

  function fetchData() {
    setMessageRooms(undefined)
    getTranslations('mesaj-sayfa', (translations, data) => {
      setTranslations(translations);
      getMessageRooms()
    })
  }

  useEffect(() => {
    if (messageRooms != undefined) {
      if (userId != undefined) {
        const room = messageRooms.find(r => r.users.find(u => {
          return u.keyId == userId
        }))
        if (room != undefined) {
          setSelectedRoom(room)
          setRoomSelectionNumber((oldVal) => oldVal + 1)
        } else {
          debugger
          createMessageRoom()
        }
      }
    }
  }, [messageRooms]);


  useEffect(() => {
    if (mainContext?.userData?.token && mainContext.publicToken) {
      const isExpired = mainContext.isExpired('messages')
      //berkExpire
      if (!isExpired) {
        fetchData()
      } else {
        setTimeout(() => {
          mainContext.setSigninupState(PageState.Signin)
        }, 1500)
      }
    }
  }, [mainContext?.userData, mainContext?.userData?.token, mainContext?.publicToken]);


  const onScroll = () => {
    if (messagesRef.current) {
      const {scrollTop, scrollHeight, clientHeight, offsetTop} = messagesRef.current;
      let atTop = scrollTop == 0;
      if (atTop && messages != undefined && (messages?.length ?? 0) > 0) {
        if ((messages.length % messageListLength) == 0) {
          getOlderMessages();
        }
      }
    }
  };

  function getMessageRooms(customRoom?: TMessageRoom) {
    if (customRoom != undefined) {
      setRequestingMessages(true)
    }
    postV2('/Room/List', {}).then((resp) => {
      setRequestingMessages(false)
      if (resp.data.result == false) {
        mainContext?.showToast(NotificationType.Fail, 'getMessages')
        return
      }
      const newRooms = parseMessageRooms(resp.data);
      if (customRoom != undefined) {
        let customNewRoom = newRooms.find(r => r.roomKeyId == customRoom.roomKeyId)
        setMessageRooms((oldRooms) => {
          if (oldRooms != undefined) {
            for (let i = 0; i < oldRooms.length; i++) {
              if (oldRooms[i].roomKeyId == customRoom.roomKeyId) {
                oldRooms[i] = customNewRoom ?? customRoom
              }
            }
          }
          return oldRooms
        })

        setSelectedRoom(customNewRoom ? {...customNewRoom} : {...customRoom})
        setRoomSelectionNumber((oldVal) => oldVal + 1)
      } else {
        setMessageRooms(newRooms)
      }
    }).catch((ex) => {
      mainContext?.showToast(NotificationType.Fail, 'getMessages')
      setRequestingMessages(false)
    })
  }

  function createMessageRoom() {
    postV2('/Room/Create', {
      memberRelationKey: userId,
      memberType: mainContext?.userData?.userType == UserType.Professional ? UserType.Company : UserType.Professional
    }).then((resp) => {
      debugger
      if (resp instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'createMessageRoom')
      }
      sendMessage()
    })
  }

  function getOlderMessages() {
    setLoadingMessages(LoadingMessagesState.Loading)
    const page = (messages!.length / messageListLength) + 1
    postV2('/Room/listpastmessage', {
      RoomMessageKey: messages![0].keyId,
      MessageCount: messageListLength
    }).then((resp) => {
      if (resp.data.result == false) {
        mainContext?.showToast(NotificationType.Fail, 'getMessages')
        return
      }
      const olderMessages = parseMessages(resp.data, selectedRoom?.users ?? [])
      setMessages([...olderMessages, ...messages!])
    }).catch((ex) => {
      mainContext?.showToast(NotificationType.Fail, 'getMessages')
    }).finally(() => {
      setTimeout(() => {
        setLoadingMessages(LoadingMessagesState.Finished)
      }, 150);
    })
  }

  useEffect(() => {
    if (selectedRoom != undefined) {
      setMessages(selectedRoom.messages)
      setProjectOptions(selectedRoom.relations
        // .filter(r => r.type == TMessageRoomRelationType.Project)?
        .map((r, i) => {
          // Key_
          return {id: i, code: r.keyId, label: r.name, valueNumber: r.type}
        }) ?? [])
      setHookProps({
        users: selectedRoom.users,
        lastMessageUnix: (messages && messages.length > 0) ? messages![messages!.length - 1].dateObj.getTime() : 0,
        reset: true
      })
    }
  }, [selectedRoom, roomSelectionNumber]);

  const [scrolledBottom, setScrolledBottom] = useState(false)

  useEffect(() => {
    if (messages && messages.length > 0 && selectedRoom != undefined && !scrolledBottom /*(messages?.length ?? 0) <= messageListLength*/) {
      setScrolledBottom(true)
      setTimeout(() => {
        const container = document.getElementById('messages')
        if (container != null) {
          const totalHeight = Math.max(container.scrollHeight, container.clientHeight)
          container.scrollTop = totalHeight;
        }
      }, 200)
    }
  }, [selectedRoom, messages]);

  useEffect(() => {
    if (loadingMessages == LoadingMessagesState.Finished) {
      const messageId = messages![messageListLength].keyId
      const message = document.getElementById(messageId);
      if (message != null) {
        const container = document.getElementById('messages');
        if (container) {
          console.log(messageId)
          container.scrollTo(0, message.offsetTop - container.offsetTop - 100)
        }
      }
    }
  }, [loadingMessages]);

  function sendMessage() {
    if (newMessage.length < 1) {
      return
    }

    setSendingMessage(true)
    let you = selectedRoom?.users.find(u => u.keyId == mainContext?.userData?.id);
    postV2('/Room/message', {
      roomUserKey: you?.roomKeyId,
      message: newMessage
    }).then((resp) => {
      if (resp.data.result == false) {
        mainContext?.showToast(NotificationType.Fail, 'sendMessage')
        return
      }
      setNewMessage('')
      let sentMessage = {
        keyId: `s${newMessages?.length ?? 0}`,
        userKeyId: mainContext?.userData?.id ?? '',
        message: newMessage,
        messageType: MessageType.Message,
        dateObj: new Date(),
        dateStr: new Date().toISOString(),
        user: you,
        reads: []
      };
      addMessage(sentMessage)
    }).catch((ex) => {
      mainContext?.showToast(NotificationType.Fail, 'sendMessage')
    }).finally(() => {
      setSendingMessage(false)
    })
  }

  useEffect(() => {
    setTimeout(() => {
      const container = document.getElementById('messages')
      if (container != null) {
        const totalHeight = Math.max(container.scrollHeight, container.clientHeight)
        container.scrollTop = totalHeight;
      }
    })
  }, [newMessages]);

  const [reviseModal, setReviseModal] = useState(false)
  const [reviseRequesting, setReviseRequesting] = useState(false)
  const [fileUploadModal, setFileUploadModal] = useState(false)
  const [requesting, setRequesting] = useState(false)

  function renderMessage(message: TMessage, previousMessage?: TMessage): JSX.Element {
    const senderIsYou = message.user?.keyId == mainContext?.userData?.id;
    const receiverRead = message.reads.find(r => (r.user?.type && mainContext?.userData?.userType && r.user?.type != mainContext?.userData?.userType)) != undefined;
    let hours = '' + message.dateObj.getHours();
    let minutes = '' + message.dateObj.getMinutes();
    if (hours.length == 1) {
      hours = '0' + hours
    }
    if (minutes.length == 1) {
      minutes = '0' + minutes
    }
    return <>
      {previousMessage == undefined ?
        <div
          className={'flex justify-center text-light-grey text-10-24-500 py-[12px]'}>{dateToString(message.dateObj)}</div> :
        (previousMessage && dateToString(previousMessage.dateObj) != dateToString(message.dateObj)) ?
          <div
            className={'flex justify-center text-light-grey text-10-24-500 py-[12px]'}>{dateToString(message.dateObj)}</div> :
          <div className={'h-[12px]'}>&nbsp;</div>
      }
      <div key={message.keyId} id={message.keyId}
           className={`flex w-full ${message.user?.type == mainContext?.userData?.userType ? 'justify-end' : 'justify-start'}`}>
        <div className={''}>
          <div className={'messageBox'}>
            {!senderIsYou &&
                <img className={'w-[67px] h-[67px] rounded-full bg-gray-300'}
                     src={message.user?.photo}/>
            }
            <div className={'flex-col items-end'}>
              {message.messageType == MessageType.Message &&
                  <div
                      className={`message ${senderIsYou ? 'sentMessage' : 'receivedMessage'}`}>
                      <span className={'text'}>{message.message}</span>
                  </div>
              }
              {message.messageType == MessageType.File &&
                  <UploadedFile onRight={senderIsYou} link={message.message}/>
              }
              {message.messageType == MessageType.Revision &&
                  <RequestedRevision onRight={senderIsYou} message={message}/>
              }
              <div className={'flex gap-[4px]'}>
                {senderIsYou &&
                    <img src={receiverRead ? '/images/messageRead.svg' : '/images/messageUnread.svg'}/>
                }
                <span className={'date'}>
                            {`${hours}.${minutes}`}
                          </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  }

  const messagesRef = useRef<HTMLDivElement>(null)

  function createRevise() {
    setReviseRequesting(true)
    let relation = selectedRoom?.relations.find(r => r.keyId == tempOption?.code);

    let data = {
      RelationType: relation!.type,
      RelationKey: relation!.keyId,
    }
    let you = selectedRoom?.users.find(u => u.keyId == mainContext?.userData?.id);
    postV2('/Room/RevitionCreate', data)
      .then((resp) => {
        setReviseRequesting(false)
        if (resp instanceof TError) {
          mainContext?.showToast(NotificationType.Fail, 'revise')
          return
        }
        let sentMessage = {
          keyId: `s${newMessages?.length ?? 0}`,
          userKeyId: mainContext?.userData?.id ?? '',
          message: newMessage,
          messageType: MessageType.Revision,
          dateObj: new Date(),
          dateStr: new Date().toISOString(),
          user: you,
          reads: [],
          relationName: relation!.name,
        };
        addMessage(sentMessage)
        setReviseModal(false)
        setTempOption(undefined)
        mainContext?.showToast(NotificationType.Success, 'revise')
      })
  }

  function onFileUpload() {
    setRequesting(true)
    getFile(
      acceptedFiles[0],
      (filename, fileData) => {
        let relation = selectedRoom?.relations.find(r => r.keyId == tempOption?.code);
        if (relation) {
          saveFile(relation.type, relation.keyId, filename, fileData)
        }
      },
      (error) => {
        setRequesting(false);
        mainContext?.showToast(NotificationType.Fail, 'uploadFile')
      }, mainContext
    )
  }

  function saveFile(relationType: TMessageRoomRelationType, relationKey: string, name: string, file: string) {
    let data = {
      RelationType: TMessageRoomRelationTypeToStr(relationType),
      RelationKey: relationKey,
      RoomUserKey: mainContext?.userData?.id,
      fileName: name,
      fileContents: file
    };
    postV2("/Room/RoomFileCreate", data).then((resp) => {
      setRequesting(false);
      if (resp instanceof TError || resp.data.result == false) {
        mainContext?.showToast(NotificationType.Fail, 'uploadFile')
      } else {
        mainContext?.showToast(NotificationType.Success, 'uploadFile')
        setFileUploadModal(false)
        let you = selectedRoom?.users.find(u => u.keyId == mainContext?.userData?.id);
        let sentMessage = {
          keyId: `s${newMessages?.length ?? 0}`,
          userKeyId: mainContext?.userData?.id ?? '',
          message: resp.data.message,
          messageType: MessageType.File,
          dateObj: new Date(),
          dateStr: new Date().toISOString(),
          user: you,
          reads: []
        };
        addMessage(sentMessage)
      }
    });
  }

  function getFile(file: File, onSuccess: (filename: string, fileData: any) => void, onError: (error: string) => void, mainContext: MainContextType | null) {
    const reader = new FileReader()
    reader.onabort = () => {
      console.log('file reading was aborted')
      onError(mainContext?.getTranslation('common', 'dosya-okunamadi') ?? 'Error')
    }
    reader.onerror = () => {
      console.log('file reading has failed')
      onError(mainContext?.getTranslation('common', 'dosya-okunamadi') ?? 'Error')
    }
    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result
      if (binaryStr == null) {
        onError(mainContext?.getTranslation('common', 'dosya-okunamadi') ?? 'Error')
        return
      }
      if (binaryStr instanceof ArrayBuffer) {
        const byteArray = new Uint8Array(binaryStr);
        const str = byteArrayToBase64(window, byteArray);
        onSuccess(file.name, str)
      } else {
        return;
      }
    }
    reader.readAsArrayBuffer(file)
  }

  return <div className={'main-width1200 pt-[128px] pb-[48px] lg:pb-[72px] items-center h-full'}>
    <div className={'flex gap-[18px] h-[740px]'}>
      <div className={'flex-col gap-[20px]'}>
        <div className={'flex whiteShadowCard px-[24.7px] py-[12.25px] gap-[17.7px]'}>
          <img src={'/images/search_black.svg'}/>
          <input placeholder={getTranslation('mesajlarda-ara')}/>
        </div>

        <div className={'whiteShadowCard flex-col h-full overflow-hidden'}>
          <div className={'purple-y-scroll'}>
            {messageRooms?.map((room, i) => {
              const otherUser = room.users.find(u => u.keyId != mainContext?.userData?.id)
              return <div role={'button'} style={(i > 0 && i < messageRooms?.length - 1)
                ? {borderTop: '1px solid #F6F6FF', borderBottom: '1px solid #F6F6FF'} :
                i == 0 ? {borderBottom: '1px solid #F6F6FF'} :
                  i == messageRooms?.length - 1 ? {borderTop: '1px solid #F6F6FF'} : {}}
                          className={`pl-[20px] pr-[16px] py-[16px] flex gap-[8px] items-center relative ${(selectedRoom?.roomKeyId ?? '') == room.roomKeyId ? 'div-lightest-purple' : ''}`}
                          onClick={() => {
                            if (sendingMessage) {
                              return
                            }
                            getMessageRooms(room)
                          }}
              >
                <div className={'absolute top-[10px] right-[10px]'}><span className={'message-date-overview'}></span>
                </div>
                {(room.messages.length > 0 && room.messages[0].user) ?
                  <img className={'w-[67px] h-[67px] rounded-full bg-gray-300'} src={room.messages[0].user.photo}/>
                  : <div className={'w-[67px] h-[67px] rounded-full bg-gray-300'}></div>
                }
                {room.messages.length > 0 &&
                    <div className={'flex-col'}>
                      {otherUser && otherUser.name &&
                          <span className={'message-overview'}>{otherUser.name}</span>
                      }
                        <div className={'flex gap-[4px]'}>{
                          (room.messages[0].reads.length > 0) &&
                            <div className={'w-[18px] flex justify-end'}>
                                <img
                                    src={(room.messages[0].reads.find(r => (r.user?.type && mainContext?.userData?.userType && r.user?.type != mainContext?.userData?.userType)) != undefined
                                      ? '/images/messageRead.svg' : '/images/messageUnread.svg')}/>
                            </div>
                        }
                            <span className={'message-overview'}>{room.messages[0].message}</span>
                        </div>
                    </div>
                }
              </div>
            })}
          </div>
        </div>

      </div>
      <div className={`flex-1 whiteShadowCard`}>
        {messageRooms == undefined || messageRooms.length == 0 &&
            <div className={'berk flex flex-col justify-center items-center w-full h-full text-20-24-700 text-black'}>
              {getTranslation('mesaj-yok')}
            </div>}
        {(selectedRoom == undefined && userId == undefined) ? <></> :
          <div className={'h-full w-full flex-col py-[32px]'}>
            {selectedRoom &&
                <div className={'flex px-[32px] text-16-24-600 text-black'}>
                  {selectedRoom.users.find(u => u.type != mainContext?.userData?.userType)?.name}
                </div>
            }

            {(messages == undefined && userId == undefined) ?
              <div className={'h-full w-full flex items-center justify-center'}>
                <CLoadingAnimation/>
              </div> :
              <div className={'relative h-full w-full'}>
                {requestingMessages &&
                    <div className={'absolute w-full h-full'}>
                        <CLoadingAnimation/>
                    </div>
                }
                <div className={'flex-col h-full w-full'}>
                  <div id={'messages'}
                       className={'flex-1 py-[12px] pl-[32px] pr-[16px] w-full flex-col purple-y-scroll'}
                       ref={messagesRef} onScroll={onScroll}>
                    {(selectedRoom && messages) && <>
                      {(loadingMessages == LoadingMessagesState.Loading) && <CLoadingAnimation size={48}/>}
                      {messages.map((message, i) => {
                          return renderMessage(message, i == 0 ? undefined : messages[i - 1]);
                        }
                      )}
                      {newMessages && newMessages.map((message, i) => {
                          return renderMessage(message, i == 0 ? messages[messages.length - 1] : newMessages[i - 1])
                        }
                      )}
                    </>}
                  </div>
                  <div className={'h-[168px] w-full py-[20px] px-[34px] flex gap-[34px]'}
                       style={{borderTop: '2px solid #F6F6FF'}}>
                <textarea placeholder={getTranslation('mesaj-yaz')}
                          className={'purple-y-scroll standardInput rounded-0 !pl-0 !border-none !h-[128px]'}
                          value={newMessage}
                          onChange={(e) => {
                            let newVal = e.target.value;
                            if (newVal.length < 256) {
                              setNewMessage(newVal)
                            }
                          }}/>
                    <div>
                      <ModalButton className={'div-green !px-[48px]'} loading={sendingMessage}
                                   disabled={sendingMessage || newMessage.length < 1}
                                   text={mainContext?.getTranslation('common', 'gonder') ?? ''} onClick={() => {
                        if (selectedRoom == undefined) {
                          if (newMessage.length > 0) {
                            createMessageRoom()
                          }
                        } else {
                          sendMessage()
                        }
                      }}/>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        }
      </div>
      {selectedRoom != undefined &&
          <div className={'div-light-purple rounded-[16px] px-[22px] py-[25px] flex-col gap-[35px] items-center'}>
              <div className={'flex-col items-center gap-[12px]'}>
                  <img className={'w-[67px] h-[67px] rounded-full bg-gray-300'}
                       src={selectedRoom.users.find(u => u.type != mainContext?.userData?.userType)?.photo}/>
                  <p className={'text-16-24-600 text-dark-grey'}>{selectedRoom.users.find(u => u.type != mainContext?.userData?.userType)?.name}</p>
              </div>
              <div className={'flex-col gap-[24px]'}>
                  <ModalButton className={'min-h-[32px] div-purple !px-[20px] !py-[6px] flex items-center'}
                               text={mainContext?.getTranslation('menu', 'revize-talep') ?? ''}
                               loading={false} disabled={false}
                               onClick={() => {
                                 setReviseModal(true)
                               }}
                               leadingComponent={<>
                                 <div className={'w-[22px]'}>
                                   <img src={'/images/revise.svg'}/>
                                 </div>
                                 <div className={'w-[8px]'}/>
                               </>}
                  />
                  <ModalButton leadingComponent={<>
                    <div className={'w-[22px] flex justify-center'}>
                      <img style={{transform: 'rotate(90deg)'}} className={'w-[10px]'}
                           src={'/images/WhiteLeftArrow.svg'}/>
                    </div>
                    <div className={'w-[8px]'}/>
                  </>}
                               className={'min-h-[32px] div-green !px-[20px] !py-[6px] flex items-center'}
                               loading={false}
                               onClick={() => setFileUploadModal(true)} disabled={false}
                               text={mainContext?.getTranslation('common', 'dosya-yukle') ?? ''}
                  />
              </div>
          </div>
      }
    </div>
    {/*revize*/}
    <BModal isOpen={reviseModal} onClose={() => setReviseModal(false)}
            title={mainContext?.getTranslation('menu', 'revize-talebi')}
            saveDisabled={tempOption == undefined}
            requesting={reviseRequesting}
            onSave={createRevise} children={<>
      <CSelectWithSearchForFilterSingle options={projectOptions}
                                        val={tempOption}
        //@ts-ignore
                                        onChange={setTempOption}/>
    </>}/>

    {/*dosya yukle*/}
    <BModal isOpen={fileUploadModal} onClose={() => setFileUploadModal(false)}
            title={mainContext?.getTranslation('common', 'dosya-yukle')}
            saveDisabled={tempOption == undefined || acceptedFiles.length == 0 || afiles.length == 0}
            requesting={requesting}
            onSave={onFileUpload} children={<div className={'flex-col gap-[24px]'}>
      <CSelectWithSearchForFilterSingle options={projectOptions}
                                        val={tempOption}
        //@ts-ignore
                                        onChange={setTempOption}/>
      <div className={'flex-1 flex-col'}>
        <div className={'modalTitle pb-[16px]'}>{getTranslation('projeni-buraya-yukle')}</div>
        <div {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
          {files.length > 0 ?
            <div className={'flex-col'}>
              <div className={'flex-1'}>
                <ul><p>{files}</p></ul>
              </div>
              <div className={'flex justify-between'}>
                <p role={'button'} onClick={(e) => {
                }}><strong>{mainContext?.getTranslation('common', 'guncelle')}</strong></p>
                <p role={'button'} onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setAFiles([])
                }}><strong>{mainContext?.getTranslation('common', 'kaldir')}</strong></p>
              </div>
            </div>
            :
            <p>
              <strong>{mainContext?.getTranslation('common', 'dosya-sec')}</strong>&nbsp;{mainContext?.getTranslation('common', 'yada-dosya-surukle')}
            </p>
          }
        </div>

      </div>
    </div>}/>

  </div>
}
