import { useContext } from "react";
import { MainContext } from "src/context/MainContext";
import { parseImageUrl } from "src/utils/request";
import CPurpleTextArrow from "./CPurpleTextArrow";

function CWhyMoveToPofft(translation: any) {
  const mainContext = useContext(MainContext);

  function getTranslation(parent: string, child: string): string {
    return translation.translation.get(parent)?.sentences.get(child) ?? "";
  }

  return (
    <div className="flex-col lg:flex-row gap-[24px] lg:gap-[88px]">
      <div className="flex-col flex-1 lg:w-[50%]">
        <div className="pt-[16px] text-24-40-500_32-40-500 text-black w-[640px] m-auto lg:text-start w-full">
          {getTranslation("isini-poffta-tasi", "text1")}
        </div>
        <div className="pt-[16px] text-16-24-400 text-grey w-[640px] m-auto lg:text-start w-full">
          {getTranslation("isini-poffta-tasi", "text2")}
        </div>
        <div
          className="pt-[24px] lg:pt-[55px] pb-[8px] lg:pb-[12px] w-full"
          role="button"
          onClick={() => {
            window.location.href=(  "/uzman");
          }}
        >
          <CPurpleTextArrow
            text={mainContext?.getTranslation("common", "pofft-uzman") ?? ""}
          />
        </div>
        <div
          className="py-[8px] lg:py-[12px] w-full"
          role="button"
          onClick={() => {
            window.location.href=( "/sirket");
          }}
        >
          <CPurpleTextArrow
            text={mainContext?.getTranslation("common", "pofft-sirket") ?? ""}
          />
        </div>
        <div
          className="py-[8px] lg:py-[12px] w-full"
          role="button"
          onClick={() => {
            window.location.href=( "/partner");
          }}
        >
          <CPurpleTextArrow
            text={mainContext?.getTranslation("common", "pofft-partner") ?? ""}
          />
        </div>
      </div>
      <div className="flex-1 self-center">
        <div className="shadow-image" style={{ width: "unset" }}>
          <img
            src={parseImageUrl("isini-neden-poffta-tasimalisin.png")}
            style={{
              filter: "drop-shadow(0px 20px 80px rgba(80, 66, 17, 0.13))",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default CWhyMoveToPofft;
