import {Box, Modal} from "@mui/material";
import './Filter.scss'
import {useContext} from "react";
import {MainContext} from "../../context/MainContext";
import CLoadingAnimation from "../CLoadingAnimation";

interface IProps {
  title: string;
  header: string;
  desc?: string
  isOpen: boolean
  onClose: () => void
  onSave: () => void
  children: React.ReactElement
  saveDisabled?: boolean
  closeDisabled?: boolean
  customHeaderClass?: string
  requesting?: boolean
  error?: string
  customButtons?: React.ReactElement
}

export function FilterModal({
                              title,
                              header,
                              desc,
                              isOpen,
                              onClose,
                              onSave,
                              saveDisabled,
                              closeDisabled,
                              children,
                              customHeaderClass,
                              requesting,
                              error,
                              customButtons
                            }: IProps) {

  const mainContext = useContext(MainContext);

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '16px',
    minWidth: '700px',
    maxHeight: '90vh',
    display:'flex',
    flexDirection:'column',
  };

  return <Modal open={isOpen} onClose={onClose} disableAutoFocus={true}>
    <Box sx={style}>
      <div className={'filterModal'}>
        <div className={'title'}>
          <span>{title}</span>
          <img onClick={onClose} src={'/images/closeModal.svg'}/>
        </div>
        <div className={'pt-[20px] pb-[48px] flex flex-col items-start ' + customHeaderClass ?? ''}>
          <div className={'header'}>{header}</div>
          {desc && <div className={'desc'} dangerouslySetInnerHTML={{__html:desc}}></div>}
        </div>
        {children}
        <div className={'flex-col pt-[8px]'}>
          <div className={'h-[52px] flex items-center'}>
            {error && <div className="text-red-400 text-sm">{error}</div>}
          </div>
          {customButtons ??
              <div className={'flex justify-end gap-[12px]'}>
                  <div role={'button'}
                       className={'button cancel' + ((saveDisabled != undefined && saveDisabled == true) ? ' disabledBtn' : '')}
                       onClick={onClose}>{mainContext!.getTranslation('common', 'iptal')}</div>
                {requesting === true ?
                  <div className={'w-[96px]'}>
                    <CLoadingAnimation size={40}/>
                  </div>
                  :
                  <div role={'button'}
                       className={'button save' + ((saveDisabled != undefined && saveDisabled == true) ? ' disabledBtn' : '')}
                       style={requesting ? {backgroundColor: '#ccc'} : {}}
                       onClick={onSave}>{mainContext!.getTranslation('common', 'kaydet')}</div>
                }
              </div>
          }
        </div>
      </div>
    </Box>
  </Modal>
}
