import {FilterModal} from "../Filter/FilterModal";
import {useContext, useEffect, useState} from "react";
import {MainContext} from "../../context/MainContext";
import {postV2} from "../../utils/request";
import {CompanyProfileData, deepCopyCompany} from "../../types/TCompanyData";
import {parseCompanyDataRequest} from "../../service/companyRequest";
import {TError} from "../../types/TError";
import {NotificationType} from "../Toast/Toast";

interface IProps {
  getTranslation: (key: string) => string
  isOpen: boolean
  close: () => void
  update: (newCompany: CompanyProfileData) => void
  company?: CompanyProfileData
}

export function CAboutEditModalCompany({getTranslation, isOpen, close, company, update}: IProps) {
  const mainContext = useContext(MainContext)
  const [tempCompany, setTempCompany] = useState(company)
  const [isUpdating, setIsUpdating] = useState(false)


  useEffect(() => {
    if (isOpen && company) {
      setTempCompany(deepCopyCompany(company))
    } else {
      setTempCompany(undefined)
    }
  }, [isOpen])

  function save() {
    if (mainContext?.isExpired('abouteditcompany')) {
      return
    }
    if ((tempCompany?.name ?? '').length < 3 || (tempCompany?.about ?? '').length < 3) {
      return
    }
    setIsUpdating(true)
    const companyDataParsed = parseCompanyDataRequest(tempCompany!)
    postV2('/Company/update', companyDataParsed).then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateCompanyInfo')
      } else {
        mainContext?.showToast(NotificationType.Success, 'updateCompanyInfo')
        update(tempCompany!)
      }
    }).catch((e) => {
      mainContext?.showToast(NotificationType.Fail, 'updateCompanyInfo')
    }).finally(() => {
      setIsUpdating(false)
    })
  }

  return <FilterModal
    title={getTranslation('hakkimizda')}
    header={getTranslation('sirket-adi')}
    isOpen={isOpen}
    onClose={close}
    onSave={save}
    requesting={isUpdating}
    saveDisabled={(tempCompany?.name ?? '').length < 3 || (tempCompany?.about ?? '').length < 3 || isUpdating}
    customHeaderClass={'!pb-[20px]'}
  >
    <div>
      <div className={'w-full'}>
        <input placeholder={mainContext?.getTranslation('common', 'giriniz')}
               className={'standardInput !color-[#6D6D6D]'}
               value={tempCompany?.name}
               onChange={(e) => {
                 let newVal = e.target.value;
                 if (newVal.length < 100) {
                   setTempCompany({...tempCompany!, name: newVal})
                 }
               }}
        />
      </div>
      <div className={'pt-[32px]'}>
        <div className={'modalTitle pb-[16px]'}>{getTranslation('sirketini-kisaca-tanit')}</div>
      </div>
      <textarea placeholder={getTranslation('kisaca-kendini-tanit-detay')} rows={5}
                value={tempCompany?.about}
                onChange={(e) => {
                  let newVal = e.target.value;
                  if (newVal.length < 4000) {
                    setTempCompany({...tempCompany!, about: newVal})
                  }
                }}
                className={'standardInput py-[12px] !h-[180px] !color-[#6D6D6D]'}
      />
    </div>
  </FilterModal>
}
