import {Box, Modal} from "@mui/material";
import styles from './Profile.module.scss'
import {useContext, useEffect, useState} from "react";
import {MainContext} from "../../context/MainContext";
import {getDiffDates, getDiffFromToday} from "../../utils/date";
import {mergeCss} from "../../utils/cssMerge";
import CSwiper3LoopMini from "../CSwiper3LoopAlternative";
import {TJob} from "../../types/TJob";

interface IProps {
  title: string
  data?: TJob,
  selectedMediaIndex?: number
  onClose: () => void
}

export function JobDetailModal({title, data, onClose, selectedMediaIndex}: IProps) {

  const mainContext = useContext(MainContext)

  const [mediaIndex, setMediaIndex] = useState(selectedMediaIndex ?? -2)

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '90%',
    maxWidth: '604px',
    maxHeight: '85%',
    bgColor: "#fff",
    border: "none",
    borderRadius: "20px",
    overflowY: 'hidden',
    display: 'flex',
    outline: 'none'
  };

  useEffect(() => {
    if ((selectedMediaIndex ?? -1) > -1) {
      setMediaIndex(selectedMediaIndex ?? -1);
    }
  }, [selectedMediaIndex]);

  return <Modal
    open={data != undefined}
    onClose={() => {
      onClose();
    }}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box className={'place-content-center'} sx={style}>
      <div
        className={mergeCss(['flex-col bg-white py-[29px] px-[36px] rounded-[16px] overflow-x-hidden', styles.content])}>
        <div className={'flex justify-between pb-[12px]'}>
          <h1>{title}</h1>
          <img role={'button'} onClick={onClose} src={'/images/close.svg'}/>
        </div>
        <div className={'dividerHorizontal'}/>
        <div className={'flex justify-between items-start pt-[16px]'}>
          <div className={'flex-col'}>
            <h1>{data?.title}</h1>
            <h2>{data?.firm}&nbsp;
              <div className={'purpleDot'}/>
              &nbsp;{data?.workType}</h2>
            <h3>
              {data?.startDate ?? '-'}
              &nbsp;
              <div className={'purpleDot'}/>
              &nbsp;{(data?.endDate && data?.endDate!='-') ? data?.endDate : mainContext?.getTranslation('common', 'halen')}
              &nbsp;
              {(data?.startDate && data?.startDate.length > 1) &&
                  <>
                      <div className={'purpleDot'}/>
                      &nbsp;{(data?.startDateObj) && ((data.endDateObj && getDiffDates(data.endDateObj,new Date())<0) ? getDiffDates(data?.endDateObj, data?.startDateObj) : getDiffFromToday(data?.startDateObj))}&nbsp;{mainContext?.getTranslation('common', 'gundur')}
                  </>
              }
            </h3>
          </div>
          <div className={'flex gap-[8px]'}>
            <img src={'/images/Location.svg'}/>
            <p>{data?.location}</p>
          </div>
        </div>
        <div className={'flex-col w-full overflow-x-hidden'}>
          <h4>{mainContext?.getTranslation('common', 'aciklama')}</h4>
          <p className={styles.description}>{data?.description}</p>

          <div className={'flex-col'}>
            {mediaIndex > -1 &&
                <div className={'py-[32px] rounded-[16px] flex justify-center'}>
                    <img className={'max-h-[290px]'} src={data?.medias?.[mediaIndex] ?? ''}/>
                </div>
            }
            <div className={'w-full'}>
              {(data?.medias && data?.medias.length > 0) &&
                  <CSwiper3LoopMini
                      notActiveEffect={true}
                      swipeAmount={3}
                      data={{
                        cards: data.medias.map((item, i) => {
                          return (
                            <div className="flex-col justify-center h-full" onClick={() => {
                              setMediaIndex(i)
                            }}>
                              <img src={item}/>
                            </div>
                          );
                        }),
                      }}
                  />
              }
            </div>
          </div>


          {/*{data?.medias &&*/}
          {/*    <div className={'flex-col '}>*/}
          {/*      {mediaIndex > -1 &&*/}
          {/*          <div className={'py-[32px] rounded-[16px] flex justify-center'}>*/}
          {/*              <img className={'max-h-[290px]'} src={data.medias[mediaIndex] ?? ''}/>*/}
          {/*          </div>*/}
          {/*      }*/}
          {/*        <div className={'overflow-x-scroll flex gap-[12px]'}>*/}
          {/*          {*/}
          {/*            data?.medias.map((m, i) => <img role={'button'} onClick={() => {*/}
          {/*              setMediaIndex(i)*/}
          {/*            }} className={'h-[92px]'} src={m}/>)*/}
          {/*          }*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*}*/}
        </div>
      </div>
    </Box>
  </Modal>
}
