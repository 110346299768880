import {FilterConfig} from "./TFilter";
import {currencies} from "../service/commonRequest";
import {dateStrToString} from "../utils/date";

export interface TPaymentPlan {
  id: number,
  projectOfferID: number,
  installmentNo: number,
  amount: number,
  currency?: FilterConfig
  paymentDateObj?: Date,
  paymentDateStr?: string,
  isPaid: boolean
}

export function parsePaymentPlans(data: any): TPaymentPlan[] {
  return data.map((item: any) => {
    let paymentDateStr = item.paymentDate ? dateStrToString(item.paymentDate) : '-';
    let paymentDateObj = item.paymentDate ? new Date(item.paymentDate) : undefined;
    let model = {
      id: item.id,
      projectOfferId: item.projectOfferID,
      installmentNo: item.installmentNo,
      amount: item.amount,
      currency: item.currencyID != undefined ? currencies.find(c => c.id == item.currencyID) : undefined,
      paymentDateObj: paymentDateObj,
      paymentDateStr: paymentDateStr,
      isPaid: item.status == 1
    };
    return model
  })
}
