import {ProjectOfferUpdate} from "./TJob";

export interface TProjectOffer {
  offerKeyId: string
  professionalKeyId: string
  professionalName: string
  professionalProfession?: string
  professionalPicture?: string
  price: number
  companyRequestPrice: number
  currencyId: number
  currencyStr: string
  visible: boolean
  status: string
  statusEnum: ProjectOfferUpdate
  statusStr: string
  // remark: string
  companyRemark: string
  professionalRemark: string
}

export function parseCompanyJobOfferResponse(item: any): TProjectOffer {
  return {
    offerKeyId: item.projectOfferKeyID,
    professionalKeyId: item.professinalKeyID,
    professionalName: item.professionalStr,
    professionalPicture: item.professionalProfilePhoto,
    professionalProfession: item.professionalFirstProfession,
    price: item.price,
    companyRequestPrice: item.companyRequestPrice,
    currencyId: item.currencyID,
    currencyStr: item.currencyStr,
    visible: item.visible,
    status: item.status,
    statusEnum: parseStatusToEnum(item.status),
    statusStr: item.statusStr,
    // remark: item.remark,
    companyRemark: item.companyRemark,
    professionalRemark: item.professionalRemark
  };
}
export function parseCompanyJobOffersResponse(data: any): TProjectOffer[] {
  return data.map((item: any) => {
      return parseCompanyJobOfferResponse(item);
    }
  )
}

function parseStatusToEnum(str: string): ProjectOfferUpdate {
  for (let e in ProjectOfferUpdate) {
    if (ProjectOfferUpdate[e] == str) {
      let pou = parseInt(e) as unknown as ProjectOfferUpdate;
      return pou
    }
  }
  return ProjectOfferUpdate.Waiting
}


