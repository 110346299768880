import {ChangeEvent, useContext, useEffect, useRef, useState} from "react";
import {MainContext} from "../../context/MainContext";
import {getV2, postV2} from "../../utils/request";
import {EnumProfileUpdateType} from "../../types/EnumProfileUpdateType";
import {ProfessionalProfileData} from "../../service/profileRequest";
import {Box, IconButton, InputAdornment, Modal, TextField} from "@mui/material";
import {VisibilityOffOutlined, VisibilityOutlined} from "@mui/icons-material";
import {passRegex} from "../../types/Regex";
import CLoadingAnimation from "../CLoadingAnimation";
import {getFile} from "../../utils/file";
import {TError} from "../../types/TError";
import {NotificationType} from "../Toast/Toast";

interface IProps {
  getTranslation: (parent: string, child: string) => string
  isOpen: boolean
  close: () => void
  updated: (callback?: any) => void
  data: ProfessionalProfileData
}

export function CUserEditModal({getTranslation, isOpen, close, updated, data}: IProps) {
  const mainContext = useContext(MainContext)

  const [isUpdatingPhoto, setIsUpdatingPhoto] = useState(false)
  const [isUpdatingPass, setIsUpdatingPass] = useState(false)
  const [isUpdatingLocation, setIsUpdatingLocation] = useState(false)
  const [tempData, setTempData] = useState(data)

  useEffect(() => {
    if (isOpen) {
      setTempData(data)
      setLocation(data.address)
    }
  }, [data, isOpen]);

  function updatePass() {
    if (mainContext?.isExpired('useredit')) {
      return
    }
    setIsUpdatingPass(true);
    postV2('/Login/changePassword', {
      'oldPassword': pass,
      'password': pass1,
      'passwordAgain': pass2,
    }).then(resp => {
      setIsUpdatingPass(false);
      if (resp instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
      } else {
        mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
        setOldpassError(undefined);
        setPass1Error(undefined);
        setPass2Error(undefined);
        setPass('')
        setPass1('')
        setPass2('')
      }
    })
  }

  function updateLocation() {
    if (mainContext?.isExpired('useredit2')) {
      return
    }
    setIsUpdatingLocation(true)
    postV2('/Professional/partialupdate', {
      updateType: EnumProfileUpdateType.Address,
      Adress: location,
    }).then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
      } else {
        mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
        updated()
      }
    }).catch((e) => {
      mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
    }).finally(() => {
      setIsUpdatingLocation(false)
    })
  }

  function deleteOldPhotos() {
    if (data.allProfilePhotos && data.allProfilePhotos.length > 0) {
      data.allProfilePhotos.forEach(p => {
        getV2('/ProfessionalFile/deletefile?fileKey=' + p.key)
      })
    }
  }

  function updatePhoto() {
    if (mainContext?.isExpired('updatephoto')) {
      return
    }

    if (photoFile == undefined || photoFile.length == 0) {
      return;
    }
    setIsUpdatingPhoto(true)
    getFile(
      photoFile[0],
      (filename, fileData) => {
        postV2('/ProfessionalFile/createfile', {
          type: EnumProfileUpdateType.ProfilePhoto,
          fileName: filename,
          fileContents: fileData
        }).then(r => {
          setIsUpdatingPhoto(false);
          if (r instanceof TError) {
            mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
          } else {
            mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
            deleteOldPhotos()
            updated((newData: ProfessionalProfileData) => {
              if (newData.profilePhoto) {
                const oldData = JSON.parse(localStorage.getItem('userData') ?? '{}')
                oldData.picture = newData.profilePhoto.url
                localStorage.setItem('userData', JSON.stringify(oldData))
                if (mainContext?.userData) {
                  mainContext.setUserData({...mainContext.userData, picture: newData.profilePhoto.url})
                }
              }
            })
          }
        }).catch((e) => {
          setIsUpdatingPhoto(false);
          mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
        });
      },
      (error) => {
        setIsUpdatingPhoto(false);
        mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
      }, mainContext
    )
  }

  const [pass, setPass] = useState("");
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [oldpassError, setOldpassError] = useState<string | undefined>(undefined);
  const [pass1Error, setPass1Error] = useState<string | undefined>(undefined);
  const [pass2Error, setPass2Error] = useState<string | undefined>(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [location, setLocation] = useState<string | undefined>(undefined)
  const [photoFile, setPhotoFile] = useState<undefined | FileList>(undefined)

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  useEffect(() => {
    var noErrorFound = true;

    if (pass1.length == 0) {
      setPass1Error(undefined);
    } else {
      if (pass1.length < 8) {
        setPass1Error(
          mainContext!.getTranslation("giris-kayit", "enaz-8karakter")
        );
        noErrorFound = false;
      } else {
        if (passRegex.test(pass1)) {
          setPass1Error(undefined);
        } else {
          setPass1Error(
            mainContext!.getTranslation("giris-kayit", "sifre-ozel-karakter")
          );
          noErrorFound = false;
        }
      }
    }

    if (pass2.length == 0) {
      setPass2Error(undefined);
    } else {
      if (pass2.length < 8) {
        setPass2Error(
          mainContext!.getTranslation("giris-kayit", "enaz-8karakter")
        );
        noErrorFound = false;
      } else {
        if (passRegex.test(pass2)) {
          setPass2Error(undefined);
        } else {
          setPass2Error(
            mainContext!.getTranslation("giris-kayit", "sifre-ozel-karakter")
          );
          noErrorFound = false;
        }
      }
    }

    if (noErrorFound && pass2 != pass1) {
      setPass2Error("Şifreleriniz uyuşmamaktadır");
    }
  }, [pass1, pass2]);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '16px',
    minWidth: '700px'
  };

  const updatePassDisabled = pass.length < 7 || pass1.length < 7 || pass2.length < 7 || pass1Error != undefined || pass2Error != undefined || oldpassError != undefined;


  const newpass1Error = (pass1.length > 0 && pass2.length > 0 && pass1 != pass2) ? mainContext?.getTranslation('common', 'yeni-sifre-uyusmuyor') :
    (pass1.length == 0 && pass2.length > 0) ? mainContext?.getTranslation('common', 'alan-bos-birakilamaz') : pass1Error
  const newpass2Error = (pass1.length > 0 && pass2.length > 0 && pass1 != pass2) ? mainContext?.getTranslation('common', 'yeni-sifre-uyusmuyor') :
    (pass2.length == 0 && pass1.length > 0) ? mainContext?.getTranslation('common', 'alan-bos-birakilamaz') : pass2Error

  const inputFileRef = useRef(null);
  const onFileChangeCapture = (e: ChangeEvent<HTMLInputElement>) => {
    /*Selected files data can be collected here.*/
    let files = e.target.files;
    console.log(files);
    if (FileReader && files && files.length) {
      setPhotoFile(files)
      var fr = new FileReader();
      fr.onload = function () {

        let ppImage = document.getElementById('ppImage');
        if (ppImage != null) {
          //@ts-ignore
          ppImage.src = fr.result;
        }
      }
      fr.readAsDataURL(files[0]);
    }
  };
  const onPhotoClick = () => {
    //@ts-ignore
    inputFileRef.current?.click();
  };

  return <Modal open={isOpen} onClose={close} disableAutoFocus={true}>
    <Box sx={style}>
      <div className={'filterModal max-h-[85vh]'}>
        <input
          style={{display: 'none'}}
          type="file"
          ref={inputFileRef}
          onChangeCapture={onFileChangeCapture}
        />
        <div className={'title'}>
          <span>{mainContext?.getTranslation('profile', 'profil-ayarlari')}</span>
          <img onClick={close} src={'/images/closeModal.svg'}/>
        </div>
        <div className={'flex-col y-scroll'}>
          <div className={'w-full flex justify-between pt-[40px]'}>
            <div className={'flex-col gap-[24px] items-center'}>
              <p
                className={'text-18-24-600 whitespace-nowrap'}>{getTranslation('profile', (data.profilePhoto?.url ?? '').length > 0 ? 'profil-foto-duzenle' : 'profil-foto-ekle')}</p>
              <img
                id={'ppImage'}
                className="w-[154px] h-[154px] rounded-full bg-gray-300"
                src={tempData.profilePhoto?.url}
              />
            </div>
            <div className={'flex items-center'}>
              <div className={'flex-col gap-[24px]'}>
                <p
                  className={'text-18-24-600 text-gray-500 whitespace-nowrap'}>{mainContext?.getTranslation('profile', 'fotografini-yukleyebilirsin')}</p>
                {/*{photoError && <div className="text-red-400 text-sm">{photoError}</div>}*/}
                <div className={'flex gap-[12px]'}>
                  <div role={'button'} className={'button cancel'}
                       onClick={onPhotoClick}>{mainContext?.getTranslation('profile', 'fotografi-degistir')}</div>
                  <div role={`button`}
                       className={`button save ${photoFile == undefined ? 'disabledBtn' : isUpdatingPhoto ? 'loadingButton relative' : ''}`}
                       onClick={updatePhoto}>
                    {isUpdatingPhoto &&
                        <div className={'absolutetop0'}>
                            <CLoadingAnimation size={32}/>
                        </div>
                    }
                    <p className={(isUpdatingPhoto ? ' opacity-0' : '')}>
                      {mainContext?.getTranslation('profile', 'fotografi-kaydet')}
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className={'title pt-[75px]'}>
            <span>{mainContext?.getTranslation('profile', 'sifremi-degistir')}</span>
          </div>
          <div className={'flex-col pt-[28px] gap-[21px]'}>
            <p className={'text-14-24-700 text-grey'}>{mainContext?.getTranslation('profile', 'eski-sifre')}</p>
            <TextField
              id="outlined-basic"
              label={mainContext?.getTranslation("profile", "eski-sifre-giriniz")}
              variant="outlined"
              className="basicInput !w-full"
              value={pass}
              onChange={(e) => {
                setPass(e.target.value);
              }}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffOutlined/>
                      ) : (
                        <VisibilityOutlined/>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={'flex-col pt-[28px] gap-[21px]'}>
            <p className={'text-14-24-700 text-grey'}>{mainContext?.getTranslation('profile', 'yeni-sifre')}</p>
            <TextField
              id="outlined-basic"
              label={mainContext?.getTranslation("profile", "yeni-sifre-giriniz")}
              variant="outlined"
              className="basicInput !w-full"
              value={pass1}
              onChange={(e) => {
                setPass1(e.target.value);
              }}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffOutlined/>
                      ) : (
                        <VisibilityOutlined/>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {newpass1Error && <div className="text-red-400 text-sm">{newpass1Error}</div>}
          </div>
          <div className={'flex-col pt-[28px] gap-[21px]'}>
            <p className={'text-14-24-700 text-grey'}>{mainContext?.getTranslation('profile', 'yeni-sifre-tekrar')}</p>
            <TextField
              id="outlined-basic"
              label={mainContext?.getTranslation("profile", "yeni-sifre-giriniz")}
              variant="outlined"
              className="basicInput !w-full"
              value={pass2}
              onChange={(e) => {
                setPass2(e.target.value);
              }}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffOutlined/>
                      ) : (
                        <VisibilityOutlined/>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {newpass2Error && <div className="text-red-400 text-sm">{newpass2Error}</div>}
          </div>
          <div className={'w-[96px] self-end mt-[50px]'}>
            {/*{passError && <div className="text-red-400 text-sm py-[16px]">{passError}</div>}*/}
            {isUpdatingPass === true ?
              <CLoadingAnimation size={40}/>
              :
              <div role={'button'}
                   className={'button save' + ((updatePassDisabled != undefined && updatePassDisabled == true) ? ' disabledBtn' : '')}
                   style={isUpdatingPass ? {backgroundColor: '#ccc'} : {}}
                   onClick={updatePass}>{mainContext!.getTranslation('common', 'kaydet')}</div>
            }
          </div>
          <div className={'title pt-[25px]'}>
            <span>{mainContext?.getTranslation('profile', 'adres')}</span>
          </div>
          <div className={'flex-col pt-[28px] gap-[21px]'}>
            <TextField
              id="outlined-basic"
              label={mainContext?.getTranslation("profile", "adres-giriniz")}
              variant="outlined"
              className="basicInput !w-full"
              value={location}
              onChange={(e) => {
                setLocation(e.target.value);
              }}
              type="text"
            />
          </div>
          <div className={'w-[96px] self-end mt-[50px]'}>
            {isUpdatingLocation === true ?
              <CLoadingAnimation size={40}/>
              :
              <div role={'button'}
                   className={'button save' + ((location == undefined || location.length < 3) ? ' disabledBtn' : '')}
                   style={isUpdatingLocation ? {backgroundColor: '#ccc'} : {}}
                   onClick={updateLocation}>{mainContext!.getTranslation('common', 'kaydet')}</div>
            }
          </div>
        </div>
      </div>
    </Box>
  </Modal>
}
