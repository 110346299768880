import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {companyNameRegex, passRegex} from "src/types/Regex";
import { absoluteCenter } from "src/utils/window";
import { MainContext } from "../context/MainContext";
import { optionalNameRegex } from "../types/Regex";
import { CCheckbox } from "./CCheckbox";
import CLoadingAnimation from "./CLoadingAnimation";
import { InfoText, RegisterType } from "./CSignInUp";
import { CSignInUpSwitcher } from "./CSignInUpSwitcher";
import { CAgreementTextPopup } from "./CAgreementTextPopup";

export interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onDecline: () => void;
  onBack: () => void;
  signUp: (
    firstname: string,
    lastname: string,
    pass: string,
    smsPermission: boolean,
    callPermission: boolean,
    emailPermission: boolean,
    companyName?: string
  ) => Promise<void>;
  registerType: RegisterType;
  changeRegisterType: (type: RegisterType) => void;
}

export function CSignupDetailPopup({
  isOpen,
  onClose,
  onDecline,
  onBack,
  signUp,
  registerType,
  changeRegisterType,
}: IProps) {
  const mainContext = useContext(MainContext);

  const [requesting, setRequesting] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [ticariiletiCheck, setTicariiletiCheck] = useState(false);

  const [withSms, setWithSms] = useState(false);
  const [withPhone, setWithPhone] = useState(false);
  const [withEmail, setWithEmail] = useState(false);

  const [companyNameError, setCompanyNameError] = useState<string | undefined>(
    ""
  );
  const [firstnameError, setFirstnameError] = useState<string | undefined>(
    undefined
  );
  const [lastnameError, setLastnameError] = useState<string | undefined>(
    undefined
  );
  const [pass1Error, setPass1Error] = useState<string | undefined>(undefined);
  const [pass2Error, setPass2Error] = useState<string | undefined>(undefined);
  const [infoText, setInfoText] = useState<undefined | InfoText>(undefined);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (firstname.length == 0) {
      setFirstnameError(undefined);
    } else {
      optionalNameRegex.test(firstname)
        ? setFirstnameError(undefined)
        : setFirstnameError(
            mainContext!.getTranslation("giris-kayit", "gecersiz-ad")
          );
    }
  }, [firstname]);

  useEffect(() => {
    if (lastname.length == 0) {
      setLastnameError(undefined);
    } else {
      optionalNameRegex.test(lastname)
        ? setLastnameError(undefined)
        : setLastnameError(
            mainContext!.getTranslation("giris-kayit", "gecersiz-soyad")
          );
    }
  }, [lastname]);

  useEffect(() => {
    if (companyName.length == 0) {
      setCompanyNameError(undefined);
    } else {
      companyNameRegex.test(companyName)
        ? setCompanyNameError(undefined)
        : setCompanyNameError(
            mainContext!.getTranslation("giris-kayit", "gecersiz-sirketismi")
          );
    }
  }, [companyName]);

  useEffect(() => {
    var noErrorFound = true;

    if (pass1.length == 0) {
      setPass1Error(undefined);
    } else {
      if (pass1.length < 8) {
        setPass1Error(
          mainContext!.getTranslation("giris-kayit", "enaz-8karakter")
        );
        noErrorFound = false;
      } else {
        if (passRegex.test(pass1)) {
          setPass1Error(undefined);
        } else {
          setPass1Error(
            mainContext!.getTranslation("giris-kayit", "sifre-ozel-karakter")
          );
          noErrorFound = false;
        }
      }
    }

    if (pass2.length == 0) {
      setPass2Error(undefined);
    } else {
      if (pass2.length < 8) {
        setPass2Error(
          mainContext!.getTranslation("giris-kayit", "enaz-8karakter")
        );
        noErrorFound = false;
      } else {
        if (passRegex.test(pass2)) {
          setPass2Error(undefined);
        } else {
          setPass2Error(
            mainContext!.getTranslation("giris-kayit", "sifre-ozel-karakter")
          );
          noErrorFound = false;
        }
      }
    }

    if (noErrorFound && pass2 != pass1) {
      setPass2Error("Şifreleriniz uyuşmamaktadır");
    }
  }, [pass1, pass2]);

  const ticariIletiMetni: InfoText = {
    title: mainContext!.getTranslation("giris-kayit", "ticari-ileti"),
    text: mainContext!.getTranslation("giris-kayit", "ticari-ileti-metni"),
    buttonText: mainContext!.getTranslation(
      "giris-kayit",
      "okudum-onayliyorum"
    ),
    onAccept: () => {
      setTicariiletiCheck(true);
      setInfoText(undefined);
    },
    onClose: () => {
      console.log("onClose");
      setTicariiletiCheck((ticariIleti) => !ticariIleti);
      setInfoText(undefined);
    },
  };

  function canSignIn(): boolean {
    if (registerType == RegisterType.Company) {
      if (companyName.length == 0 || companyNameError != undefined) {
        return false;
      }
    }
    if (firstname.length == 0 || firstnameError != undefined) {
      return false;
    }
    if (lastname.length == 0 || lastnameError != undefined) {
      return false;
    }
    if (pass2.length < 8 || pass2Error != undefined) {
      return false;
    }
    if (ticariiletiCheck == false) {
      return false;
    }
    if (withEmail == false && withPhone == false && withSms == false) {
      return false;
    }

    return true;
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => onClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={absoluteCenter}>
        <div className="w-[360px] lg:w-[380px] p-[24px] bg-white rounded-[8px] flex-col justify-center items-center relative">
          <div className={'absolute left-[24px] top-[32px]'} onClick={onBack}><img src={'/images/leftarrow.svg'}/></div>
          <div className="text-32-40-500 text-black pb-[32px] text-center whitespace-break-spaces" dangerouslySetInnerHTML={{__html:
              registerType == RegisterType.Expert
                ? mainContext
                ?.getTranslation("giris-kayit", "uzman-olarak")
                .replaceAll("\\n", "\n") ?? ""
                : mainContext
                ?.getTranslation("giris-kayit", "sirket-olarak")
                .replaceAll("\\n", "\n") ?? ""
          }}>

          </div>

          {registerType == RegisterType.Expert ? (
            <>
              <p className="text-grey text-14-20-400">
                {mainContext?.getTranslation(
                  "giris-kayit",
                  "sirket-olarak-katil-oncesi"
                )}
              </p>
              <p
                className="text-purple text-14-20-400"
                onClick={() => {
                  changeRegisterType(RegisterType.Company);
                }} dangerouslySetInnerHTML={{__html:
                  mainContext?.getTranslation(
                    "giris-kayit",
                    "sirket-olarak-katil"
                  )??''
              }}
              />
            </>
          ) : (
            <>
              {mainContext?.getTranslation(
                "giris-kayit",
                "uzman-olarak-katil-oncesi"
              )}
              <p className="text-grey text-14-20-400">{}</p>
              <p
                className="text-purple text-14-20-400"
                onClick={() => {
                  changeRegisterType(RegisterType.Expert);
                }} dangerouslySetInnerHTML={{__html:mainContext?.getTranslation(
                  "giris-kayit",
                  "uzman-olarak-katil"
                )??''}}
              />
            </>
          )}

          {registerType == RegisterType.Company && (
            <div className="pt-[24px] flex w-full justify-between">
              <div
                className="w-[100%]"
                style={requesting ? { pointerEvents: "none" } : {}}
              >
                <TextField
                  id="outlined-basic"
                  label={mainContext?.getTranslation(
                    "common",
                    "input-sirketadi"
                  )}
                  variant="outlined"
                  className="basicInput !w-full"
                  value={companyName}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                />
                {companyNameError && (
                  <div className="text-red-400 text-12-14-500 pt-[8px] pl-[16px]">
                    {companyNameError}
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="pt-[24px] flex w-full justify-between pb-[26px]">
            <div className="signupHalfInput">
              <TextField
                id="outlined-basic"
                label={mainContext?.getTranslation("common", "input-ad")}
                variant="outlined"
                className="basicInput !w-full"
                value={firstname}
                onChange={(e) => {
                  setFirstname(e.target.value);
                }}
              />
              {firstnameError && (
                <div className="text-red-400 text-12-14-500 pt-[8px] pl-[16px]">
                  {firstnameError}
                </div>
              )}
            </div>
            <div className="signupHalfInput">
              <TextField
                id="outlined-basic"
                label={mainContext?.getTranslation("common", "input-soyad")}
                variant="outlined"
                className="basicInput !w-full"
                value={lastname}
                onChange={(e) => {
                  setLastname(e.target.value);
                }}
              />
              {lastnameError && (
                <div className="text-red-400 text-12-14-500 pt-[8px] pl-[16px]">
                  {lastnameError}
                </div>
              )}
            </div>
          </div>
          <div
            className="w-[100%] pb-[24px]"
            style={requesting ? { pointerEvents: "none" } : {}}
          >
            <TextField
              id="outlined-basic"
              label={mainContext?.getTranslation("common", "input-sifre")}
              variant="outlined"
              className="basicInput !w-full"
              value={pass1}
              onChange={(e) => {
                setPass1(e.target.value);
              }}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {pass1Error && (
              <div className="text-red-400 text-12-14-500 pt-[8px] pl-[16px]">
                {pass1Error}
              </div>
            )}
          </div>
          <div
            className="w-[100%] pb-[24px]"
            style={requesting ? { pointerEvents: "none" } : {}}
          >
            <TextField
              id="outlined-basic"
              label={mainContext?.getTranslation("common", "input-sifretekrar")}
              variant="outlined"
              className="basicInput !w-full"
              value={pass2}
              onChange={(e) => {
                setPass2(e.target.value);
              }}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {pass2Error && (
              <div className="text-red-400 text-12-14-500 pt-[8px] pl-[16px]">
                {pass2Error}
              </div>
            )}
          </div>

          <CCheckbox
            selected={ticariiletiCheck}
            onChange={setTicariiletiCheck}
            disabled={requesting}
            textHtml={
              <div className="text-12-16-400 text-black ml-[8px]">
                {mainContext?.getTranslation(
                  "giris-kayit",
                  "ticari-ileti-oncesi"
                )}
                <span
                  className="text-purple underline"
                  onClick={() => {
                    setInfoText(ticariIletiMetni);
                  }}
                >
                  {mainContext?.getTranslation("giris-kayit", "ticari-ileti")}
                </span>
                {mainContext?.getTranslation(
                  "giris-kayit",
                  "ticari-ileti-sonrasi"
                )}
              </div>
            }
          />

          <div className="text-black text-14-24-400 text-start pb-[20px]">
            {mainContext?.getTranslation("giris-kayit", "iletisim-yontemi")}
          </div>

          <div
            className={`flex justify-start text-left items-center self-start`}
            style={requesting ? { pointerEvents: "none" } : {}}
          >
            <CCheckbox
              selected={withSms}
              onChange={setWithSms}
              textHtml={
                <div className="text-12-16-400 text-black ml-[8px]">
                  {mainContext?.getTranslation("common", "sms")}
                </div>
              }
            />
            <CCheckbox
              selected={withPhone}
              onChange={setWithPhone}
              textHtml={
                <div className="text-12-16-400 text-black ml-[8px]">
                  {mainContext?.getTranslation("common", "telefon")}
                </div>
              }
            />
            <CCheckbox
              selected={withEmail}
              onChange={setWithEmail}
              textHtml={
                <div className="text-12-16-400 text-black ml-[8px]">
                  {mainContext?.getTranslation("common", "eposta")}
                </div>
              }
            />
          </div>

          <div className="w-full pb-[24px]">
            <Button
              disabled={requesting || !canSignIn()}
              className="w-full purpleButton"
              onClick={async () => {
                setRequesting(true);
                await signUp(
                  firstname,
                  lastname,
                  pass1,
                  withSms,
                  withPhone,
                  withEmail,
                  companyName
                );
                setRequesting(false);
              }}
            >
              {requesting ? (
                <CLoadingAnimation size={24} />
              ) : (
                <span>{mainContext?.getTranslation("common", "katil")}</span>
              )}
            </Button>
          </div>
          <CSignInUpSwitcher onSwitch={() => onClose()} />

          <CAgreementTextPopup
            isOpen={infoText != undefined}
            onClose={() => {
              infoText?.onClose?.();
            }}
            onAccept={() => {
              infoText?.onAccept?.();
            }}
            infoTitle={infoText?.title}
            infoText={infoText?.text}
            buttonText={infoText?.buttonText}
          />
        </div>
      </Box>
    </Modal>
  );
}
