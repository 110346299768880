import {parseToMediaObject, TMediaObject} from "./TMediaObject";
import {dateStrToString} from "../utils/date";

export interface TPortfolio {
  id: number;
  key: string;
  professionalID: number;
  name: string;
  remark: string;
  medias: TMediaObject[];
  videoLink: string;
  skills: number[];
  startDate?: string;
  startDateObj?: Date;
  endDate?: string;
  endDateObj?: Date;
  deleted?: boolean;
}

export function parseToPortfolio(c: any): TPortfolio {

  let startDateStr = c.beginDate ? dateStrToString(c.beginDate) : '-';
  let startDateObj = c.beginDate ? new Date(c.beginDate) : undefined;
  let endDateStr = c.endDate ? dateStrToString(c.endDate) : '-';
  let endDateObj = c.endDate ? new Date(c.endDate) : undefined;
  return {
    id: c.id,
    key: c.key,
    professionalID: c.professionalID,
    name: c.name,
    remark: c.remark,
    medias: c.fileList.map(parseToMediaObject),
    videoLink: c.videoLink,
    skills: c.skills,
    startDate: startDateStr,
    startDateObj: startDateObj,
    endDate: endDateStr,
    endDateObj: endDateObj,
  }
}
