import {ChangeEvent, useContext, useEffect, useRef, useState} from "react";
import {MainContext} from "../../context/MainContext";
import {getV2, postV2} from "../../utils/request";
import {EnumProfileUpdateType} from "../../types/EnumProfileUpdateType";
import {Box, Modal} from "@mui/material";
import {passRegex} from "../../types/Regex";
import CLoadingAnimation from "../CLoadingAnimation";
import {getFile} from "../../utils/file";
import {CompanyProfileData} from "../../types/TCompanyData";
import {TError} from "../../types/TError";
import {NotificationType} from "../Toast/Toast";

interface IProps {
  getTranslation: (child: string) => string
  isOpen: boolean
  close: () => void
  updated: (callback?: any) => void
  data: CompanyProfileData
}

export function CUserEditModalCompany({getTranslation, isOpen, close, updated, data}: IProps) {
  const mainContext = useContext(MainContext)

  const [isUpdatingPhoto, setIsUpdatingPhoto] = useState(false)
  const [isUpdatingPagePhoto, setIsUpdatingPagePhoto] = useState(false)
  const [tempData, setTempData] = useState(data)

  useEffect(() => {
    if (isOpen) {
      setTempData(data)
    }
  }, [data, isOpen]);


  function deleteOldPhotos(type: EnumProfileUpdateType) {
    if (data.allProfilePhotos && data.allProfilePhotos.length > 0) {
      data.allProfilePhotos.forEach(p => {
        if ((type == EnumProfileUpdateType.Logo && p.type != EnumProfileUpdateType.PageImage) ||
          (type == EnumProfileUpdateType.PageImage && p.type != EnumProfileUpdateType.Logo)) {
          getV2('/CompanyFile/deletefile?fileKey=' + p.key)
        }
      })
    }
  }

  function updatePhoto(type: EnumProfileUpdateType) {
    if (mainContext?.isExpired('companyupdatephoto')) {
      return
    }

    if (type == EnumProfileUpdateType.Logo) {
    if (photoFile == undefined || photoFile.length == 0) {
      return;
    }
      setIsUpdatingPhoto(true)
    } else {
      if (pagePhotoFile == undefined || pagePhotoFile.length == 0) {
        return;
      }
      setIsUpdatingPagePhoto(true)
    }
    getFile(
      type == EnumProfileUpdateType.Logo?photoFile![0]:pagePhotoFile![0],
      (filename, fileData) => {
        postV2('/CompanyFile/createfile', {
          type: type,
          fileName: filename,
          fileContents: fileData
        }).then(r => {
          if (type == EnumProfileUpdateType.Logo) {
            setIsUpdatingPhoto(false)
          } else {
            setIsUpdatingPagePhoto(false)
          }
          if (r instanceof TError) {
            mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
          } else {
            mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
            deleteOldPhotos(type)
            updated((newData: CompanyProfileData) => {
              if (newData.profilePhoto) {
                mainContext?.updateUserPhoto(newData.profilePhoto)
              }
            })
          }
        }).catch((e) => {
          if (type == EnumProfileUpdateType.Logo) {
            setIsUpdatingPhoto(false)
          } else {
            setIsUpdatingPagePhoto(false)
          }
          mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
        });
      },
      (error) => {
        if (type == EnumProfileUpdateType.Logo) {
          setIsUpdatingPhoto(false)
        } else {
          setIsUpdatingPagePhoto(false)
        }
        mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
      }, mainContext
    )

  }

  const [photoFile, setPhotoFile] = useState<undefined | FileList>(undefined)
  const [pagePhotoFile, setPagePhotoFile] = useState<undefined | FileList>(undefined)

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '16px',
    minWidth: '600px',
    maxWidth: '750px'
  };

  const photoFileRef = useRef(null);
  const onFileChangeCapture = (e: ChangeEvent<HTMLInputElement>) => {
    let files = e.target.files;
    if (FileReader && files && files.length) {
      setPhotoFile(files)
      var fr = new FileReader();
      fr.onload = function () {

        let ppImage = document.getElementById('ppImage');
        if (ppImage != null) {
          //@ts-ignore
          ppImage.src = fr.result;
        }
      }
      fr.readAsDataURL(files[0]);
    }
  };
  const onPhotoClick = () => {
    //@ts-ignore
    photoFileRef.current?.click();
  };

  const pagePhotoFileRef = useRef(null);
  const onPageFileChangeCapture = (e: ChangeEvent<HTMLInputElement>) => {
    let files = e.target.files;
    if (FileReader && files && files.length) {
      setPagePhotoFile(files)
      var fr = new FileReader();
      fr.onload = function () {
        let pppImage = document.getElementById('pppImage');
        if (pppImage != null) {
          //@ts-ignore
          pppImage.src = fr.result;
        }
      }
      fr.readAsDataURL(files[0]);
    }
  };
  const onPagePhotoClick = () => {
    //@ts-ignore
    pagePhotoFileRef.current?.click();
  };

  return <Modal open={isOpen} onClose={close} disableAutoFocus={true}>
    <Box sx={style}>
      <div className={'filterModal max-h-[85vh]'}>
        <input
          style={{display: 'none'}}
          type="file"
          ref={photoFileRef}
          onChangeCapture={onFileChangeCapture}
        />
        <input
          style={{display: 'none'}}
          type="file"
          ref={pagePhotoFileRef}
          onChangeCapture={onPageFileChangeCapture}
        />
        <div className={'title'}>
          <span>{getTranslation('sirket-profil-ayarlari')}</span>
          <img onClick={close} src={'/images/closeModal.svg'}/>
        </div>
        <div className={'flex-col y-scroll'}>
          <div className={'w-full flex justify-between pt-[40px]'}>
            <div className={'flex-col gap-[24px] items-center'}>
              <p
                className={'text-18-24-600 whitespace-nowrap'}>{mainContext?.getTranslation('profile', (data.profilePhoto?.url ?? '').length > 0 ? 'profil-foto-duzenle' : 'profil-foto-ekle')}</p>
              <img
                id={'ppImage'}
                className="w-[154px] h-[154px] rounded-full bg-gray-300"
                src={tempData.profilePhoto?.url}
              />
            </div>
            <div className={'flex items-center'}>
              <div className={'flex-col gap-[24px]'}>
                <p
                  className={'text-18-24-600 text-black whitespace-nowrap'}>{getTranslation('sirket-foto-yukle')}</p>
                {/*{photoError && <div className="text-red-400 text-sm">{photoError}</div>}*/}
                <div className={'flex gap-[12px]'}>
                  <div role={'button'} className={'button cancel'}
                       onClick={onPhotoClick}>{mainContext?.getTranslation('profile', 'fotografi-degistir')}</div>
                  <div role={`button`}
                       className={`button save ${photoFile == undefined ? 'disabledBtn' : isUpdatingPhoto ? 'loadingButton relative' : ''}`}
                       onClick={() => updatePhoto(EnumProfileUpdateType.Logo)}>
                    {isUpdatingPhoto &&
                        <div className={'absolutetop0'}>
                            <CLoadingAnimation size={32}/>
                        </div>
                    }
                    <p className={(isUpdatingPhoto ? ' opacity-0' : '')}>
                      {mainContext?.getTranslation('profile', 'fotografi-kaydet')}
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className={'w-full flex justify-between pt-[120px]'}>
            <div className={'flex-col gap-[24px] w-full'}>
              <p
                className={'text-18-24-600 whitespace-nowrap'}>{getTranslation('sirket-sayfa-foto-yukle')}</p>
              <img
                id={'pppImage'}
                className="object-cover w-full h-[154px] rounded-[16px] bg-gray-300"
                src={tempData.pagePhoto?.url}
              />
              <div className={'flex items-end self-end'}>
                <div className={'flex gap-[12px]'}>
                  <div role={'button'} className={'button cancel'}
                       onClick={onPagePhotoClick}>{mainContext?.getTranslation('profile', 'fotografi-degistir')}</div>
                  <div role={`button`}
                       className={`button save ${pagePhotoFile == undefined ? 'disabledBtn' : isUpdatingPagePhoto ? 'loadingButton relative' : ''}`}
                       onClick={() => updatePhoto(EnumProfileUpdateType.PageImage)}>
                    {isUpdatingPagePhoto &&
                        <div className={'absolutetop0'}>
                            <CLoadingAnimation size={32}/>
                        </div>
                    }
                    <p className={(isUpdatingPagePhoto ? ' opacity-0' : '')}>
                      {mainContext?.getTranslation('profile', 'fotografi-kaydet')}
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </Box>
  </Modal>
}
