import {Box, Modal} from "@mui/material";
import styles from './Profile.module.scss'
import React from "react";
import {mergeCss} from "../../utils/cssMerge";

interface IProps {
  title?: string
  component?: JSX.Element,
  onClose: () => void
  componentClass?: string
  rightSpacing?:number
  parentClass?:string
}

export function ComponentShowModal({title, component, onClose,componentClass,rightSpacing,parentClass}: IProps) {

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '90%',
    maxWidth: '700px',
    maxHeight: '85%',
    bgColor: "#fff",
    border: "none",
    borderRadius: "20px",
    overflowY: 'hidden',
    display: 'flex',
    outline: 'none'
  };

  return <Modal
    open={component != undefined}
    onClose={() => {
      onClose();
    }}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box className={`place-content-center ${parentClass??''}`} sx={style}>
      <div
        className={mergeCss(['flex-col bg-white py-[29px] px-[36px] rounded-[16px] w-full overflow-x-hidden',rightSpacing?`!pr-[${rightSpacing}px]`:'', styles.content])}>
        <div className={`flex justify-between pb-[12px] ${rightSpacing?`!pr-[${rightSpacing}px]`:''}`}>
          {title ? <h1>{title}</h1> : <div></div>}
          <img role={'button'} onClick={onClose} src={'/images/close.svg'}/>
        </div>
        <div className={rightSpacing?`!pr-[${rightSpacing}px]`:''}>
          <div className={'dividerHorizontal'}/>
        </div>
          <div className={mergeCss(['flex-col overflow-y-auto',componentClass??'',rightSpacing?`pr-[${rightSpacing}px]`:''])}>
            {component}
          </div>
      </div>
    </Box>
  </Modal>
}
