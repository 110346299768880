import {FilterModal} from "./Filter/FilterModal";
import {forwardRef, useContext, useEffect, useRef, useState} from "react";
import {MainContext} from "./../context/MainContext";
import {JobStatusInt, ProjectOfferUpdate, TJob} from "../types/TJob";
import {TextField} from "@mui/material";
import {NumericFormat, NumericFormatProps} from "react-number-format";
import {NumericFormatPrice} from "./Numeric/PriceInput";
import {postV2} from "../utils/request";
import CLoadingAnimation from "./CLoadingAnimation";
import {ModalButton} from "./Input/ModalButton";
import {TError} from "../types/TError";
import {NotificationType} from "./Toast/Toast";

interface IProps {
  getTranslation: (key: string) => string
  isOpen: boolean
  close: () => void
  update: () => void
  job?: TJob
}

export function CInspectOfferByProfessionalModal({getTranslation, isOpen, close, update, job}: IProps) {
  const mainContext = useContext(MainContext)

  const [isRequesting, setIsRequesting] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [newAmount, setNewAmount] = useState<number | undefined>(undefined)

  const isUpdatable = newAmount && newAmount > 0 && (job?.price ? (job.price != newAmount) : true)

  useEffect(() => {
    setNewAmount(undefined)
  }, [isOpen]);

  function save() {
    if (mainContext?.isExpired('inspectoffer')) {
      return
    }
    if (isUpdatable) {
      return
    }
    setIsUpdating(true)
    postV2('/ProjectOffer/update', {
      projectKeyID: job?.id,
      price: newAmount,
    }).then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateOffer')
      } else {
        mainContext?.showToast(NotificationType.Success, 'updateOffer')
        update()
      }
    }).catch((e) => {
      mainContext?.showToast(NotificationType.Fail, 'updateOffer')
    }).finally(() => {
      setIsUpdating(false)
    })
  }

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.includes('-')) {
      return
    }
    setNewAmount(parseFloat((event.target.value.length > 0 && event.target.value != '-') ? event.target.value : '0'));
  };

  async function Update(customPrice?: number) {
    if (isRequesting) {
      return
    }
    if (customPrice == undefined) {
      if (newAmount === undefined || newAmount <= 0) {
        return
      }
    }else{
      setNewAmount(customPrice)
    }
    setIsRequesting(true)
    setIsUpdating(true)
    let updateData = {
      projectKeyID: job?.projectKeyID,
      currencyID: job?.currencyID,
      price: customPrice??newAmount,
      status: JobStatusInt.ProfessionalUpdateOffer,
      remark: job?.description,
      visible: true
    };
    await postV2('/ProjectOffer/update', updateData).then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateOffer')
      } else {
        mainContext?.showToast(NotificationType.Success, 'updateOffer')
        update()
        close();
      }
    }).catch((e) => {
      mainContext?.showToast(NotificationType.Fail, 'updateOffer')
    }).finally(() => {
      setIsUpdating(false)
    })

    setIsRequesting(false)
    setIsUpdating(false)
  }


  return <FilterModal
    title={getTranslation('teklifi-incele')}
    header={getTranslation('teklif-verdigin-tutar')}
    isOpen={isOpen}
    onClose={close}
    onSave={save}
    requesting={isUpdating}
    customHeaderClass={'!pb-[20px]'}
    customButtons={<div className={'flex justify-center gap-[24px]'}>
      <ModalButton loading={isUpdating} disabled={isRequesting || newAmount === undefined || newAmount <= 0}
                   text={getTranslation('fiyat-oner')} onClick={Update}/>
      {(job?.companyRequestPrice != undefined && (job.price == undefined || job?.price != job?.companyRequestPrice)) &&
          <ModalButton loading={isUpdating} disabled={isRequesting}
                       className={'div-green'}
                       text={getTranslation('sirket-teklifini-kabul-et')} onClick={()=>Update(job!.companyRequestPrice!)}/>
      }
    </div>}
  >
    <div className={'flex-col'}>
      <div className={'w-full div-light-purple rounded-[16px] pl-[10px] pt-[29px] pb-[36px] pr-[71px]'}>
        <div className={'flex gap-[79px]'}>
          <div className={'flex flex-col gap-[26px] min-w-[210px] items-center'}>
            <div className={'rounded-full w-[100px] h-[100px] div-purple'}>
              <img src={'BE donecek'}/>
            </div>
            <div>
              <p className={'text-purple text-15-24-700'}>{`${job?.firm}`}</p>
              <p className={'text-purple text-15-24-700'}>{`${job?.title}`}</p>
            </div>
          </div>
          <div className={'flex flex-col gap-[34px]'}>
            <div style={{pointerEvents: 'none'}} className={'flex flex-col gap-[8px]'}>
              <p className={'text-black text-15-24-700'}>{getTranslation('sana-gelen')}</p>
              <div className={'input-text mini'}>
                <TextField
                  value={job?.companyRequestPrice ?? 0}
                  InputProps={{
                    inputComponent: NumericFormatPrice as any,
                  }}
                  variant="outlined"
                />
              </div>
            </div>
            <div style={{pointerEvents: 'none'}} className={'flex flex-col gap-[8px]'}>
              <p className={'text-black text-15-24-700'}>{getTranslation('sirketin-gonderdigi')}</p>
              <div className={'input-text mini'}>
                <TextField
                  value={job?.price ?? 0}
                  InputProps={{
                    inputComponent: NumericFormatPrice as any,
                  }}
                  variant="outlined"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={'pt-[32px]'}>
        <div className={'modalTitle'}>{getTranslation('fiyat-oner')}</div>
      </div>
      <div className={'input-text w-full pt-[18px]'}>
        <TextField
          placeholder={mainContext?.getTranslation('common', 'giriniz')}
          value={newAmount == 0 ? '' : newAmount}
          onChange={handlePriceChange}
          InputProps={{
            inputComponent: NumericFormatPrice as any,
          }}
          variant="outlined"
        />
      </div>
    </div>
  </FilterModal>
}
