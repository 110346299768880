import styles from "../pages/ProjectDetail/ProjectDetail.module.scss";
import {Button} from "@mui/material";
import {BModal} from "./Modal/BModal";
import {useNavigate} from "react-router-dom";

interface IProps {
  isOpen: boolean
  close: () => void
  onClick: () => void
  getTranslation: (key: string) => string
  textKey: string
  descriptionTextKey: string
}

export function CProjectUpsertModal({isOpen, close, getTranslation, textKey, descriptionTextKey, onClick}: IProps) {
  const navigate = useNavigate();

  return <BModal isOpen={isOpen} onClose={close}
                 children={<div className={'flex flex-col items-center pt-[6px]'}>
                   <img className={'w-[356px]'} src={'/images/ProjectSuccess.svg'}/>
                   <div className={'pt-[31px]'}>
                     <div className={'successTexts bigText'}>
                       <div>
                         {getTranslation('tebrikler')}
                       </div>
                       <div>
                         {getTranslation(textKey)}
                       </div>
                     </div>
                     <div className={`pt-[16px] ${styles.tebriksDetay}`}>
                       {getTranslation(descriptionTextKey)}
                     </div>
                     <div className={'pt-[41px] flex justify-center'}>
                       <Button className={'purpleButtonMini !bg-[#05D490]'} onClick={onClick}>
                         {getTranslation('ilani-gor')}
                       </Button>
                     </div>
                   </div>
                 </div>}/>
}
