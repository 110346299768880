import { TVerticalCardImage } from "../types/TComponents";

interface PVerticalImageCard {
  data: TVerticalCardImage;
}

function CVerticalImageCard({ data }: PVerticalImageCard) {
  return (
    <div className="verticalImageCard">
      <img className="image" src={data.img} />
      {data.header && <div className="header">{data.header}</div>}
      {data.sub1 && <div className="sub1">{data.sub1}</div>}
      <div className="flex justify-between">
        {data.sub2 && <div className="sub2">{data.sub2}</div>}
        {data.starText && (
          <div className="flex items-center score gap-[4px]">
            <img className="star" src="/images/star.svg" />
            <div>{data.starText}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CVerticalImageCard;
