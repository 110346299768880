import {parsePrice} from "../utils/currency";
import {CompanyContactPerson, CompanyProfileData, parseContactPerson} from "./TCompanyData";
import {dateStrToString} from "../utils/date";
import {FilterConfig} from "./TFilter";
import {
  cities,
  countries, currencies,
  languages,
  professions,
  skills,
  workingPriceTypes,
  workingTypes
} from "../service/commonRequest";
import {JobStatus} from "./TJob";
import {TError} from "./TError";

export interface TProject {
  id: number
  projectKeyID: string
  title: string
  location?: string
  postedAgo: string
  workingTypeId: string
  workingPriceTypeId: string
  workingTypes: string[]
  workingTypeStr: string
  workingPriceTypeStr: string
  priceRange: string[]
  currencySymbol: string
  description: string
  tags: string[]
  liked: boolean
  contactPerson: CompanyContactPerson
  createDate?: string
  createDateObj?: Date
  startDate?: string
  startDateObj?: Date
  endDate?: string
  endDateObj?: Date
  skills: string[]
  languages: string[]
  professions: string[]
  offerCount: number
  offerStatus: JobStatus
  installment: number
  isActive: boolean
  hasConfirmOffer: boolean
  skillManual: string
  professionManual: string
  country?: FilterConfig
  city?: FilterConfig
  currency?: FilterConfig
  workingPriceType?: FilterConfig
  professionsMap?: FilterConfig[]
  languagesMap?: FilterConfig[]
  skillsMap?: FilterConfig[]
  workingTypesMap?: FilterConfig[]
  source: string
  url: string

  unparsedBeginDate?: string
  unparsedDeadlineDate?: string
  unparsedTransactionDate?: string
}

export function parseProjectDetail(data: any, keyId?: string): TProject {
  let createDateStr = data.transactionDate ? dateStrToString(data.transactionDate) : '-';
  let createDateObj = data.transactionDate ? new Date(data.transactionDate) : undefined;
  let startDateStr = data.beginDate ? dateStrToString(data.beginDate) : '-';
  let startDateObj = data.beginDate ? new Date(data.beginDate) : undefined;
  let endDateStr = data.deadline ? dateStrToString(data.deadline) : '-';
  let endDateObj = data.deadline ? new Date(data.deadline) : undefined;
  let priceRange = [parsePrice(data.priceRangeBegin), parsePrice(data.priceRangeEnd)];
  let currency = currencies.find(c => c.id == data.currencyID);
  const job: TProject = {
    id: data.id,
    projectKeyID: data.projectKeyID ?? keyId,
    title: data.name,
    description: data.remark ?? '',
    contactPerson: parseContactPerson(data),
    location: data.location,
    currencySymbol: data.currencyStr ?? currency?.code ?? '-',
    priceRange: priceRange,
    workingPriceTypeId: data.workingPriceTypeID == 0 ? undefined : data.workingPriceTypeID,
    workingTypeId: data.workingTypeID == 0 ? undefined : data.workingTypeID,
    workingPriceTypeStr: data.workingPriceTypeStr ?? '',
    workingTypeStr: data.workingTypeStr ?? '',
    workingTypes: data.workingTypes ?? [],
    skills: data.skills ?? [],
    languages: data.languages ?? [],
    professions: data.professions ?? [],
    tags: data.skillStrs ? [...data.skillStrs] : [],
    postedAgo: data.timeStr,
    liked: data.favorite,
    createDate: createDateStr,
    createDateObj: createDateObj,
    startDate: startDateStr,
    startDateObj: startDateObj,
    endDate: endDateStr,
    endDateObj: endDateObj,
    offerCount: data.offerCount,
    offerStatus: data.offerStatus,
    installment: data.installment,
    isActive: data.active,
    hasConfirmOffer: data.hasConfirmOffer,
    professionManual: data.professionOffer,
    skillManual: data.skillOffer,
    country: countries.find(c => c.id == data.countryID),
    city: cities.find(c => c.id == data.cityID),
    workingPriceType: workingPriceTypes.find(c => c.id == data.workingPriceTypeID),
    currency: currency,
    //@ts-ignore
    professionsMap: data.professions?.filter(t => t != '0')?.map(p => professions.find((f) => ('' + f.id) == ('' + p))) ?? [],
    //@ts-ignore
    languagesMap: data.languages?.filter(t => t != '0')?.map(p => languages.find((f) => ('' + f.id) == ('' + p))) ?? [],
    //@ts-ignore
    skillsMap: data.skills?.filter(t => t != '0')?.map(p => skills.find((f) => ('' + f.id) == ('' + p))) ?? [],
    //@ts-ignore
    workingTypesMap: data.workingTypes?.filter(t => t != '0')?.map(p => workingTypes.find((f) => ('' + f.id) == ('' + p))) ?? [],
    source: data.source ?? 'pofft',
    url: data.url ?? '',
    unparsedBeginDate: data.beginDate ?? '',
    unparsedDeadlineDate: data.deadline ?? '',
    unparsedTransactionDate: data.transactionDate ?? '',
  }
  return job
}

export interface TSearchResponse {
  projectList: TProject[],
  totalCount: number
}

export function ParseSearchResults(responseData: any, totalCount: number): TSearchResponse {
  if (responseData instanceof Array) {
    const jobs = (responseData as any[]).map((d) => parseProjectDetail(d))
    return {projectList: jobs, totalCount: totalCount}
  }
  return {projectList: [], totalCount: 0}
}

export function ParseProjectToRequest(project: TProject, companyProfile?: CompanyProfileData): any | TError {
  if (project?.projectKeyID == undefined) {
    return new TError(-1, '')
  }
  let data: any = {
    active: false,
    projectKeyID: project?.projectKeyID,
    name: project?.title,
    remark: project?.description,
    workingPriceTypeID: project?.workingPriceTypeId,
    priceRangeBegin: project?.priceRange[0],
    priceRangeEnd: project?.priceRange[1],
    // workingTypes: Array.from(project?.workingTypesMap?.map(w=>`${w.id}`)??[]),
    workingTypes: project?.workingTypes,
    skills: project?.skills,
    professions: project?.professions,
    languages: project?.languages,
    cityID: companyProfile?.cityID,
    countryID: companyProfile?.countryID,
    currencyID: project?.currency?.id,
    skillOffer: project?.skillManual,
    professionOffer: project?.professionManual,
    address: companyProfile?.address,
    beginDate: project?.unparsedBeginDate,
    deadline: project?.unparsedDeadlineDate,
    transactionDate: project?.unparsedTransactionDate,
  }
  if (companyProfile?.contactPerson) {
    data.contactPersonName = companyProfile.contactPerson.name
    data.contactPersonSurname = companyProfile.contactPerson.surname
    data.contactPersonEmail = companyProfile.contactPerson.email
    data.contactPersonPhone = companyProfile.contactPerson.phone
  }
  return data
}
