import React, {ReactElement, useContext, useEffect, useState} from "react";
import {Autocomplete, Box, TextField} from "@mui/material";
import {MainContext} from "../../context/MainContext";
import './Inputs.scss';
import {FilterConfig} from "../../types/TFilter";
import CLoadingAnimation from "../CLoadingAnimation";
import {Popper} from "@material-ui/core";

interface IProps {
  val: any
  onChange: (newVals: FilterConfig | FilterConfig[] | undefined) => void
  isMultiple?: boolean
  disabled?: boolean
  loading?: boolean
  options?: FilterConfig[];
  customPortal?: boolean
  noResultHandler?: ReactElement
  customClass?: string;
}

export function CSelectWithSearchForFilterSingle({
                                                   val,
                                                   onChange,
                                                   isMultiple,
                                                   options,
                                                   disabled,
                                                   loading,
                                                   customPortal,
                                                   noResultHandler,
                                                   customClass
                                                 }: IProps) {
  const mainContext = useContext(MainContext);

  const [selectedValues, setSelectedValues] = useState<FilterConfig[]>(val != undefined ? val : [])

  function getUnselectedOptions(): any[] {
    return options?.filter((o) => {
      if (selectedValues != undefined) {
        for (let i = 0; i < selectedValues.length; i++) {
          if (o.id == selectedValues[i].id) {
            return false
          }
        }
      }
      return true
    }) ?? []
  }

  useEffect(() => {
    setSelectedValues(val);
  }, [val]);

  const PopperMy = function (props: any) {
    let parent = document.getElementById('input-select');
    return (<Popper {...props} anchorEl={parent} style={{height: 250, width: parent!.offsetWidth}} placement='bottom'/>)
  }

  const opts = isMultiple ? getUnselectedOptions() : options ?? []
  return <>
    {(loading != undefined && loading == true) ?
      <div className={'flex flex-col justify-center h-full'}>
        <CLoadingAnimation size={44}/>
      </div>
      :
      <div id={'input-select'} className={'input-select ' + (customClass ?? '')}>
        <Autocomplete
          disabled={disabled}
          PopperComponent={customPortal ? (p) => PopperMy(p) : undefined}
          disablePortal={customPortal == undefined}
          size={'small'}
          options={opts}
          value={selectedValues ?? []}
          multiple={isMultiple}
          popupIcon={<div className={'flex justify-start'}><img src={'/images/filterSelectIcon.svg'}/>
          </div>}
          onChange={(event: any, newValue: any | any[] | null) => {
            if (newValue == null) {
              onChange(undefined)
              return
            }
            setSelectedValues(newValue)
            onChange(newValue)
          }}
          renderOption={(props, option) => (
            <Box component="li" className={'option'} {...props}>
              {option.label}
            </Box>
          )}
          noOptionsText={
            <div className={'flex-col gap-[12px]'}>
              {noResultHandler ?? mainContext!.getTranslation('common', 'aramaya-uygun-bulunamadi')}
            </div>
          }
          sx={{width: 300}}
          renderInput={(params) => <TextField {...params}
                                              label={mainContext!.getTranslation('common', 'seciniz')}/>}
        />
      </div>}
  </>
}
