import { Box, Button, Modal, TextField } from "@mui/material";
import { absoluteCenter } from "src/utils/window";
import CLoadingAnimation from "./CLoadingAnimation";
import { useEffect, useState, useContext } from 'react';
import { emailRegex } from "src/types/Regex";
import { MainContext } from '../context/MainContext';
import { forgetPassRequest } from "src/utils/request";

export interface IProps {
  isOpen: boolean;
  forgottenEmail: string;
  forgottenEmailError?: string;
  infoTitle?: string;
  buttonText?: string;
  onClose: () => void;
  setForgottenEmail: (val: string) => void;
  setForgottenEmailError: (val: string | undefined) => void;
}

export function CForgotPassPopup({
  isOpen,
  onClose,
  setForgottenEmail,
  forgottenEmail,
  forgottenEmailError,
  setForgottenEmailError,
}: IProps) {
  const mainContext=useContext(MainContext);
  useEffect(() => {
    if (forgottenEmail.length == 0) {
      setForgottenEmailError(undefined);
    } else {
      emailRegex.test(forgottenEmail)
        ? setForgottenEmailError(undefined)
        : setForgottenEmailError("Lütfen geçerli bir e-posta adresi giriniz.");
    }
  }, [forgottenEmail]);
  const [sentState, setSentState] = useState(false);
  const [requesting, setRequesting] = useState(false);

  function continueEnabled(): boolean { 
    return (
      forgottenEmail != undefined &&
      forgottenEmail.length > 0 &&
      forgottenEmailError == undefined
    );
  }

  async function resetPassword() {
    if(requesting){
      return
    }
    setRequesting(true);
    const result=await forgetPassRequest(forgottenEmail);
    setRequesting(false);
    if(result){
      //https://www.pofft.com/tr/Login/ResetPassword?login=berkocan1995@gmail.com&keyID=Y8bmfIUxF9YpetEs7Nmh6Q==&key=b6e0b6c968f4618e179ec76c54d0d79c&memberType=0
      setSentState(true); 
    }

  }

  return (
    <Modal
      open={isOpen}
      onClose={()=>{
        setSentState(false)
        setRequesting(false)
        onClose()
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={absoluteCenter}>
        <div className="w-[360px] lg:w-[380px] p-[24px] bg-white rounded-[8px] flex-col justify-center items-center">
          {sentState ? (
            <div className="flex-col items-center">
              <img src="/images/forgot_pass_success.svg" />
              <div className="pt-[26px] text-black text-18-24-600 text-center">
              {mainContext?.getTranslation('giris-kayit','sifre-sifirlama-gonderildi')}
              </div>
            </div>
          ) : (
            <>
              <div className="text-18-24-600 text-black pb-[32px]">
              {mainContext?.getTranslation('common','sifremi-unuttum')}
              </div>
              <div className="text-black text-14-24-400 pb-[24px]">
              {mainContext?.getTranslation('giris-kayit','sifre-sifirla-mail')}
              </div>
              <div
                className="w-[100%] pb-[24px]"
                style={requesting ? { pointerEvents: "none" } : {}}
              >
                <TextField
                  id="outlined-basic"
                  label={mainContext?.getTranslation('common','input-eposta')}
                  variant="outlined"
                  className="basicInput !w-full"
                  value={forgottenEmail}
                  onChange={(e) => {
                    setForgottenEmail(e.target.value);
                  }}
                />
                {forgottenEmailError && (
                  <div className="text-red-400 text-12-14-500 pt-[8px] pl-[16px]">
                    {forgottenEmailError}
                  </div>
                )}
              </div>
              <Button
                className="purpleButton w-full pb-[32px]"
                disabled={!continueEnabled()}
                onClick={() => {
                  if (requesting) {
                    return;
                  }
                  resetPassword();
                }}
              >
                {requesting ? (
                  <CLoadingAnimation size={24} />
                ) : (
                  <span>{mainContext?.getTranslation('giris-kayit','sifre-sifirla')}</span>
                )}
              </Button>
              <div className="flex gap-[4px] w-full items-center py-[32px]">
                <div className="full-dividerH h-[2px]" />
              </div>
              <div className="text-14-24-400 text-black">
              {mainContext?.getTranslation('giris-kayit','giris-yapa-don-oncesi')}
                <span
                  className="text-purple"
                  onClick={() => {
                    onClose();
                  }}
                >
                  {mainContext?.getTranslation('giris-kayit','giris-yapa-don')}
                </span>
                {mainContext?.getTranslation('giris-kayit','giris-yapa-don-sonrasi')}
              </div>
            </>
          )}
        </div>
      </Box>
    </Modal>
  );
}
