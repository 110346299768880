import {useContext} from "react";
import {MainContext} from "../context/MainContext";
import {useNavigate} from "react-router-dom";

export function NotFound() {
  const mainContext = useContext(MainContext);
  const navigate = useNavigate();

  return <div
    className={'flex-col lg:flex-row justify-between lg:pb-[72px] items-center relative lg:h-[100vh] w-[100vw]'}>
    <div className="h-full w-full relative">
      <img className={'w-[100vw] hidden lg:block'} src={'/images/NotFoundBackground.svg'}/>
      <img className={'w-[100vw] block lg:hidden'} src={'/images/home_hero_mobile_background.svg'}/>
      <div className={'absolute top-0 left-0 w-full h-full'}>
        <div className={'main-padding m-auto main-width pt-[132px] lg:pt-[152px] h-full'}>
          <div className={'w-full h-full flex flex-col justify-start lg:justify-center pt-[24px] lg:pt-[0px]'}>
            <div className={'flex flex-col-reverse lg:flex-row justify-between items-center gap-[24px]'}>
              <div
                className={'flex flex-col gap-[51px] lg:gap-[56px] items-center lg:items-start text-center lg:text-start'}>
                <div>
                  <p className={'text-black text-30-32-700_50-52-700'}>Ooops...</p>
                  <p
                    className={'pt-[18px] text-black text-16-18-400_48-50-400 tracking-[0px]'}>{mainContext?.getTranslation('common', 'sayfa-bulunamadi')}</p>
                </div>
                <div
                  className="div-purple text-white text-16-16-600 rounded-[15px] px-[37px] py-[16px] lg:px-[50px] lg:py-[22px] text-center mr-auto flex gap-[16px]"
                  onClick={() => {
                    navigate('/')
                  }}
                >
                  <img src={'/images/WhiteLeftArrow.svg'}/>
                  <span>{mainContext?.getTranslation("common", 'anasayfa')}</span>
                </div>
              </div>
              <div className={'flex justify-center lg:justify-end flex-1'}>
                <div className={'flex flex-col gap-[12px] lg:gap-[31px]'}>
                  <div className={'text-center'}>
                    <p className={'text-black text-30-32-700_50-52-700'}>404</p>
                    <p className={'text-black text-16-24-600_18-24-600 spacing'}>Page not found</p>
                  </div>
                  <img src={'/images/404-image.svg'}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
