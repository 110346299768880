import {useContext, useEffect, useState} from "react";
import {MainContext} from "../../context/MainContext";
import {FilterConfig, FilterPageProps, JobFilterType} from "../../types/TFilter";
import {CSelectWithSearchForFilter} from "../Input/CSelectWithSearchForFilter";
import {getV2, postV2} from "../../utils/request";
import {TError} from "../../types/TError";
import {FilterModal} from "../Filter/FilterModal";
import {EnumProfileUpdateType} from "../../types/EnumProfileUpdateType";
import {CSelectWithSearchForFilterSingle} from "../Input/CSelectWithSearchForFilterSingle";
import {NotificationType} from "../Toast/Toast";

const jobFilterType = JobFilterType.Profession;

interface IProps {
  value?: boolean
  isOpen: boolean
  close: () => void
  updated: (newValue: boolean) => void
}

export function CProfileSearchingJobModal({isOpen, close, value, updated}: IProps) {
  const mainContext = useContext(MainContext);

  const options: FilterConfig[] = [
    {id: 0, label: mainContext?.getTranslation('profile', 'is-aramiyorum') ?? ''},
    {id: 1, label: mainContext?.getTranslation('profile', 'is-ariyorum') ?? ''}
  ]

  const [tempVal, setTempVal] = useState(value ?? false)
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    setTempVal(value ?? false)
  }, [value]);

  useEffect(() => {
    if (isOpen) {
      setTempVal(value ?? false)
    }
  }, [isOpen]);

  function handleSave() {
    setIsUpdating(true)
    postV2('/Professional/partialupdate', {
      updateType: EnumProfileUpdateType.ActiveSearchProject,
      activeSearchProject: tempVal ? 'Yes' : 'No',
    }).then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
        return
      }
      mainContext?.showToast(NotificationType.Success, 'updateProfileInfo')
      updated(tempVal);
      close()
    }).catch((e) => {
      mainContext?.showToast(NotificationType.Fail, 'updateProfileInfo')
    }).finally(() => {
      setIsUpdating(false)
    })
  }

  function onCancel() {
    close();
  }

  return <FilterModal
    requesting={isUpdating}
    title={mainContext!.getTranslation('profile', 'is-arama-durumun')}
    header={''}
    desc={mainContext!.getTranslation('profile', 'aktif-is-arama')}
    isOpen={isOpen} onClose={onCancel} onSave={handleSave} customHeaderClass={'!pb-[20px]'}>
    <CSelectWithSearchForFilterSingle options={options} val={tempVal === true ? options[1] : options[0]} loading={false}
      //@ts-ignore
                                      onChange={f => f === undefined ? setTempVal(false) : setTempVal(f.id == 1)}
    />
  </FilterModal>
}
