import {useContext, useEffect, useState} from "react";
import {RBlog} from "../types/RBlogs";
import {getV2} from "../utils/request";
import CLoadingAnimation from "./CLoadingAnimation";
import {CTopBlogCard} from "./CTopBlogCard";
import {isDesktop} from "../utils/window";
import CSwiper1TopBlog from "./CSwiper1TopBlog";
import {MainContext} from "src/context/MainContext";
import {useNavigate} from "react-router-dom";

function CTopBlogs() {
  const navigate=useNavigate();
  const mainContext = useContext(MainContext);

  const [blogs, setBlogs] = useState<undefined | RBlog[]>(undefined);

  useEffect(() => {
    if (mainContext?.publicToken) {
      getV2(
        "/page/getpagebypaging?position=blog&pageSize=3&pageNumber=0&sortColumnName=ID&sortType=desc"
      ,true).then((resp) => {
        const blogsData = RBlog.parse(resp);
        setBlogs(blogsData);
      });
    }
  }, [mainContext?.publicToken]);

  return (
    <>
      {blogs == undefined ? (
        <CLoadingAnimation/>
      ) : (
        <div className="flex-col gap-[32px]">
          {isDesktop() ? (
            <div className="flex justify-around gap-[40px] pt-[48px]">
              {blogs.map((item,i) => {
                return <CTopBlogCard key={item.name.toLowerCase()+i} blog={item}/>;
              })}
            </div>
          ) : (
            <CSwiper1TopBlog
              data={blogs.map((item,i) => {
                return <CTopBlogCard key={item.name.toLowerCase()+i} blog={item}/>;
              })}
            />
          )}
          <div className="w-full flex">
            <div
              role="button"
              onClick={() => {
                navigate("/blogs");
              }}
              className="div-purple text-white rounded-[8px] px-[48px] py-[16px] m-auto text-center"
            >
              {mainContext?.getTranslation("common", "tum-yazilari-gor")}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CTopBlogs;
