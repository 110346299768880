import {parseTranslationWithVariable} from "../utils/translation";
import {useEffect, useState} from "react";
import {Icon} from '@iconify/react';
import {SocialMedia} from "../types/TCompanyData";

interface IProps {
  socialMedia?: SocialMedia
  getTranslation?: (parent: string, child: string) => string
  onUpdate: (s: SocialMedia) => void
  isUpdating: boolean
}

export function CSocialMediaContainer({socialMedia, getTranslation, onUpdate, isUpdating}: IProps) {
  if (socialMedia != undefined && socialMedia.socialMedia == 'Twitter') {
    socialMedia.socialMedia = 'X'
  }
  const [editing, setEditing] = useState(false)
  const [tempVal, setTempVal] = useState<string | undefined>(undefined)

  useEffect(() => {
    setTempVal(socialMedia?.link)
  }, [socialMedia]);

  useEffect(() => {
    if (isUpdating == true) {
      setEditing(false)
      setTempVal(socialMedia?.link)
    }
  }, [isUpdating]);

  if (socialMedia == undefined) {
    return <></>
  }
  const isLinkProvided = socialMedia.link != undefined && socialMedia.link.length > 0

  return <div role={'button'} className={`socialmediaContainer${isLinkProvided ? ' filled' : ''}`}
              onClick={() => {
                if (isUpdating) {
                  return
                }
                if (!editing) {
                  setEditing(!editing)
                }
              }}>
    <div className={'items-center'}>
      <img src={`/images/social_${socialMedia.socialMedia}${isLinkProvided ? '_white' : ''}.svg`}/>
      {editing ? <input autoFocus={true}
                        value={tempVal}
                        onChange={(e) => setTempVal(e.target.value)}
                        placeholder={'Giriniz'}
                        className={`standardInput compact colorless !color-[#6D6D6D] !text-base min-w-[300px] ${isLinkProvided ? 'white-color' : ''}`}
        /> :
        <p className={isLinkProvided ? 'text-white' : 'text-purple'}>{isLinkProvided ?
          parseTranslationWithVariable(getTranslation?.('profile', 'social-media-edit-var') ?? '', socialMedia.socialMedia) :
          parseTranslationWithVariable(getTranslation?.('profile', 'social-media-add-var') ?? '', socialMedia.socialMedia)
        }</p>
      }
      {editing &&
          <div className={'flex gap-[8px]'}>
              <div onClick={() => {
                setEditing(false)
                onUpdate({
                  ...socialMedia,
                  link: (tempVal == undefined || tempVal.length == 0) ? undefined : tempVal
                })
              }}>
                  <Icon icon='mingcute:check-fill' color={isLinkProvided ? 'white' : '#5658FF'}/>
              </div>
              <div onClick={() => {
                setEditing(false)
                setTempVal(socialMedia?.link)
              }}>
                  <Icon icon='mingcute:close-fill' color={isLinkProvided ? 'white' : '#5658FF'}/>
              </div>
          </div>
      }
    </div>
  </div>
}
