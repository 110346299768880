import { useContext, useEffect, useState } from "react";
import CBlogs from "src/components/CBlogs";
import CLoadingAnimation from "src/components/CLoadingAnimation";
import { MainContext } from "src/context/MainContext";
import { DataProps } from "src/types/DataProps";
import { Translation, parseTranslation } from "src/types/Translation";
import {getV2} from "src/utils/request";
import {getTranslations} from "../service/commonRequest";

export default function Blogs() {
  const mainContext = useContext(MainContext);

  const [data, setData] = useState<DataProps[] | undefined>(undefined);
  const [translations, setTranslations] = useState<
    Map<string, Translation> | undefined
  >(undefined);

  function getTranslation(child: string): string {
    return translations?.get("blog")?.sentences.get(child) ?? "";
  }

  useEffect(() => {
    if (mainContext?.publicToken) {
      getTranslations('ana-sayfa',(translations,data)=>{
        setData(data);
        setTranslations(translations);
      })
    }
  }, [mainContext?.publicToken]);

  useEffect(() => {
    if (data != undefined) {
      setTimeout(() => {
        const refs = window.location.href.split("#");
        if (refs !== null && refs.length > 1) {
          const targetto = refs[1];
          const target = document.getElementById(targetto);
          const scrollToY = target!.offsetTop - 50;
          console.log(`Y: ${targetto} px: ${scrollToY}`);
          window.scrollTo(0, scrollToY);
        } else {
          window.scrollTo(0, 0);
        }
      }, 250);
    }
  }, [data]);

  return data == undefined ? (
    <div className="h-[100vh] w-[100vw] flex-col justify-center">
      <CLoadingAnimation />
    </div>
  ) : (
    (() => {

      return (
        <>
          <div className="pb-[48px] lg:pb-[72px]">
            <div className="flex justify-between main-width m-auto main-padding pt-[96px] lg:pt-[136px] relative items-center h-full flex-col">
          <div className={`pt-[16px] text-black w-[640px] m-auto text-center w-full text-24-40-500_32-40-500`}>
            {getTranslation('text2')}
          </div>
              <CBlogs />
            </div>
          </div>
          <div className="pt-[48px] lg:pt-[168px]"></div>
        </>
      );
    })()
  );
}
