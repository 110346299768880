import * as animationData from "../animation/loading.json";

import Lottie from "react-lottie";

interface IProps {
  size?: number;
  fullscreen?: boolean;
}

function CLoadingAnimation({ size,fullscreen }: IProps) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={fullscreen?"w-[100%] h-[100%] flex justify-center items-center":""}>
      <Lottie
        options={defaultOptions}
        height={size ?? 250}
        width={size ?? 250}
      />
    </div>
  );
}

export default CLoadingAnimation;
