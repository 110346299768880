export interface rowData {
  data1: string;
  data2?: string;
}

export function createTableHTML(datas: rowData[]) {
  const openTable =
    '<table border="2" style="border-collapse: collapse; border: 2px solid black;">';
  const closeTable = "</table>";

  let tableContent = "";
  datas.forEach((data) => {
    if (data.data2 != undefined) {
      tableContent += rowWith2columns(data.data1, data.data2!);
    } else {
      tableContent += rowWith1column(data.data1);
    }
  });

  return openTable+tableContent+closeTable;
}

function rowWith2columns(key: string, val: string) {
  return (
    '<tr> <td style="border: 2px solid black; padding: 8px;">' +
    key +
    '</td> <td style="border: 2px solid black; padding: 8px;">' +
    val +
    "</td> </tr>"
  );
}
function rowWith1column(val: string) {
  return (
    '<tr><td colspan="2" style="border: 2px solid black; padding: 8px;">' +
    val +
    "</td></tr>"
  );
}
