import { IDataProps } from "../types/DataProps";
import { isDesktop } from "../utils/window";
function CHeroPartner({ getTranslation, data }: IDataProps) {
  return (
    <div className="homeHero mobileonly:h-[869px]">
      <div className="homeHeroImage mobileonly:h-[869px]">
        {isDesktop() ? (
          <img src="/images/partner_hero_background.svg" />
        ) : (
          <img src="/images/partner_hero_mobile_background.svg" />
        )}
      </div>
      <div className="flex  main-width m-auto main-padding pt-[132px] lg:pt-[152px] relative items-center h-full flex-col lg:flex-row">
        <div className="flex-col justify-left lg:flex-1 text-center lg:text-start">
          <div className="text-20-24-400 text-grey">
            {getTranslation("hero-header")}
          </div>
          <div className="pt-[16px] text-32-40-500 text-black">
            {getTranslation("hero-title")}
          </div>
          <div className="pt-[16px] text-16-24-400 text-grey">
            {getTranslation("hero-content")}
          </div>
          <ul
            className="pt-[16px] pl-[16px] text-16-24-400 text-grey text-start"
            style={{ listStyle: "disc" }}
          >
            <li>{getTranslation("hero-content-1")}</li>
            <li>{getTranslation("hero-content-2")}</li>
            <li>{getTranslation("hero-content-3")}</li>
          </ul>
        </div>
        <div className="w-full lg:w-[min(600px,60%)]">
          <img src={data?.pagePictureModelList?.[0].getUrl()} />
        </div>
      </div>
    </div>
  );
}

export default CHeroPartner;
