import {useContext} from "react";
import {MainContext} from "../../context/MainContext";

interface IProps {
  link: string
  onRight: boolean
}

export function UploadedFile({link, onRight}: IProps) {

  const mainContext = useContext(MainContext);

  function getFilename(): string {
    return link
    // const splits = link.split('/')
    // if (splits.length > 0) {
    //   const file = splits[splits.length - 1]
    //   return file
    // }
    // return 'downloadfile'
  }

  return <div
    className={`div-lightest-purple rounded-[16px] px-[14px] py-[16px] flex gap-[28px] items-center ${onRight ? 'self-end' : 'self-start'}`}>
    <div className={'flex items-center gap-[8px]'}>
      <div className={'bg-white rounded-full p-[10px]'}>
        <img src={'/images/Document.svg'}/>
      </div>
      <p className={'text-14-24-500 text-grey'}>{mainContext?.getTranslation('common', 'dosya-paylasildi')}</p>
    </div>
    <a target={'_blank'} href={link} download={getFilename()}>
      <img src={'/images/DownloadButton.svg'}/>
    </a>
  </div>
}
