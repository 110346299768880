import {useContext} from "react";
import {MainContext} from "../../context/MainContext";
import {TMessage} from "../../types/TMessage";

interface IProps {
  message: TMessage
  onRight: boolean
}

export function RequestedRevision({message, onRight}: IProps) {
  const mainContext = useContext(MainContext);

  return <div
    className={`div-lightest-purple rounded-[16px] px-[14px] py-[16px] flex gap-[28px] items-center ${onRight ? 'self-end' : 'self-start'}`}>
    <div className={'flex items-center gap-[8px]'}>
      <div className={'bg-white rounded-full p-[10px]'}>
        <img src={'/images/revisePurple.svg'}/>
      </div>
      <p className={'text-14-24-500 text-grey'}><b>{message.relationName}</b> {mainContext?.getTranslation('common', 'revizyon-talep-edildi')}</p>
    </div>
  </div>
}
