import {FilterModal} from "../Filter/FilterModal";
import {useContext, useEffect, useState} from "react";
import {MainContext} from "../../context/MainContext";
import {postV2} from "../../utils/request";
import {emailRegex, optionalNameRegex} from "../../types/Regex";
import {Phone} from "../Input/Phone";
import {CompanyContactPerson, CompanyProfileData, deepCopyCompany} from "../../types/TCompanyData";
import {parseCompanyDataRequest} from "../../service/companyRequest";
import {NotificationType} from "../Toast/Toast";
import {TError} from "../../types/TError";

interface IProps {
  getTranslation: (key: string) => string
  isOpen: boolean
  close: () => void
  updated: () => void
  company?: CompanyProfileData
}

export function CContactPersonEditModal({getTranslation, isOpen, close, company, updated}: IProps) {
  const contact = company?.contactPerson
  const mainContext = useContext(MainContext)
  const [tempContact, setTempContact] = useState<CompanyContactPerson | undefined>(undefined)

  const [isUpdating, setIsUpdating] = useState(false)

  const nameError = optionalNameRegex.test(tempContact?.name ?? '')
    ? undefined
    : mainContext!.getTranslation("giris-kayit", "gecersiz-ad");
  const surnameError = optionalNameRegex.test(tempContact?.name ?? '')
    ? undefined
    : mainContext!.getTranslation("giris-kayit", "gecersiz-soyad");
  const emailError = emailRegex.test(tempContact?.email ?? '') ? undefined : 'error'

  const isUpdatable = (tempContact?.name ?? '').length > 1 && (tempContact?.surname ?? '').length > 1 &&
    (tempContact?.phone ?? '').trim().length > 13 && emailRegex.test(tempContact?.email ?? '') && nameError == undefined && surnameError == undefined && emailError == undefined

  useEffect(() => {
    if (isOpen && contact) {
      setTempContact(JSON.parse(JSON.stringify(contact)))
    } else {
      setTempContact(undefined)
    }
  }, [isOpen])

  function save() {
    if (mainContext?.isExpired('contactperson')) {
      return
    }
    const tempCompany = deepCopyCompany(company!)
    tempCompany.contactPerson = tempContact!
    const companyDataParsed = parseCompanyDataRequest(tempCompany)
    setIsUpdating(true)
    postV2('/Company/update', companyDataParsed).then(r => {
      if (r instanceof TError) {
        mainContext?.showToast(NotificationType.Fail, 'updateCompanyInfo')
      } else {
        mainContext?.showToast(NotificationType.Success, 'updateCompanyInfo')
        updated()
      }
    }).catch((e) => {
      mainContext?.showToast(NotificationType.Fail, 'updateCompanyInfo')
    }).finally(() => {
      setIsUpdating(false)
    })
  }

  return <FilterModal
    title={getTranslation('sirket-yetkilisi')}
    header={getTranslation('yetkili-adsoyad')}
    isOpen={isOpen}
    onClose={close}
    onSave={save}
    requesting={isUpdating}
    saveDisabled={!isUpdatable || isUpdating}
    customHeaderClass={'!pb-[20px]'}
  >
    <div>
      <div className={'w-full flex gap-[28px]'}>
        <input placeholder={mainContext?.getTranslation('common', 'input-ad')}
               className={'standardInput !color-[#6D6D6D]'}
               value={tempContact?.name}
               onChange={(e) => {
                 let newVal = e.target.value;
                 setTempContact({...tempContact!, name: newVal})
               }}
        />
        <input placeholder={mainContext?.getTranslation('common', 'input-soyad')}
               className={'standardInput !color-[#6D6D6D]'}
               value={tempContact?.surname}
               onChange={(e) => {
                 let newVal = e.target.value;
                 setTempContact({...tempContact!, surname: newVal})
               }}
        />
      </div>
      <div className={'pt-[32px]'}>
        <div className={'modalTitle pb-[16px]'}>{getTranslation('yetkili-eposta')}</div>
      </div>
      <input placeholder={mainContext?.getTranslation('common', 'input-eposta')}
             className={'standardInput !color-[#6D6D6D]'}
             value={tempContact?.email}
             onChange={(e) => {
               let newVal = e.target.value;
               if (newVal.length > 50) {
                 return
               }
               setTempContact({...tempContact!, email: newVal})
             }}
      />
      <div className={'pt-[32px]'}>
        <div className={'modalTitle pb-[16px]'}>{getTranslation('yetkili-telefon')}</div>
      </div>
      <Phone val={tempContact?.phone ?? ''} setVal={(p) => setTempContact({...tempContact!, phone: p})}/>

      {/*<input placeholder={mainContext?.getTranslation('common', 'input-telefon')}*/}
      {/*       className={'standardInput !color-[#6D6D6D]'}*/}
      {/*       value={tempContact?.phone}*/}
      {/*       onChange={(e) => {*/}
      {/*         let newVal = e.target.value;*/}
      {/*         if (newVal.length > 13) {*/}
      {/*           return*/}
      {/*         }*/}
      {/*         const numVal = Number(newVal)*/}
      {/*         if (isNaN(numVal)) {*/}
      {/*           return*/}
      {/*         }*/}
      {/*         setTempContact({...tempContact!, phone: newVal})*/}
      {/*       }}*/}
      {/*/>*/}

    </div>
  </FilterModal>
}
