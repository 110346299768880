import {MuiTelInput, MuiTelInputInfo} from "mui-tel-input";
import {useContext} from "react";
import {MainContext} from "../../context/MainContext";

const PhoneWithCountryRegex = /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;

export interface PhoneProps {
  val: string
  setVal: (s:string)=>void
}

export function Phone({val, setVal}: PhoneProps) {
  const mainContext = useContext(MainContext)

  const handleChange = (newValue: string, info: MuiTelInputInfo) => {
    const valid = PhoneWithCountryRegex.test(newValue);
    setVal(newValue)
  }

  return <MuiTelInput defaultCountry="TR" forceCallingCode={true} value={val}
                      placeholder={mainContext?.getTranslation('common', 'giriniz')}
                      onChange={handleChange}/>
}
