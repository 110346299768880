import {
  Button,
  FocusLock,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {TMenu} from "../types/TMenu";

interface PTopbarMenu {
  data: TMenu;
  isMobile?: boolean;
  onClicked?: () => void;
}

function CTopbarMenu({data, isMobile, onClicked}: PTopbarMenu) {
  const hasItems = data.items && data.items.length > 0;

  const navigate = useNavigate();

  return (
    <Popover placement="bottom-end" trigger={hasItems ? "hover" : "click"}>
      <PopoverTrigger>
        <Button
          onClick={() => {
            // onClicked && onClicked();
            // window.location.href = (data.link);
          }}
          className="menu-title"
        >
          {data.title}
        </Button>
      </PopoverTrigger>
      {hasItems && !isMobile && (
        <PopoverContent className="menu-box">
          <FocusLock persistentFocus={false}>
            <PopoverArrow/>
            {data.items?.map((item, i) => {
              return (
                <PopoverBody key={item.text + i} className="menu-item menu-item-hover" role={'button'} onClick={() => {
                  if (item.link) {
                    window.location.href = (item.link);
                  } else if (item.func) {
                    item.func();
                  }
                }}>
                  <Button className="max-w-[220px] text-start !whitespace-break-spaces">
                    {item.text}
                  </Button>
                  <img src="/images/menu_arrow.svg"/>
                </PopoverBody>
              );
            })}
          </FocusLock>
        </PopoverContent>
      )}
    </Popover>
  );
}

export default CTopbarMenu;
