import {CProfileCard} from "./Profile/CProfileCard";
import {CDashboardFilters} from "./CDashboardFilters";
import {FilterConfig, FilterType, JobFilter, JobFilterType} from "../types/TFilter";
import {useTranslation} from "../hooks/TranslationHook";

interface IProps {
  onFiltersApplied: (filters: Map<string, FilterConfig[]>) => void;
  hideFilters?: boolean;
}

export function CProfile({onFiltersApplied, hideFilters}: IProps) {
  const profileTranslations = useTranslation('profil-sayfa', 'sayfa')

  return (
    <div id={'dashboardprofile'} className="flex-col min-w-[240px] w-[240px]">
      <div className="text-32-40-500 pb-[24px]">
        pofft’a
        <br/>
        Hoş Geldin 👋
      </div>
      <div>
        <CProfileCard/>
        {hideFilters != true &&
            <CDashboardFilters onFiltersApplied={onFiltersApplied}/>
        }
      </div>
    </div>
  );
}
