import { useContext } from "react";
import { MainContext } from "src/context/MainContext";
import { RBlog } from "src/types/RBlogs";
import { dateStrToString } from "../utils/date";

interface IProps {
  blog: RBlog;
  parentClass?: string;
}
export function CTopBlogCard({ blog, parentClass }: IProps) {
  const mainContext = useContext(MainContext);

  return (
    <div
      className={`flex-1 overflow-hidden rounded-[16px] bg-white flex-col ${parentClass}`}
      style={{ boxShadow: "0px 20px 80px #EBECF0" }}
    >
      <div className={""}>
        <img
          className="h-full w-[100%] object-cover"
          src={blog.pagePictureModelList.getUrl()}
        ></img>
      </div>
      <div
        className={`flex-col justify-between text-start px-[16px] pt-[16px] pb-[24px] h-full`}
      >
        <div className="flex justify-between text-12-24-600 text-light-grey">
          <div>{dateStrToString(blog.transactionDate)}</div>
          <div>Post by: {blog.by}</div>
        </div>
        <div className="flex-col justify-between h-full">
          <div className="flex-col">
            <div className="text-16-24-600_18-24-600 text-black pt-[8px]">
              {blog.name}
            </div>
            <div className="text-14-24-400 text-light-grey pt-[16px]">
              {blog.extra1.length>286?(blog.extra1.substring(0,283)+"..."):blog.extra1}
            </div>
          </div>
          <div
            className="text-16-24-600 text-purple pt-[16px] text-start"
            role="button"
            onClick={() => {
              window.location.href=('/blog?id='+blog.id)
            }}
          >
            {mainContext?.getTranslation("common", "devamini-oku")}
          </div>
        </div>
      </div>
    </div>
  );
}
