import {MainContextType} from "../context/MainContext";

export function byteArrayToBase64(window: any, byteArray: any) {
  let binary = '';
  const bytes = new Uint8Array(byteArray);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}


export function getFile(file: File,onSuccess:(filename:string,fileData:any)=>void, onError: (error: string) => void, mainContext: MainContextType|null) {
  const reader = new FileReader()
  reader.onabort = () => {
    console.log('file reading was aborted')
    onError(mainContext?.getTranslation('common', 'dosya-okunamadi') ?? 'Error')
  }
  reader.onerror = () => {
    console.log('file reading has failed')
    onError(mainContext?.getTranslation('common', 'dosya-okunamadi') ?? 'Error')
  }
  reader.onload = () => {
    // Do whatever you want with the file contents
    const binaryStr = reader.result
    if (binaryStr == null) {
      onError(mainContext?.getTranslation('common', 'dosya-okunamadi') ?? 'Error')
      return
    }
    if (binaryStr instanceof ArrayBuffer) {
      const byteArray = new Uint8Array(binaryStr);
      const str = byteArrayToBase64(window, byteArray);
      onSuccess(file.name,str)
    } else {
      return;
    }
  }
  reader.readAsArrayBuffer(file)
}
