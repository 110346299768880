import {forwardRef} from "react";
import {NumericFormat, NumericFormatProps} from "react-number-format";

export const priceInput_purple_border_more_round = forwardRef<NumericFormatProps, CustomPriceInputProps>((props, ref) => (
  <NumericFormatPrice {...props} customClassName="purple-border-round16" ref={ref}/>
)) as any

export interface CustomPriceInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  customSuffix?: string;
  customClassName?: string;
}

export const NumericFormatPrice = forwardRef<NumericFormatProps, CustomPriceInputProps>(
  function NumericFormatCustom(props, ref) {
    const {onChange, customSuffix, customClassName, ...other} = props;
    return (
      <NumericFormat
        {...other}
        //@ts-ignore
        className={customClassName ? `${other.className} ${customClassName}` : other.className}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        decimalScale={2}
        thousandSeparator
        valueIsNumericString
        suffix={` ${customSuffix ?? 'TL'}`}
      />
    );
  },
);
