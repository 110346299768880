import {useContext, useEffect, useState} from "react";
import {parseTranslation, Translation} from "../types/Translation";
import {getV2, publicToken} from "../utils/request";
import {MainContext} from "../context/MainContext";
import {getTranslations} from "../service/commonRequest";

export function useTranslation(name:string,parent:string) {
  const mainContext = useContext(MainContext)

  const [translations, setTranslations] = useState<
    Map<string, Translation> | undefined
  >(undefined);
  function getTranslation(key: string): string {
    return translations?.get(parent)?.sentences.get(key) ?? "";
  }

  useEffect(() => {
    if (mainContext?.userData?.token && mainContext?.publicToken) {
      getTranslations(name,(translations,data)=>{
        setTranslations(translations);
      })
    }
  }, [mainContext?.userData?.token,mainContext?.publicToken]);

  return getTranslation;
}
